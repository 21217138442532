import React, { useCallback, useEffect, useState } from "react";
import { MixedTags } from "@yaireo/tagify/dist/react.tagify";
import { useContext } from "react";
import { UserContext } from "../context/userContext";
import { TemplateContext } from "../context/templateContext";
const MixedModeTagify = (props) => {
    console.log("props", props)
    const [settings, setSettings] = useState({
        pattern: /@/,
        dropdown: {
            enabled: 0,
            position: "text"
        },
        whitelist: props.whiteListOptions
    });
    const [inputValue, setInputValue] = useState(""); // Track user's input
    const { promptActionData,
        setPromptActionData } = useContext(TemplateContext)
    console.log("promptActionData", promptActionData)
    const [key, setKey] = useState(1); // Key to force re-render of MixedTags
    useEffect(() => {
        setSettings((prevSettings) => ({
            ...prevSettings,
            whitelist: props.whiteListOptions
        }));
        setKey(key + 1); // Increment the key to force re-render
    }, [props.whiteListOptions]);

    useEffect(() => {
        if (promptActionData) {
            let temp = promptActionData;
            temp = temp.replace(/</gi, '&lt;').replace(/>/gi, '&gt;');
            if (temp !== promptActionData) {
                setPromptActionData(temp)
            }
        }
    }, [promptActionData])
    const onChange = useCallback((e) => {
        e.preventDefault()
        e.stopPropagation()
        console.log("event of tagify", e)
        console.log("CHANGED:", e.detail.value);
        const inputString = "This is a test string with @fir\n and some other text.";
        // const regex = /@.*\n/; // Matches @ followed by anything up to and including \n
        const regex = /@[^[\]{}]*\n/
        if (regex.test(e.detail.value)) {
            console.log("String contains '@' followed by something up to '\\n'");
        } else {
            console.log("else block", e.detail.value)
            // setInputValue(e.detail.value);
            // props.setValue(e.detail.value)
            setPromptActionData(e.detail.value.replace(/\n+$/, ''))
            console.log("String does not contain '@' followed by something up to '\\n'");
        }
        // if(e.detail.value.includes("@\n")){
        // }else{
        //     setInputValue(e.detail.value);
        //     props.setValue(e.detail.value)
        // }
    }, []);
    console.log("promptActionData-------", promptActionData)
    console.log("whiteListOptions-------", props.whiteListOptions)
    return (
        <>
            <MixedTags
                key={key}
                showDropdown
                settings={settings}
                className="myTags"
                readOnly={false}
                onChange={onChange}
                value={promptActionData}
            // value={props.value}
            />
        </>
    );
};
export default MixedModeTagify;