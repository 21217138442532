import React, { useEffect, useState } from 'react';
import { Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import RoundLoader from "../components/roundLoader";
import globalStyles from "../assets/styles/global.module.scss";
import GlobalDataGrid from '../components/globalDataGrid';
import agent from "../api/api";
import GlobalSearch from '../utils/globalSearch';
import { UserContext } from '../context/userContext';
import { checkForErrorCode, getErrorMessage } from '../utils/constants';
import RefreshIcon from '@mui/icons-material/Refresh';
import styles from '../assets/styles/existingCustomers.module.scss'
import { makeStyles } from '@mui/styles';

const ExistingCustomers = () => {
  const [showLoader, setShowLoader] = React.useState(false);
  const [customerList, setCustomerList] = React.useState([]);
  const [rowFilteredDetails, setRowFilteredDetails] = React.useState(null);
  const [filterValues, setFilterValues] = React.useState(null);
  const { setError, error, setFlagLoading, flagLoading } = React.useContext(UserContext);
  const [sortedData, setSortedData] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: '' });
  const [changeField, setChangeField] = useState("");
  const [selectedRow, setSelectedRow] = React.useState({
    value: "",
    fieldName: ""
  });

  const columns = [
    { field: 'user_assignment_id', headerName: 'User_assignment ID', width: 170 },
    { field: 'userFirstName', headerName: 'First Name', width: 125 },
    { field: 'userLastName', headerName: 'Last Name', width: 125 },
    { field: 'userEmail', headerName: 'Email', width: 220 },
    { field: 'mouseflow_record_url', headerName: 'Mouseflow Filter', width: 150 },
    { field: 'userPhoneNumber', headerName: 'Phone Number', width: 150 },
    { field: 'userLastLogin', headerName: 'Last Login', width: 170 },
    { field: 'userRegistered', headerName: 'Registered', width: 170 },
    { field: 'registerTimestamp', headerName: 'Register Timestamp', width: 190 },
    { field: 'userStatusId', headerName: 'Status ID', width: 100 },
    { field: 'useStatusName', headerName: 'Status Name', width: 130 },
    { field: 'userRoleId', headerName: 'Role ID', width: 100 },
    { field: 'userRoleName', headerName: 'Role Name', width: 125 },
    { field: 'userPersonaId', headerName: 'Persona ID', width: 120 },
    { field: 'highPotentialUser', headerName: 'High Potential User', width: 170 },
    { field: 'userId', headerName: 'User ID', width: 80 },
  ];

  const rows = customerList !== null && customerList.map((item) => ({
    id: item.private_user_id,
    user_assignment_id: item.user_assignment_id,
    userFirstName: item.private_user_first_name,
    userLastName: item.private_user_last_name ? item.private_user_last_name : "-",
    userEmail: item.private_user_email,
    mouseflow_record_url: item.mouseflow_record_url,
    userPhoneNumber: item.private_user_phone_number ? item.private_user_phone_number : "-",
    userLastLogin: item.user_last_login,
    userRegistered: item.user_registered,
    registerTimestamp: new Date(item.register_timestamp).toLocaleString(),
    userStatusId: item.user_status_id,
    useStatusName: item.user_status_name,
    userRoleId: item.private_user_role_id,
    userRoleName: item.user_role_name,
    userPersonaId: item.persona_id ? item.persona_id : "-",
    highPotentialUser: item.high_potential_user ? item.high_potential_user : "-",
  }));


  const getCustomerListData = async () => {
    setFilterValues("")
    setSortConfig({...sortConfig, direction: ""})
    setShowLoader(true)
    const response = await agent.GetCustomerList.get();
    setShowLoader(false)
    if (response.status) {
      if (response.data) {

        let customerList = response.data.map((customer, index) => ({
          id: index,
          user_assignment_id: customer.user_assignment_id,
          userFirstName: customer.private_user_first_name,
          userLastName: customer.private_user_last_name ? customer.private_user_last_name : "-",
          userEmail: customer.private_user_email,
          mouseflow_record_url: customer.mouseflow_record_url,
          userPhoneNumber: customer.private_user_phone_number ? customer.private_user_phone_number : "-",
          userLastLogin: customer.user_last_login,
          userRegistered: customer.user_registered,
          registerTimestamp: new Date(customer.register_timestamp).toLocaleString(),
          userStatusId: customer.user_status_id,
          useStatusName: customer.user_status_name,
          userRoleId: customer.private_user_role_id,
          userRoleName: customer.user_role_name,
          userPersonaId: customer.persona_id ? customer.persona_id : "-",
          highPotentialUser: customer.high_potential_user ? customer.high_potential_user : "-",
          userId: customer.private_user_id
        }))
        setRowFilteredDetails(customerList)
        setCustomerList(customerList)
      }
    }
    else {
      let errorCode = checkForErrorCode(response)
      setError({ ...error, flagShowError: true, message: getErrorMessage(errorCode) })
    }
  }

  React.useEffect(() => {
    setFlagLoading(true)
    getCustomerListData();
    setTimeout(() => {
      setFlagLoading(false)
    }, 1500);
  }, [])

  const handleSearch = (event) => {
    setSortConfig({...sortConfig, direction: ""})
    let query = event.target.value.toLowerCase().trim()
    setFilterValues(query)
    if (query && query.length > 0) {
      const filteredActiveChatsTemp = customerList.filter((customer) => {
        return (
          JSON.stringify(customer)?.toLowerCase().includes(query)
        )
      })
      setRowFilteredDetails(filteredActiveChatsTemp)
      setSortedData(filteredActiveChatsTemp)
    } else{
      setRowFilteredDetails(customerList)
      setSortedData(customerList)
    }
  }

  const handleClearSearch = () => {
    setFilterValues("");
    setRowFilteredDetails(customerList);
    setSortConfig({...sortConfig, direction: ""})
  }

  const handleRowClick = (params, field) => {
      setSelectedRow({...selectedRow, value: params, fieldName: field});
      if(field === "mouseflow_record_url") {
        window.open(params, '_blank')
      }
  };


  const useStyles = makeStyles(() => ({
    customTooltip: {
        backgroundColor: '#FFF',
        color: '#000',
        fontSize: '13px',
        padding: '8px 15px',
        borderRadius: 5,
        margin: "0px",
        boxShadow: "0px 0px 3px rgba(0,0,0,0.25)"
    },
  }));
  const classes = useStyles();

  useEffect(() => {
    if (rowFilteredDetails && rowFilteredDetails.length > 0) {
      setSortedData([...rowFilteredDetails]);
    }
  }, [rowFilteredDetails]);

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setChangeField(key);
    const sorted = [...sortedData].sort((a, b) => {
    const aValue = a[key] || "";
    const bValue = b[key] || "";

    if (aValue === "" && bValue === "") {
      return 0;
    }

    if (aValue === "") {
      return direction === 'ascending' ? -1 : 1;
    }

    if (bValue === "") {
      return direction === 'ascending' ? 1 : -1;
    }
    
    if (aValue < bValue) {
      return direction === 'ascending' ? -1 : 1;
    }

    if (aValue > bValue) {
      return direction === 'ascending' ? 1 : -1;
    }

    return 0;
    });

    setSortedData(sorted);
    setSortConfig({ key, direction });
  };

  const renderTableRow = (row) => (
    <TableRow className={styles.tableBodyRow}>
      <TableCell sx={{ minWidth: "170px" }} className={styles.tableBodyCell} onClick={() => { handleRowClick(row.user_assignment_id, "user_assignment_id") }}>
        <Tooltip title={row.user_assignment_id} classes={{ tooltip: classes.customTooltip }} placement='right-start'>{row.user_assignment_id}</Tooltip>
      </TableCell>
      <TableCell sx={{ minWidth: "125px" }} className={styles.tableBodyCell} onClick={() => { handleRowClick(row.userFirstName, "userFirstName") }}>
        <Tooltip title={row.userFirstName} classes={{ tooltip: classes.customTooltip }} placement='right-start'>{row.userFirstName?.length > 12 ? row.userFirstName.slice(0, 12) + "..." : row.userFirstName}</Tooltip>
      </TableCell>
      <TableCell sx={{ minWidth: "125px" }} className={styles.tableBodyCell} onClick={() => { handleRowClick(row.userLastName, "userLastName") }}>
        <Tooltip title={row.userLastName} classes={{ tooltip: classes.customTooltip }} placement='right-start'>{row.userLastName}</Tooltip>
      </TableCell>
      <TableCell sx={{ minWidth: "220px" }} className={styles.tableBodyCell} onClick={() => { handleRowClick(row.userEmail, "userEmail") }}>
        <Tooltip title={row.userEmail} classes={{ tooltip: classes.customTooltip }} placement='right-start'>{row.userEmail}</Tooltip>
      </TableCell>
      <TableCell sx={{ minWidth: "150px", cursor: "pointer" }} className={styles.tableBodyCell} onClick={() => { handleRowClick(row.mouseflow_record_url, "mouseflow_record_url") }}>
        <Tooltip title={row.mouseflow_record_url} classes={{ tooltip: classes.customTooltip }} placement='right-start'>{row.mouseflow_record_url.length > 25 ? row.mouseflow_record_url.slice(0, 25) + "..." : row.mouseflow_record_url}</Tooltip>
      </TableCell>
      <TableCell sx={{ minWidth: "150px" }} className={styles.tableBodyCell} onClick={() => { handleRowClick(row.userPhoneNumber, "userPhoneNumber") }}>
        <Tooltip title={row.userPhoneNumber} classes={{ tooltip: classes.customTooltip }} placement='right-start'>{row.userPhoneNumber}</Tooltip>
      </TableCell>
      <TableCell sx={{ minWidth: "170px" }} className={styles.tableBodyCell} onClick={() => { handleRowClick(row.userLastLogin, "userLastLogin") }}>
        <Tooltip title={row.userLastLogin} classes={{ tooltip: classes.customTooltip }} placement='right-start'>{row.userLastLogin}</Tooltip>
      </TableCell>
      <TableCell sx={{ minWidth: "170px" }} className={styles.tableBodyCell} onClick={() => { handleRowClick(row.userRegistered, "userRegistered") }}>
        <Tooltip title={row.userRegistered} classes={{ tooltip: classes.customTooltip }} placement='right-start'>{row.userRegistered}</Tooltip>
      </TableCell>
      <TableCell sx={{ minWidth: "190px" }} className={styles.tableBodyCell} onClick={() => { handleRowClick(row.registerTimestamp, "registerTimestamp") }}>
        <Tooltip title={row.registerTimestamp} classes={{ tooltip: classes.customTooltip }} placement='right-start'>{row.registerTimestamp}</Tooltip>
      </TableCell>
      <TableCell sx={{ minWidth: "100px" }} className={styles.tableBodyCell} onClick={() => { handleRowClick(row.userStatusId, "userStatusId") }}>
        <Tooltip title={row.userStatusId} classes={{ tooltip: classes.customTooltip }} placement='right-start'>{row.userStatusId}</Tooltip>
      </TableCell>
      <TableCell sx={{ minWidth: "130px" }} className={styles.tableBodyCell} onClick={() => { handleRowClick(row.useStatusName, "useStatusName") }}>
        <Tooltip title={row.useStatusName} classes={{ tooltip: classes.customTooltip }} placement='right-start'>{row.useStatusName}</Tooltip>
      </TableCell>
      <TableCell sx={{ minWidth: "100px" }} className={styles.tableBodyCell} onClick={() => { handleRowClick(row.userRoleId, "userRoleId") }}>
        <Tooltip title={row.userRoleId} classes={{ tooltip: classes.customTooltip }} placement='right-start'>{row.userRoleId}</Tooltip>
      </TableCell>
      <TableCell sx={{ minWidth: "125px" }} className={styles.tableBodyCell} onClick={() => { handleRowClick(row.userRoleName, "userRoleName") }}>
        <Tooltip title={row.userRoleName} classes={{ tooltip: classes.customTooltip }} placement='right-start'>{row.userRoleName}</Tooltip>
      </TableCell>
      <TableCell sx={{ minWidth: "100px" }} className={styles.tableBodyCell} onClick={() => { handleRowClick(row.userPersonaId, "userPersonaId") }}>
        <Tooltip title={row.userPersonaId} classes={{ tooltip: classes.customTooltip }} placement='right-start'>{row.userPersonaId}</Tooltip>
      </TableCell>
      <TableCell sx={{ minWidth: "170px" }} className={styles.tableBodyCell} onClick={() => { handleRowClick(row.highPotentialUser, "highPotentialUser") }}>
        <Tooltip title={row.highPotentialUser} classes={{ tooltip: classes.customTooltip }} placement='right-start'>{row.highPotentialUser}</Tooltip>
      </TableCell>
      <TableCell sx={{ minWidth: "80px" }} className={styles.tableBodyCell} onClick={() => { handleRowClick(row.userId, "userId") }}>
        <Tooltip title={row.userId} classes={{ tooltip: classes.customTooltip }} placement='right-start'>{row.userId}</Tooltip>
      </TableCell>
    </TableRow>
  );

  return (
    <>
      {
        showLoader && <RoundLoader />
      }

      <Grid container className={globalStyles.containerflexWrapStyle} direction={"row"} xs={12} gap={1} alignItems={"center"} justifyContent={"end"} mb={1}>
        <Grid item marginRight={"15px"}>
          <span style={{fontSize: "14px", fontWeight: "500"}}>Existing Customers: </span><span style={{fontSize: "15px", fontWeight: "600"}}>{customerList.length}</span>
        </Grid>
        <Grid item className={globalStyles.searchBarFieldGrid}>
          <GlobalSearch placeholder="Search Customer" value={filterValues} onChange={(e) => { handleSearch(e) }} onClose={handleClearSearch} />
        </Grid>
        <Box display={"flex"} justifyContent={"end"}>
          <RefreshIcon onClick={getCustomerListData} className={globalStyles.clickable} />
        </Box>
      </Grid>

      <Grid container className={globalStyles.containerflexWrapStyle} direction={"column"}>
        <Grid item sx={{boxShadow: "0px 0px 3px rgba(0,0,0,0.15)"}}>
            {!flagLoading &&
            <TableContainer className={styles.tableContainer}>
                <Table aria-label='simple table' className={styles.tableDesign}>
                  <TableHead className={styles.tableHead}>

                    <TableRow className={styles.tableHeadRow}>
                      {columns?.map(column => 
                        <TableCell key={column.field} className={styles.tableHeadCell} sx={{minWidth: column.width, cursor: "pointer"}} onClick={() => handleSort(column.field)}>
                          <Grid container direction={"row"} justifyContent={"space-between"} flexWrap={"nowrap"} alignItems={"center"}>
                            <Grid item>
                              {column.headerName}
                            </Grid>
                            <Grid item direction={"column"}>
                              <Grid container direction={"column"}>
                                <Typography variant='span' style={{color: sortConfig.direction==="ascending" && column.field === changeField ? "rgba(0,0,0,0.85)" : "rgba(0,0,0,0.1)", lineHeight: "normal", fontSize: "12px"}}>▲</Typography>
                                <Typography variant='span' style={{color: sortConfig.direction==="descending" && column.field === changeField ? "rgba(0,0,0,0.85)" : "rgba(0,0,0,0.1)", lineHeight: "normal", fontSize: "12px"}}>▼</Typography>
                              </Grid>                                
                            </Grid>
                          </Grid>
                        </TableCell>
                      )}
                    </TableRow>
                  </TableHead>

                  <TableBody className={styles.tableBody}>
                    {(sortedData && sortedData.length > 0) ? sortedData.map(row => renderTableRow(row)) :
                      // (rowFilteredDetails && rowFilteredDetails.length > 0) ? rowFilteredDetails.map(row => renderTableRow(row)) :
                        <TableRow className={styles.tableBodyRow}>
                          <TableCell colSpan={8} sx={{ textAlign: "center", padding: "16px 16px 32px" }}>
                            <span style={{ position: "fixed" }}>No Data Available</span>
                          </TableCell>
                        </TableRow>
                    }
                  </TableBody>

                </Table>
            </TableContainer>}
        </Grid>
      </Grid>

      {selectedRow.fieldName != "" && (
          <div className={styles.showDetailsBlock}>
              <div className={styles.showDetailsTitleField}><Typography>{selectedRow.fieldName}</Typography></div>
              <div className={styles.showDetailsDataField}><Typography>{selectedRow.value}</Typography></div>
          </div>
      )}
    </>
  )
}

export default ExistingCustomers