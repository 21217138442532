import * as React from "react";
import { Grid } from '@mui/material';
import { useNavigate, useSearchParams } from "react-router-dom";
import agent from "../../api/api";
import GlobalDataGrid from "../../components/globalDataGrid";
import GlobalPopUp from "../../utils/globalPopUp";
import PromptPropertyForm from "./promptPropertyForm";
import { UserContext } from "../../context/userContext";
import PromptVariantForm from "./promptVariantForm";
import { PromptContext } from "../../context/promptContext";
import GlobalButton from "../../components/button";
import GlobalDeletePopup from "../../utils/globalDeletePopup";

const PromptDetails = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [promptDetails, setPromptDetails] = React.useState([])
    const [promptPropertyList, setPromptPropertyList] = React.useState([])
    const [promptVariantData, setPromptVariantData] = React.useState([])
    const { success, setSuccess, error, setError, setFlagLoading } = React.useContext(UserContext)
    const { GetModelGenericList, GetOutputGenericData } = React.useContext(PromptContext);
    const [flagShowDeleteConfirmation, setFlagShowDeleteConfirmation] = React.useState(false)
    const [promptPropertyForm, setPromptPropertyForm] = React.useState({
        prompt_property_name: "",
        prompt_property_flag_context: false,
        prompt_property_flag_is_required: false
    })
    const [promptVariantForm, setPromptVariantForm] = React.useState({
        output_format_name: "",
        prompt_variant_name: "",
        output_format_example: "",
        prompt_context_data: "",
        prompt_action_data: "",
        prompt_output_key_name: "",
        prompt_flag_output_data: false,
        prompt_variant_flag_all_models: false,
        prompt_variant_model_data: [],
        prompt_variant_example_data: []
    })
    const GetPropertyData = async () => {
        const response = await agent.getPromptPropertyList.get(searchParams.get('prompt_id'))
        if (response.status) {
            let temp = response.data.map((res, index) => ({ ...res, id: index }))
            setPromptPropertyList(temp)
        } else {
            setPromptPropertyList([])
        }
    }
    const handleGetPromptDetails = async () => {
        const promptDetailResponse = await agent.GetPromptDetails.get(searchParams.get('prompt_id'))
        if (promptDetailResponse.status) {
            setPromptDetails(promptDetailResponse.data[0]);
            setPromptPropertyForm({ ...promptPropertyForm, prompt_id: promptDetailResponse.data[0].prompt_id, template_id: promptDetailResponse.data[0].template_id })
            if (promptDetailResponse.data[0].prompt_property_data.length) {
                let temp = promptDetailResponse.data[0].prompt_property_data.map((res, index) => ({ ...res, id: index }))
                setPromptPropertyList(temp)
            } else {
                setPromptPropertyList([])
            }
            if (promptDetailResponse.data[0].prompt_variant_data.length) {
                let temp1 = promptDetailResponse.data[0].prompt_variant_data.map((res, index) => ({ ...res, id: index, model_names: res.prompt_variant_flag_all_models ? "All" : res.prompt_variant_model_data.map((model) => model.model_name) }))
                setPromptVariantData(temp1)
            } else {
                setPromptVariantData([])
            }
        } else {
            setError({ ...error, flagShowError: true, message: promptDetailResponse.error.message })
        }
    }
    const handleSetPromptVariantData = (params) => {
        let temp = promptDetails.prompt_variant_data.filter((variant) => variant.prompt_variant_id == params.row.prompt_variant_id)
        setPromptVariantForm(temp[0])
    }
    const navigate = useNavigate();

    React.useEffect(() => {
        (async () => {
            if (searchParams.has('prompt_id')) {
                await handleGetPromptDetails();
                await GetOutputGenericData();
                await GetModelGenericList();
            }
        })()
    }, [])

    const [createPromptFormData, setCreatePromptFormData] = React.useState(
        {
            max_tokens: "",
            prompt_name: "",
            template_id: null,
            credits_required: "",
            prompt_type_name: "primary",
            prompt_generic_name: ""
        }
        // old code
        // {
        //     max_tokens: null,
        //     prompt_name: "",
        //     template_id: null,
        //     output_format: null,
        //     prompt_tooltip: null,
        //     prompt_icon_url: "",
        //     output_format_example: "",
        //     prompt_max_num_of_output: 5,
        //     prompt_context_data: "",
        //     prompt_action_data: "",
        //     prompt_output_key_name: "",
        //     prompt_generic_name: "",
        //     credits: null,
        //     prompt_flag_output_data: false,
        //     stream_flag: false,
        //     // model_name:"" //send when variants are ready by default gpt4
        //     // credits_required:"" //no need to pass
        //     // prompt_flag_extension: false,
        // }
    )
    const [templateList, setTemplateList] = React.useState([])
    const [flagShowPropertyPopup, setFlagShowPropertyPopup] = React.useState(false)
    const [flagShowUpdatePropertyPopup, setFlagShowUpdatePropertyPopup] = React.useState(false)

    const promptPropertyColumns = [
        { field: 'prompt_property_name', headerName: 'Property Name', width: 250 }, // Hidden ID column for selection
        { field: 'prompt_property_flag_is_required', headerName: 'Flag Required', width: 125 },
        { field: 'prompt_property_flag_context', headerName: 'Flag Context', width: 125 },
        {
            field: 'edit_prompt_property', headerName: 'Edit', width: 125,
            renderCell: (params) => {
                return (
                    <strong>
                        <GlobalButton
                            buttonText="Edit"
                            className="tableButtonStyle"
                            onClick={() => { setFlagShowUpdatePropertyPopup(true); setPromptPropertyForm(params.row) }}
                        // onClick={(params.row) => {setFlagShowUpdatePropertyPopup(true)}}
                        />
                    </strong>
                )
            },
        },
        {
            field: 'delete_prompt_property', headerName: 'Delete', width: 125,
            renderCell: (params) => {
                return (
                    <strong>
                        <GlobalButton buttonText="Delete" className="tableButtonStyle" onClick={() => { deletePromptProperty(params.row.prompt_property_id) }} />
                    </strong>
                )
            },
        },
    ];
    const promptVariantColumns = [
        { field: 'prompt_variant_id', headerName: 'Variant ID', width: 125 },
        { field: 'prompt_variant_name', headerName: 'Variant Name', width: 150 }, // Hidden ID column for selection
        { field: 'output_format_name', headerName: 'Output Format', width: 125 },
        { field: 'prompt_variant_flag_all_models', headerName: 'All Model Flag', width: 125 },
        { field: 'model_names', headerName: 'Models', width: 200 },
        {
            field: 'update_prompt_variant', headerName: 'View/Update', width: 100,
            renderCell: (params) => {
                return (
                    <strong>
                        <GlobalButton
                            buttonText="Update"
                            className="tableButtonStyle"
                            onClick={() => {
                                handleSetPromptVariantData(params)
                                // setFlagShowDeleteConfirmation(true);
                                // setSearchParams(`prompt_id=${params.row.prompt_id}&prompt_variant_id=${params.row.prompt_variant_id}`)
                            }}
                        />
                    </strong>
                )
            },
        },
        {
            field: 'delete_prompt_variant', headerName: 'Delete', width: 100,
            renderCell: (params) => {
                return (
                    <strong>
                        <GlobalButton
                            buttonText="Delete"
                            className="tableButtonStyle"
                            onClick={() => {
                                setFlagShowDeleteConfirmation(true);
                                setSearchParams(`prompt_id=${params.row.prompt_id}&prompt_variant_id=${params.row.prompt_variant_id}`)
                                // deletePromptVariant(params.row.prompt_id, params.row.prompt_variant_id)@
                            }}
                        // onClick={(params.row) => {setFlagShowUpdatePropertyPopup(true)}}
                        />
                    </strong>
                )
            },
        },
    ];
    const handleCreatePromptProperty = async () => {
        setFlagLoading(true);
        let promptPropertyFormTemp = { ...promptPropertyForm }
        promptPropertyFormTemp.prompt_id = searchParams.get('prompt_id')
        const response = await agent.createPromptProperty.post(promptPropertyFormTemp);
        if (response.status) {
            setSuccess({
                ...success,
                flagShowSuccess: response.status,
                message: response.data.message,
            });
            await GetPropertyData();
            setPromptPropertyForm({
                prompt_property_name: "",
                prompt_property_flag_context: false,
                prompt_property_flag_is_required: false
            })
            setFlagShowPropertyPopup(false)
        } else {
            setError({ ...error, flagShowError: true, message: response.error.message })
        }
        setFlagLoading(false);

    }

    const handleCreatePromptVariant = async () => {
        setFlagLoading(true);
        let temp = { ...promptVariantForm }
        temp.prompt_id = promptDetails.prompt_id
        temp.template_id = promptDetails.template_id;
        let response;
        if (promptVariantForm.prompt_variant_id) {
            response = await agent.updatePromptVariant.put(temp);
        } else {
            response = await agent.createPromptVariant.post(temp);
        }
        if (response.status) {
            setSuccess({
                ...success,
                flagShowSuccess: response.status,
                message: response.data.message,
            });
            handleGetPromptDetails()
        } else {
            setError({ ...error, flagShowError: true, message: response.error.message })
        }
        setFlagLoading(false);
    }
    const deletePromptProperty = async (prompt_property_id) => {
        setFlagLoading(true);
        const response = await agent.deletePromptProperty.delete(prompt_property_id);
        if (response.status) {
            setSuccess({
                ...success,
                flagShowSuccess: response.status,
                message: response.data.message,
            });
            await GetPropertyData();
        } else {
            setError({ ...error, flagShowError: true, message: response.error.message })
        }
        setFlagLoading(false);
    }
    const deletePromptVariant = async () => {
        let prompt_id = searchParams.get('prompt_id');
        let prompt_variant_id = searchParams.get('prompt_variant_id')
        setFlagLoading(true);
        const response = await agent.deletePromptVariant.delete(prompt_id, prompt_variant_id)
        if (response.status) {
            setSuccess({
                ...success,
                flagShowSuccess: response.status,
                message: response.data.message,
            });
            await handleGetPromptDetails();
        } else {
            setError({ ...error, flagShowError: true, message: response.error.message })

        }
        setFlagLoading(false);
    }
    const handleUpdatePromptProperty = async () => {
        setFlagLoading(true);
        const response = await agent.updatePromptProperty.put(promptPropertyForm)
        if (response.status) {
            setSuccess({
                ...success,
                flagShowSuccess: response.status,
                message: response.data.message,
            });
            await GetPropertyData();
            setFlagShowUpdatePropertyPopup(false)
        } else {
            setError({ ...error, flagShowError: true, message: response.error.message })

        }
        setFlagLoading(false);
    }
    return (
        <>
            <GlobalPopUp
                open={flagShowDeleteConfirmation}
                onClose={() => { setFlagShowDeleteConfirmation(false) }}
                title={"Delete Confirmation"}
                flagShowCloseIcon={true}
                handleClose={() => { setFlagShowDeleteConfirmation(false) }}
            >
                <GlobalDeletePopup deleteDescription={`Are you sure want to delete this variant`}
                    onClose={() => { setFlagShowDeleteConfirmation(false) }}
                    onDelete={(e) => {
                        deletePromptVariant();
                        setFlagShowDeleteConfirmation(false)
                    }} />
            </GlobalPopUp>
            <GlobalPopUp
                open={flagShowPropertyPopup}
                onClose={() => { setFlagShowPropertyPopup(false) }}
                title={"Create Prompt Property"}
                flagShowCloseIcon={true}
                handleClose={() => { setFlagShowPropertyPopup(false) }}
            >
                <PromptPropertyForm buttonName={"Create Property"} promptPropertyForm={promptPropertyForm} setPromptPropertyForm={setPromptPropertyForm} handleCreatePromptProperty={handleCreatePromptProperty} />
            </GlobalPopUp>

            <GlobalPopUp
                open={flagShowUpdatePropertyPopup}
                onClose={() => { setFlagShowUpdatePropertyPopup(false) }}
                title={"Update Prompt Property"}
                flagShowCloseIcon={true}
                handleClose={() => { setFlagShowUpdatePropertyPopup(false) }}
            >
                <PromptPropertyForm buttonName={"Update Property"} promptPropertyForm={promptPropertyForm} setPromptPropertyForm={setPromptPropertyForm} handleCreatePromptProperty={handleUpdatePromptProperty} />
            </GlobalPopUp>
            <Grid container gap={"15px"} justifyContent={"space-between"} sx={{ flexWrap: "nowrap" }}>
                {/* prompt property list */}
                {/* left block */}
                <Grid item xs={4}>
                    <GlobalDataGrid rows={promptPropertyList} columns={promptPropertyColumns} hideFooter={false} hideFooterPagination={false} hideFooterSelectedRowCount={true} showSelectedRowDetails={true} refresh={() => { }}
                        onRowClick={(e) => { }} />

                    <div style={{ marginTop: "10px" }}>
                        <GlobalButton buttonText="Add Property" className={"borderedButtonStyle"} onClick={() => { setFlagShowPropertyPopup(true) }} />
                    </div>
                </Grid>
                {/* right block */}
                <Grid item xs={7.5}>
                    <Grid container direction="column" sx={{ flexWrap: "nowrap" }}>
                        {/* variant list */}
                        <Grid item>
                            <GlobalDataGrid rows={promptVariantData} columns={promptVariantColumns} hideFooter={false} hideFooterPagination={false} hideFooterSelectedRowCount={true} showSelectedRowDetails={true} refresh={() => { }} />
                            <div style={{ marginTop: "10px" }}>
                                {promptVariantForm.prompt_variant_id &&
                                    <GlobalButton buttonText="Add Variant" className={"borderedButtonStyle"} onClick={() => {
                                        setPromptVariantForm({
                                            output_format_name: "",
                                            prompt_variant_name: "",
                                            output_format_example: "",
                                            prompt_context_data: "",
                                            prompt_action_data: "",
                                            prompt_output_key_name: "",
                                            prompt_flag_output_data: false,
                                            prompt_variant_flag_all_models: false,
                                            prompt_variant_model_data: []
                                        })
                                    }} />}
                            </div>
                        </Grid>
                        {/* variant form */}
                        <Grid item sx={{ marginTop: "20px" }}>
                            <Grid container direction={"column"}>
                                <PromptVariantForm promptVariantModelData={promptVariantForm.prompt_variant_id ? promptDetails.prompt_variant_data.find((variant) => variant.prompt_variant_id == promptVariantForm.prompt_variant_id).prompt_variant_model_data : []} promptVariantForm={promptVariantForm} setPromptVariantForm={setPromptVariantForm} handleCreatePromptVariant={handleCreatePromptVariant} whiteListOptions={promptPropertyList.map((promptProperty) => promptProperty.prompt_property_name)} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>


            </Grid>
        </>
    )
}

export default PromptDetails;