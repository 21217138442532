import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Grid, Typography } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import styles from "../assets/styles/globalDataGrid.module.scss"

const GlobalDataGrid = ({ rows, filterRows, columns, hideFooter, hideFooterPagination, hideFooterSelectedRowCount, showSelectedRowDetails, refresh, Header, onRowClick, promptsPage }) => {


    const [selectedRow, setSelectedRow] = React.useState(null);

    const handleRowClick = (params) => {
        setSelectedRow(params); // Set the selected cell content
        if (params.field == "mouseflow_record_url") {
            window.open(params.value, '_blank')
        }
    };

    return (
        <>
            {/* <Box display={"flex"} justifyContent={"end"} pb={"5px"}>
                <RefreshIcon onClick={refresh} className={styles.clickable} />
            </Box> */}
            {Header && <Box pb={1.5}><Header /></Box>}
            <Grid container className={promptsPage ? styles.promptsPageGlobalHeight : styles.globalHeight}>
                <DataGrid
                    className={styles.MuiDataGrid}
                    sx={{
                        maxHeight: promptsPage ? "calc(100vh - 250px) !important" : "calc(100vh - 200px) !important",
                        '.MuiDataGrid-columnHeaders': {
                            backgroundColor: '#FBF4FF',
                            fontSize: "14px !important",
                            color: 'black !important',
                            padding: "22px 0px !important"
                        },
                        '.MuiDataGrid-overlayWrapperInner': {
                            height: "35px !important"
                        },
                        '.MuiDataGrid-virtualScroller': {
                            minHeight: "35px !important"
                        },
                        fontSize: "13px",
                        color: "#373737",
                    }}
                    rows={filterRows ? filterRows : (rows ? rows : [])}
                    columns={columns}
                    density='compact'
                    hideFooter={hideFooter}
                    hideFooterPagination={hideFooterPagination}
                    pageSizeOptions={false}
                    hideFooterSelectedRowCount={hideFooterSelectedRowCount}
                    onRowClick={onRowClick}
                    onCellClick={handleRowClick}
                />
            </Grid>

            {showSelectedRowDetails && selectedRow && (
                <div className={styles.showDetailsBlock}>
                    <div className={styles.showDetailsTitleField}><Typography>{selectedRow.field}</Typography></div>
                    <div className={styles.showDetailsDataField}><Typography>{selectedRow.value}</Typography></div>
                </div >
            )}
        </>
    )
}

export default GlobalDataGrid