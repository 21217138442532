import React, { useState } from "react";
import agent from "../api/api";

const TemplateContext = React.createContext(
    undefined
);



const TemplateProvider = ({ children }) => {
    const [templateList, setTemplateList] = useState(
        null
    );
    const [templateFormFieldList, setTemplateFormFieldList] = useState([]);
    const [templateFormFieldsGenericData, setTemplateFormFieldsGenericData] = useState(
        [
            {
                "form_field_input_type_id": 4,
                "form_field_input_type_name": "Checkbox",
                "form_field_input_type_description": null,
                "form_field_input_type_icon": null,
                "form_field_input_type_abbr": "checkbox",
                "form_field_input_type_regex": null,
                "form_field_input_type_formwiz_name": null,
                "form_field_input_type_property": [
                    {
                        "form_field_input_type_property_id": 216,
                        "form_field_input_type_property_name": "Title",
                        "form_field_input_type_property_description": "label",
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 1,
                        "form_field_input_type_property_flag_title": true,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 217,
                        "form_field_input_type_property_name": "Is Required?",
                        "form_field_input_type_property_description": "required",
                        "form_field_data_type_id": 5,
                        "form_field_data_type_name": "Boolean",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 2,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 218,
                        "form_field_input_type_property_name": "Hidden",
                        "form_field_input_type_property_description": "hidden",
                        "form_field_data_type_id": 5,
                        "form_field_data_type_name": "Boolean",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 3,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 219,
                        "form_field_input_type_property_name": "Options",
                        "form_field_input_type_property_description": "options",
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 4,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 220,
                        "form_field_input_type_property_name": "Tooltip",
                        "form_field_input_type_property_description": "tooltip",
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 5,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 221,
                        "form_field_input_type_property_name": "Default Value",
                        "form_field_input_type_property_description": "value",
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 6,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    }
                ]
            },
            {
                "form_field_input_type_id": 5,
                "form_field_input_type_name": "Email",
                "form_field_input_type_description": null,
                "form_field_input_type_icon": null,
                "form_field_input_type_abbr": "email",
                "form_field_input_type_regex": "/^([a-zA-Z0-9\\._]+)@([a-zA-Z)-9])+.([a-z]+)(.[a-z]+)?$/",
                "form_field_input_type_formwiz_name": null,
                "form_field_input_type_property": [
                    {
                        "form_field_input_type_property_id": 330,
                        "form_field_input_type_property_name": "Read Only",
                        "form_field_input_type_property_description": "is_disabled",
                        "form_field_data_type_id": 5,
                        "form_field_data_type_name": "Boolean",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 6,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 331,
                        "form_field_input_type_property_name": "Default Value",
                        "form_field_input_type_property_description": "value",
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 7,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 332,
                        "form_field_input_type_property_name": "Helper Text",
                        "form_field_input_type_property_description": "helper_text",
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 8,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 222,
                        "form_field_input_type_property_name": "Title",
                        "form_field_input_type_property_description": "label",
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 1,
                        "form_field_input_type_property_flag_title": true,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 223,
                        "form_field_input_type_property_name": "Is Required?",
                        "form_field_input_type_property_description": "required",
                        "form_field_data_type_id": 5,
                        "form_field_data_type_name": "Boolean",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 3,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 224,
                        "form_field_input_type_property_name": "Placeholder",
                        "form_field_input_type_property_description": "placeholder",
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 2,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 225,
                        "form_field_input_type_property_name": "Hidden",
                        "form_field_input_type_property_description": "hidden",
                        "form_field_data_type_id": 5,
                        "form_field_data_type_name": "Boolean",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 4,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 226,
                        "form_field_input_type_property_name": "Tooltip",
                        "form_field_input_type_property_description": "tooltip",
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 5,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    }
                ]
            },
            {
                "form_field_input_type_id": 6,
                "form_field_input_type_name": "File Upload",
                "form_field_input_type_description": null,
                "form_field_input_type_icon": null,
                "form_field_input_type_abbr": "file",
                "form_field_input_type_regex": null,
                "form_field_input_type_formwiz_name": null,
                "form_field_input_type_property": [
                    {
                        "form_field_input_type_property_id": 323,
                        "form_field_input_type_property_name": "Minimum Number Of Files",
                        "form_field_input_type_property_description": "min_file_num",
                        "form_field_data_type_id": 2,
                        "form_field_data_type_name": "Integer",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 8,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": "1",
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 227,
                        "form_field_input_type_property_name": "Title",
                        "form_field_input_type_property_description": "label",
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 1,
                        "form_field_input_type_property_flag_title": true,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 228,
                        "form_field_input_type_property_name": "Placeholder",
                        "form_field_input_type_property_description": "placeholder",
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 2,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 229,
                        "form_field_input_type_property_name": "Maximum Number Of Files",
                        "form_field_input_type_property_description": "max_file_num",
                        "form_field_data_type_id": 2,
                        "form_field_data_type_name": "Integer",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 3,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": "1",
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 230,
                        "form_field_input_type_property_name": "Maximum File Size",
                        "form_field_input_type_property_description": "max_file_size",
                        "form_field_data_type_id": 4,
                        "form_field_data_type_name": "Size",
                        "form_field_data_type_unit": "Mb",
                        "form_field_input_type_property_seq_num": 4,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": "2",
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 231,
                        "form_field_input_type_property_name": "Minimum File Size",
                        "form_field_input_type_property_description": "min_file_size",
                        "form_field_data_type_id": 4,
                        "form_field_data_type_name": "Size",
                        "form_field_data_type_unit": "Mb",
                        "form_field_input_type_property_seq_num": 5,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": "2",
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 232,
                        "form_field_input_type_property_name": "Is Required?",
                        "form_field_input_type_property_description": "required",
                        "form_field_data_type_id": 5,
                        "form_field_data_type_name": "Boolean",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 5,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 233,
                        "form_field_input_type_property_name": "Hidden",
                        "form_field_input_type_property_description": "hidden",
                        "form_field_data_type_id": 5,
                        "form_field_data_type_name": "Boolean",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 6,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 234,
                        "form_field_input_type_property_name": "Tooltip",
                        "form_field_input_type_property_description": "tooltip",
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 7,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    }
                ]
            },
            {
                "form_field_input_type_id": 8,
                "form_field_input_type_name": "Multiple Choice",
                "form_field_input_type_description": null,
                "form_field_input_type_icon": null,
                "form_field_input_type_abbr": "multiple checkbox",
                "form_field_input_type_regex": null,
                "form_field_input_type_formwiz_name": null,
                "form_field_input_type_property": [
                    {
                        "form_field_input_type_property_id": 242,
                        "form_field_input_type_property_name": "Title",
                        "form_field_input_type_property_description": "label",
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 1,
                        "form_field_input_type_property_flag_title": true,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 243,
                        "form_field_input_type_property_name": "Is Required?",
                        "form_field_input_type_property_description": "required",
                        "form_field_data_type_id": 5,
                        "form_field_data_type_name": "Boolean",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 3,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 244,
                        "form_field_input_type_property_name": "Options",
                        "form_field_input_type_property_description": "options",
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 2,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 245,
                        "form_field_input_type_property_name": "Hidden",
                        "form_field_input_type_property_description": "hidden",
                        "form_field_data_type_id": 5,
                        "form_field_data_type_name": "Boolean",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 4,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 246,
                        "form_field_input_type_property_name": "Tooltip",
                        "form_field_input_type_property_description": "tooltip",
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 5,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 247,
                        "form_field_input_type_property_name": "Default Value",
                        "form_field_input_type_property_description": "value",
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 6,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 248,
                        "form_field_input_type_property_name": "Minimum Limits",
                        "form_field_input_type_property_description": "min",
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 7,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 249,
                        "form_field_input_type_property_name": "Maximum Limits",
                        "form_field_input_type_property_description": "max",
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 8,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    }
                ]
            },
            {
                "form_field_input_type_id": 9,
                "form_field_input_type_name": "Date",
                "form_field_input_type_description": null,
                "form_field_input_type_icon": null,
                "form_field_input_type_abbr": "date",
                "form_field_input_type_regex": null,
                "form_field_input_type_formwiz_name": null,
                "form_field_input_type_property": [
                    {
                        "form_field_input_type_property_id": 250,
                        "form_field_input_type_property_name": "Title",
                        "form_field_input_type_property_description": "label",
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 1,
                        "form_field_input_type_property_flag_title": true,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 251,
                        "form_field_input_type_property_name": "Placeholder",
                        "form_field_input_type_property_description": "placeholder",
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 2,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 252,
                        "form_field_input_type_property_name": "Is Required?",
                        "form_field_input_type_property_description": "required",
                        "form_field_data_type_id": 5,
                        "form_field_data_type_name": "Boolean",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 3,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 253,
                        "form_field_input_type_property_name": "Date Format",
                        "form_field_input_type_property_description": "date_format",
                        "form_field_data_type_id": 9,
                        "form_field_data_type_name": "Date",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 4,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": "YYYY-MM-DD",
                        "form_field_input_type_additional_property": [
                            {
                                "form_field_input_type_additional_property_id": 1,
                                "form_field_input_type_additional_property_name": "MM-DD-YYYY",
                                "form_field_input_type_additional_property_description": null,
                                "form_field_data_type_id": "9",
                                "form_field_data_type_name": "Date",
                                "form_field_data_type_unit": null,
                                "form_field_input_type_additional_property_seq_num": 1,
                                "form_field_input_type_additional_property_default_value": null
                            },
                            {
                                "form_field_input_type_additional_property_id": 2,
                                "form_field_input_type_additional_property_name": "DD-MM-YYYY",
                                "form_field_input_type_additional_property_description": null,
                                "form_field_data_type_id": "9",
                                "form_field_data_type_name": "Date",
                                "form_field_data_type_unit": null,
                                "form_field_input_type_additional_property_seq_num": 2,
                                "form_field_input_type_additional_property_default_value": null
                            },
                            {
                                "form_field_input_type_additional_property_id": 3,
                                "form_field_input_type_additional_property_name": "YYYY-MM-DD",
                                "form_field_input_type_additional_property_description": null,
                                "form_field_data_type_id": "9",
                                "form_field_data_type_name": "Date",
                                "form_field_data_type_unit": null,
                                "form_field_input_type_additional_property_seq_num": 3,
                                "form_field_input_type_additional_property_default_value": "YYYY-MM-DD"
                            }
                        ]
                    },
                    {
                        "form_field_input_type_property_id": 254,
                        "form_field_input_type_property_name": "Default Date",
                        "form_field_input_type_property_description": "default_date",
                        "form_field_data_type_id": 9,
                        "form_field_data_type_name": "Date",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 5,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": [
                            {
                                "form_field_input_type_additional_property_id": 4,
                                "form_field_input_type_additional_property_name": "NONE",
                                "form_field_input_type_additional_property_description": null,
                                "form_field_data_type_id": "9",
                                "form_field_data_type_name": "Date",
                                "form_field_data_type_unit": null,
                                "form_field_input_type_additional_property_seq_num": 1,
                                "form_field_input_type_additional_property_default_value": null
                            },
                            {
                                "form_field_input_type_additional_property_id": 5,
                                "form_field_input_type_additional_property_name": "CURRENT",
                                "form_field_input_type_additional_property_description": null,
                                "form_field_data_type_id": "9",
                                "form_field_data_type_name": "Date",
                                "form_field_data_type_unit": null,
                                "form_field_input_type_additional_property_seq_num": 2,
                                "form_field_input_type_additional_property_default_value": null
                            },
                            {
                                "form_field_input_type_additional_property_id": 6,
                                "form_field_input_type_additional_property_name": "CUSTOM",
                                "form_field_input_type_additional_property_description": null,
                                "form_field_data_type_id": "9",
                                "form_field_data_type_name": "Date",
                                "form_field_data_type_unit": null,
                                "form_field_input_type_additional_property_seq_num": 3,
                                "form_field_input_type_additional_property_default_value": null
                            }
                        ]
                    },
                    {
                        "form_field_input_type_property_id": 255,
                        "form_field_input_type_property_name": "Max Date",
                        "form_field_input_type_property_description": "min_date",
                        "form_field_data_type_id": 9,
                        "form_field_data_type_name": "Date",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 6,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 256,
                        "form_field_input_type_property_name": "Min Date",
                        "form_field_input_type_property_description": "max_date",
                        "form_field_data_type_id": 9,
                        "form_field_data_type_name": "Date",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 7,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 257,
                        "form_field_input_type_property_name": "Hidden",
                        "form_field_input_type_property_description": "hidden",
                        "form_field_data_type_id": 5,
                        "form_field_data_type_name": "Boolean",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 8,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 258,
                        "form_field_input_type_property_name": "Tooltip",
                        "form_field_input_type_property_description": "tooltip",
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 9,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 259,
                        "form_field_input_type_property_name": "Default Value",
                        "form_field_input_type_property_description": "value",
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 10,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    }
                ]
            },
            {
                "form_field_input_type_id": 10,
                "form_field_input_type_name": "Hidden",
                "form_field_input_type_description": null,
                "form_field_input_type_icon": null,
                "form_field_input_type_abbr": "hidden",
                "form_field_input_type_regex": "/^(true|false|1|0)$/i",
                "form_field_input_type_formwiz_name": null,
                "form_field_input_type_property": [
                    {
                        "form_field_input_type_property_id": 260,
                        "form_field_input_type_property_name": "Hidden",
                        "form_field_input_type_property_description": "hidden",
                        "form_field_data_type_id": 5,
                        "form_field_data_type_name": "Boolean",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 2,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 261,
                        "form_field_input_type_property_name": "Title",
                        "form_field_input_type_property_description": "label",
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 1,
                        "form_field_input_type_property_flag_title": true,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 262,
                        "form_field_input_type_property_name": "Tooltip",
                        "form_field_input_type_property_description": "tooltip",
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 3,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    }
                ]
            },
            {
                "form_field_input_type_id": 11,
                "form_field_input_type_name": "Number",
                "form_field_input_type_description": null,
                "form_field_input_type_icon": null,
                "form_field_input_type_abbr": "number",
                "form_field_input_type_regex": "/^-?\\d+(\\.\\d+)?$/",
                "form_field_input_type_formwiz_name": null,
                "form_field_input_type_property": [
                    {
                        "form_field_input_type_property_id": 263,
                        "form_field_input_type_property_name": "Title",
                        "form_field_input_type_property_description": "label",
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 1,
                        "form_field_input_type_property_flag_title": true,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 264,
                        "form_field_input_type_property_name": "Placeholder",
                        "form_field_input_type_property_description": "placeholder",
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 2,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 265,
                        "form_field_input_type_property_name": "Is Required?",
                        "form_field_input_type_property_description": "required",
                        "form_field_data_type_id": 5,
                        "form_field_data_type_name": "Boolean",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 3,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 266,
                        "form_field_input_type_property_name": "Hidden",
                        "form_field_input_type_property_description": "hidden",
                        "form_field_data_type_id": 5,
                        "form_field_data_type_name": "Boolean",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 4,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 267,
                        "form_field_input_type_property_name": "Tooltip",
                        "form_field_input_type_property_description": "tooltip",
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 5,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 268,
                        "form_field_input_type_property_name": "Default Value",
                        "form_field_input_type_property_description": "value",
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 6,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 269,
                        "form_field_input_type_property_name": "Minimum Limits",
                        "form_field_input_type_property_description": "min",
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 7,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 270,
                        "form_field_input_type_property_name": "Maximum Limits",
                        "form_field_input_type_property_description": "max",
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 8,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    }
                ]
            },
            {
                "form_field_input_type_id": 12,
                "form_field_input_type_name": "Phone",
                "form_field_input_type_description": null,
                "form_field_input_type_icon": null,
                "form_field_input_type_abbr": "number",
                "form_field_input_type_regex": "/^[1-9]\\d*(?:\\.\\d+)?$/",
                "form_field_input_type_formwiz_name": null,
                "form_field_input_type_property": [
                    {
                        "form_field_input_type_property_id": 271,
                        "form_field_input_type_property_name": "Title",
                        "form_field_input_type_property_description": "label",
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 1,
                        "form_field_input_type_property_flag_title": true,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 272,
                        "form_field_input_type_property_name": "Placeholder",
                        "form_field_input_type_property_description": "placeholder",
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 2,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 273,
                        "form_field_input_type_property_name": "Is Required?",
                        "form_field_input_type_property_description": "required",
                        "form_field_data_type_id": 5,
                        "form_field_data_type_name": "Boolean",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 3,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 274,
                        "form_field_input_type_property_name": "Hidden",
                        "form_field_input_type_property_description": "hidden",
                        "form_field_data_type_id": 5,
                        "form_field_data_type_name": "Boolean",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 4,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 275,
                        "form_field_input_type_property_name": "Tooltip",
                        "form_field_input_type_property_description": "tooltip",
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 5,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    }
                ]
            },
            {
                "form_field_input_type_id": 13,
                "form_field_input_type_name": "Multiple Chip",
                "form_field_input_type_description": null,
                "form_field_input_type_icon": null,
                "form_field_input_type_abbr": "multiple chip",
                "form_field_input_type_regex": null,
                "form_field_input_type_formwiz_name": null,
                "form_field_input_type_property": [
                    {
                        "form_field_input_type_property_id": 276,
                        "form_field_input_type_property_name": "Title",
                        "form_field_input_type_property_description": "label",
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 1,
                        "form_field_input_type_property_flag_title": true,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 277,
                        "form_field_input_type_property_name": "Placeholder",
                        "form_field_input_type_property_description": "placeholder",
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 2,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 278,
                        "form_field_input_type_property_name": "Options",
                        "form_field_input_type_property_description": "options",
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 3,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 279,
                        "form_field_input_type_property_name": "Is Required?",
                        "form_field_input_type_property_description": "required",
                        "form_field_data_type_id": 5,
                        "form_field_data_type_name": "Boolean",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 4,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 280,
                        "form_field_input_type_property_name": "Tooltip",
                        "form_field_input_type_property_description": "tooltip",
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 5,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 281,
                        "form_field_input_type_property_name": "Default Value",
                        "form_field_input_type_property_description": "value",
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 6,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 282,
                        "form_field_input_type_property_name": "Minimum Limits",
                        "form_field_input_type_property_description": "min",
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 7,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 283,
                        "form_field_input_type_property_name": "Maximum Limits",
                        "form_field_input_type_property_description": "max",
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 8,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    }
                ]
            },
            {
                "form_field_input_type_id": 14,
                "form_field_input_type_name": "Chip",
                "form_field_input_type_description": null,
                "form_field_input_type_icon": null,
                "form_field_input_type_abbr": "chip",
                "form_field_input_type_regex": null,
                "form_field_input_type_formwiz_name": null,
                "form_field_input_type_property": [
                    {
                        "form_field_input_type_property_id": 284,
                        "form_field_input_type_property_name": "Title",
                        "form_field_input_type_property_description": "label",
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 1,
                        "form_field_input_type_property_flag_title": true,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 285,
                        "form_field_input_type_property_name": "Placeholder",
                        "form_field_input_type_property_description": "placeholder",
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 2,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 286,
                        "form_field_input_type_property_name": "Is Required?",
                        "form_field_input_type_property_description": "required",
                        "form_field_data_type_id": 5,
                        "form_field_data_type_name": "Boolean",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 3,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 287,
                        "form_field_input_type_property_name": "Tooltip",
                        "form_field_input_type_property_description": "tooltip",
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 4,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 288,
                        "form_field_input_type_property_name": "Default Value",
                        "form_field_input_type_property_description": "value",
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 5,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    }
                ]
            },
            {
                "form_field_input_type_id": 16,
                "form_field_input_type_name": "Multiple Chip Dropdown",
                "form_field_input_type_description": null,
                "form_field_input_type_icon": null,
                "form_field_input_type_abbr": "multiple chip dropdown",
                "form_field_input_type_regex": null,
                "form_field_input_type_formwiz_name": null,
                "form_field_input_type_property": [
                    {
                        "form_field_input_type_property_id": 297,
                        "form_field_input_type_property_name": "Title",
                        "form_field_input_type_property_description": null,
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 1,
                        "form_field_input_type_property_flag_title": true,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 298,
                        "form_field_input_type_property_name": "Placeholder",
                        "form_field_input_type_property_description": null,
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 2,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 299,
                        "form_field_input_type_property_name": "Is Required?",
                        "form_field_input_type_property_description": null,
                        "form_field_data_type_id": 5,
                        "form_field_data_type_name": "Boolean",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 4,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 300,
                        "form_field_input_type_property_name": "Options",
                        "form_field_input_type_property_description": null,
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 3,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 301,
                        "form_field_input_type_property_name": "Tooltip",
                        "form_field_input_type_property_description": null,
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 5,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 302,
                        "form_field_input_type_property_name": "Default Value",
                        "form_field_input_type_property_description": null,
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 6,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 303,
                        "form_field_input_type_property_name": "Min Chips",
                        "form_field_input_type_property_description": null,
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 7,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 304,
                        "form_field_input_type_property_name": "Max Chips",
                        "form_field_input_type_property_description": null,
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 8,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    }
                ]
            },
            {
                "form_field_input_type_id": 18,
                "form_field_input_type_name": "Toggle Button",
                "form_field_input_type_description": null,
                "form_field_input_type_icon": null,
                "form_field_input_type_abbr": "toggle",
                "form_field_input_type_regex": null,
                "form_field_input_type_formwiz_name": null,
                "form_field_input_type_property": [
                    {
                        "form_field_input_type_property_id": 313,
                        "form_field_input_type_property_name": "Title",
                        "form_field_input_type_property_description": null,
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 1,
                        "form_field_input_type_property_flag_title": true,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 314,
                        "form_field_input_type_property_name": "Is Required?",
                        "form_field_input_type_property_description": null,
                        "form_field_data_type_id": 5,
                        "form_field_data_type_name": "Boolean",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 2,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 315,
                        "form_field_input_type_property_name": "Options",
                        "form_field_input_type_property_description": null,
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 3,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 316,
                        "form_field_input_type_property_name": "Hidden",
                        "form_field_input_type_property_description": null,
                        "form_field_data_type_id": 5,
                        "form_field_data_type_name": "Boolean",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 4,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 317,
                        "form_field_input_type_property_name": "Tooltip",
                        "form_field_input_type_property_description": null,
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 5,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 318,
                        "form_field_input_type_property_name": "Default Value",
                        "form_field_input_type_property_description": null,
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 6,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    }
                ]
            },
            {
                "form_field_input_type_id": 1,
                "form_field_input_type_name": "Text",
                "form_field_input_type_description": null,
                "form_field_input_type_icon": null,
                "form_field_input_type_abbr": "text",
                "form_field_input_type_regex": "/^[A-Za-z\\'s.,!?'\"()\\-]+$/",
                "form_field_input_type_formwiz_name": "text",
                "form_field_input_type_property": [
                    {
                        "form_field_input_type_property_id": 319,
                        "form_field_input_type_property_name": "Helper Text",
                        "form_field_input_type_property_description": "helper_text",
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 9,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 196,
                        "form_field_input_type_property_name": "Title",
                        "form_field_input_type_property_description": "label",
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 1,
                        "form_field_input_type_property_flag_title": true,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 197,
                        "form_field_input_type_property_name": "Placeholder",
                        "form_field_input_type_property_description": "placeholder",
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 2,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 198,
                        "form_field_input_type_property_name": "Is Required?",
                        "form_field_input_type_property_description": "required",
                        "form_field_data_type_id": 5,
                        "form_field_data_type_name": "Boolean",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 3,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 199,
                        "form_field_input_type_property_name": "Hidden",
                        "form_field_input_type_property_description": "hidden",
                        "form_field_data_type_id": 5,
                        "form_field_data_type_name": "Boolean",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 4,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 200,
                        "form_field_input_type_property_name": "Tooltip",
                        "form_field_input_type_property_description": "tooltip",
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 5,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 201,
                        "form_field_input_type_property_name": "Minimum Limits",
                        "form_field_input_type_property_description": "min",
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 7,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 202,
                        "form_field_input_type_property_name": "Maximum Limits",
                        "form_field_input_type_property_description": "max",
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 8,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    }
                ]
            },
            {
                "form_field_input_type_id": 2,
                "form_field_input_type_name": "Long Text",
                "form_field_input_type_description": null,
                "form_field_input_type_icon": null,
                "form_field_input_type_abbr": "longtext",
                "form_field_input_type_regex": null,
                "form_field_input_type_formwiz_name": "longtext",
                "form_field_input_type_property": [
                    {
                        "form_field_input_type_property_id": 320,
                        "form_field_input_type_property_name": "Helper Text",
                        "form_field_input_type_property_description": "helper_text",
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 9,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 203,
                        "form_field_input_type_property_name": "Title",
                        "form_field_input_type_property_description": "label",
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 1,
                        "form_field_input_type_property_flag_title": true,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 204,
                        "form_field_input_type_property_name": "Placeholder",
                        "form_field_input_type_property_description": "placeholder",
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 2,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 205,
                        "form_field_input_type_property_name": "Is Required?",
                        "form_field_input_type_property_description": "required",
                        "form_field_data_type_id": 5,
                        "form_field_data_type_name": "Boolean",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 3,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 206,
                        "form_field_input_type_property_name": "Hidden",
                        "form_field_input_type_property_description": "hidden",
                        "form_field_data_type_id": 5,
                        "form_field_data_type_name": "Boolean",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 4,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 207,
                        "form_field_input_type_property_name": "Tooltip",
                        "form_field_input_type_property_description": "tooltip",
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 5,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 208,
                        "form_field_input_type_property_name": "Minimum Limits",
                        "form_field_input_type_property_description": "min",
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 7,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 209,
                        "form_field_input_type_property_name": "Maximum Limits",
                        "form_field_input_type_property_description": "max",
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 8,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    }
                ]
            },
            {
                "form_field_input_type_id": 3,
                "form_field_input_type_name": "Radio Button",
                "form_field_input_type_description": null,
                "form_field_input_type_icon": null,
                "form_field_input_type_abbr": "radio",
                "form_field_input_type_regex": null,
                "form_field_input_type_formwiz_name": "radio",
                "form_field_input_type_property": [
                    {
                        "form_field_input_type_property_id": 321,
                        "form_field_input_type_property_name": "Helper Text",
                        "form_field_input_type_property_description": "helper_text",
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 7,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 322,
                        "form_field_input_type_property_name": "Direction to column",
                        "form_field_input_type_property_description": "direction_column",
                        "form_field_data_type_id": 5,
                        "form_field_data_type_name": "Boolean",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 8,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 210,
                        "form_field_input_type_property_name": "Title",
                        "form_field_input_type_property_description": "label",
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 1,
                        "form_field_input_type_property_flag_title": true,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 211,
                        "form_field_input_type_property_name": "Is Required?",
                        "form_field_input_type_property_description": "required",
                        "form_field_data_type_id": 5,
                        "form_field_data_type_name": "Boolean",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 3,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 212,
                        "form_field_input_type_property_name": "Options",
                        "form_field_input_type_property_description": "options",
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 2,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 213,
                        "form_field_input_type_property_name": "Hidden",
                        "form_field_input_type_property_description": "hidden",
                        "form_field_data_type_id": 5,
                        "form_field_data_type_name": "Boolean",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 4,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 214,
                        "form_field_input_type_property_name": "Tooltip",
                        "form_field_input_type_property_description": "tooltip",
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 5,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 215,
                        "form_field_input_type_property_name": "Default Value",
                        "form_field_input_type_property_description": "value",
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 6,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    }
                ]
            },
            {
                "form_field_input_type_id": 7,
                "form_field_input_type_name": "Dropdown",
                "form_field_input_type_description": null,
                "form_field_input_type_icon": null,
                "form_field_input_type_abbr": "dropdown",
                "form_field_input_type_regex": null,
                "form_field_input_type_formwiz_name": "dropdown",
                "form_field_input_type_property": [
                    {
                        "form_field_input_type_property_id": 324,
                        "form_field_input_type_property_name": "Is Searchable?",
                        "form_field_input_type_property_description": "is_searchable",
                        "form_field_data_type_id": 5,
                        "form_field_data_type_name": "Boolean",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 8,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 325,
                        "form_field_input_type_property_name": "Is Multiple?",
                        "form_field_input_type_property_description": "is_multiple",
                        "form_field_data_type_id": 5,
                        "form_field_data_type_name": "Boolean",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 9,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 326,
                        "form_field_input_type_property_name": "Add Custom Options",
                        "form_field_input_type_property_description": "is_valid_new_option",
                        "form_field_data_type_id": 5,
                        "form_field_data_type_name": "Boolean",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 10,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 327,
                        "form_field_input_type_property_name": "Minimum Limits",
                        "form_field_input_type_property_description": "min",
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 11,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 328,
                        "form_field_input_type_property_name": "Maximum Limits",
                        "form_field_input_type_property_description": "max",
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 12,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 329,
                        "form_field_input_type_property_name": "Helper Text",
                        "form_field_input_type_property_description": "helper_text",
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 13,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 235,
                        "form_field_input_type_property_name": "Title",
                        "form_field_input_type_property_description": "label",
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 1,
                        "form_field_input_type_property_flag_title": true,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 236,
                        "form_field_input_type_property_name": "Placeholder",
                        "form_field_input_type_property_description": "placeholder",
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 2,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 237,
                        "form_field_input_type_property_name": "Is Required?",
                        "form_field_input_type_property_description": "required",
                        "form_field_data_type_id": 5,
                        "form_field_data_type_name": "Boolean",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 4,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 238,
                        "form_field_input_type_property_name": "Options",
                        "form_field_input_type_property_description": "options",
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 3,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 239,
                        "form_field_input_type_property_name": "Hidden",
                        "form_field_input_type_property_description": "hidden",
                        "form_field_data_type_id": 5,
                        "form_field_data_type_name": "Boolean",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 5,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 240,
                        "form_field_input_type_property_name": "Tooltip",
                        "form_field_input_type_property_description": "tooltip",
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 6,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 241,
                        "form_field_input_type_property_name": "Default Value",
                        "form_field_input_type_property_description": "value",
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 7,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    }
                ]
            },
            {
                "form_field_input_type_id": 15,
                "form_field_input_type_name": "Multiple Chip Text Field",
                "form_field_input_type_description": null,
                "form_field_input_type_icon": null,
                "form_field_input_type_abbr": "multiple chip text field",
                "form_field_input_type_regex": null,
                "form_field_input_type_formwiz_name": "chip",
                "form_field_input_type_property": [
                    {
                        "form_field_input_type_property_id": 289,
                        "form_field_input_type_property_name": "Title",
                        "form_field_input_type_property_description": null,
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 1,
                        "form_field_input_type_property_flag_title": true,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 290,
                        "form_field_input_type_property_name": "Placeholder",
                        "form_field_input_type_property_description": null,
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 2,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 291,
                        "form_field_input_type_property_name": "Is Required?",
                        "form_field_input_type_property_description": null,
                        "form_field_data_type_id": 5,
                        "form_field_data_type_name": "Boolean",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 4,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 292,
                        "form_field_input_type_property_name": "Options",
                        "form_field_input_type_property_description": null,
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 3,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 293,
                        "form_field_input_type_property_name": "Tooltip",
                        "form_field_input_type_property_description": null,
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 5,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 294,
                        "form_field_input_type_property_name": "Default Value",
                        "form_field_input_type_property_description": null,
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 6,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 295,
                        "form_field_input_type_property_name": "Min Chips",
                        "form_field_input_type_property_description": null,
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 7,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 296,
                        "form_field_input_type_property_name": "Max Chips",
                        "form_field_input_type_property_description": null,
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 8,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    }
                ]
            },
            {
                "form_field_input_type_id": 17,
                "form_field_input_type_name": "Multiple Chip Text and Dropdown",
                "form_field_input_type_description": null,
                "form_field_input_type_icon": null,
                "form_field_input_type_abbr": "multiple chip text and dropdown",
                "form_field_input_type_regex": null,
                "form_field_input_type_formwiz_name": "dropdown",
                "form_field_input_type_property": [
                    {
                        "form_field_input_type_property_id": 305,
                        "form_field_input_type_property_name": "Title",
                        "form_field_input_type_property_description": null,
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 1,
                        "form_field_input_type_property_flag_title": true,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 307,
                        "form_field_input_type_property_name": "Is Required?",
                        "form_field_input_type_property_description": null,
                        "form_field_data_type_id": 5,
                        "form_field_data_type_name": "Boolean",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 3,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 308,
                        "form_field_input_type_property_name": "Options",
                        "form_field_input_type_property_description": null,
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 4,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 309,
                        "form_field_input_type_property_name": "Tooltip",
                        "form_field_input_type_property_description": null,
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 5,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 310,
                        "form_field_input_type_property_name": "Default Value",
                        "form_field_input_type_property_description": null,
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 6,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 311,
                        "form_field_input_type_property_name": "Min Chips",
                        "form_field_input_type_property_description": null,
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 7,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 312,
                        "form_field_input_type_property_name": "Max Chips",
                        "form_field_input_type_property_description": null,
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 8,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    },
                    {
                        "form_field_input_type_property_id": 306,
                        "form_field_input_type_property_name": "Placeholder",
                        "form_field_input_type_property_description": null,
                        "form_field_data_type_id": 1,
                        "form_field_data_type_name": "Text",
                        "form_field_data_type_unit": null,
                        "form_field_input_type_property_seq_num": 2,
                        "form_field_input_type_property_flag_title": false,
                        "form_field_input_type_property_default_value": null,
                        "form_field_input_type_additional_property": []
                    }
                ]
            }
        ]
        // [
        //     {
        //         "form_field_input_type_id": 1,
        //         "form_field_input_type_name": "Text",
        //         "form_field_input_type_description": null,
        //         "form_field_input_type_icon": null,
        //         "form_field_input_type_abbr": "text",
        //         "form_field_input_type_regex": "/^[A-Za-z\\'s.,!?'\"()\\-]+$/",
        //         "form_field_input_type_property": [
        //             {
        //                 "form_field_input_type_property_id": 196,
        //                 "form_field_input_type_property_name": "Title",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 1,
        //                 "form_field_data_type_name": "Text",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 1,
        //                 "form_field_input_type_property_flag_title": true,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 197,
        //                 "form_field_input_type_property_name": "Placeholder",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 1,
        //                 "form_field_data_type_name": "Text",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 2,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 198,
        //                 "form_field_input_type_property_name": "Is Required?",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 5,
        //                 "form_field_data_type_name": "Boolean",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 3,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 199,
        //                 "form_field_input_type_property_name": "Hidden",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 5,
        //                 "form_field_data_type_name": "Boolean",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 4,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 200,
        //                 "form_field_input_type_property_name": "Tooltip",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 1,
        //                 "form_field_data_type_name": "Text",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 5,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 201,
        //                 "form_field_input_type_property_name": "Min Length",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 1,
        //                 "form_field_data_type_name": "Text",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 7,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 202,
        //                 "form_field_input_type_property_name": "Max Length",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 1,
        //                 "form_field_data_type_name": "Text",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 8,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             }
        //         ]
        //     },
        //     {
        //         "form_field_input_type_id": 2,
        //         "form_field_input_type_name": "Long Text",
        //         "form_field_input_type_description": null,
        //         "form_field_input_type_icon": null,
        //         "form_field_input_type_abbr": "longtext",
        //         "form_field_input_type_regex": null,
        //         "form_field_input_type_property": [
        //             {
        //                 "form_field_input_type_property_id": 203,
        //                 "form_field_input_type_property_name": "Title",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 1,
        //                 "form_field_data_type_name": "Text",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 1,
        //                 "form_field_input_type_property_flag_title": true,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 204,
        //                 "form_field_input_type_property_name": "Placeholder",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 1,
        //                 "form_field_data_type_name": "Text",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 2,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 205,
        //                 "form_field_input_type_property_name": "Is Required?",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 5,
        //                 "form_field_data_type_name": "Boolean",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 3,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 206,
        //                 "form_field_input_type_property_name": "Hidden",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 5,
        //                 "form_field_data_type_name": "Boolean",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 4,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 207,
        //                 "form_field_input_type_property_name": "Tooltip",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 1,
        //                 "form_field_data_type_name": "Text",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 5,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 208,
        //                 "form_field_input_type_property_name": "Min Length",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 1,
        //                 "form_field_data_type_name": "Text",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 7,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 209,
        //                 "form_field_input_type_property_name": "Max Length",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 1,
        //                 "form_field_data_type_name": "Text",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 8,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             }
        //         ]
        //     },
        //     {
        //         "form_field_input_type_id": 3,
        //         "form_field_input_type_name": "Radio Button",
        //         "form_field_input_type_description": null,
        //         "form_field_input_type_icon": null,
        //         "form_field_input_type_abbr": "radio",
        //         "form_field_input_type_regex": null,
        //         "form_field_input_type_property": [
        //             {
        //                 "form_field_input_type_property_id": 210,
        //                 "form_field_input_type_property_name": "Title",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 1,
        //                 "form_field_data_type_name": "Text",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 1,
        //                 "form_field_input_type_property_flag_title": true,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 211,
        //                 "form_field_input_type_property_name": "Is Required?",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 5,
        //                 "form_field_data_type_name": "Boolean",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 3,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 212,
        //                 "form_field_input_type_property_name": "Options",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 1,
        //                 "form_field_data_type_name": "Text",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 2,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 213,
        //                 "form_field_input_type_property_name": "Hidden",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 5,
        //                 "form_field_data_type_name": "Boolean",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 4,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 214,
        //                 "form_field_input_type_property_name": "Tooltip",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 1,
        //                 "form_field_data_type_name": "Text",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 5,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 215,
        //                 "form_field_input_type_property_name": "Default Value",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 1,
        //                 "form_field_data_type_name": "Text",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 6,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             }
        //         ]
        //     },
        //     {
        //         "form_field_input_type_id": 4,
        //         "form_field_input_type_name": "Checkbox",
        //         "form_field_input_type_description": null,
        //         "form_field_input_type_icon": null,
        //         "form_field_input_type_abbr": "checkbox",
        //         "form_field_input_type_regex": null,
        //         "form_field_input_type_property": [
        //             {
        //                 "form_field_input_type_property_id": 216,
        //                 "form_field_input_type_property_name": "Title",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 1,
        //                 "form_field_data_type_name": "Text",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 1,
        //                 "form_field_input_type_property_flag_title": true,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 217,
        //                 "form_field_input_type_property_name": "Is Required?",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 5,
        //                 "form_field_data_type_name": "Boolean",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 2,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 218,
        //                 "form_field_input_type_property_name": "Hidden",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 5,
        //                 "form_field_data_type_name": "Boolean",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 3,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 219,
        //                 "form_field_input_type_property_name": "Options",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 1,
        //                 "form_field_data_type_name": "Text",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 4,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 220,
        //                 "form_field_input_type_property_name": "Tooltip",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 1,
        //                 "form_field_data_type_name": "Text",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 5,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 221,
        //                 "form_field_input_type_property_name": "Default Value",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 1,
        //                 "form_field_data_type_name": "Text",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 6,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             }
        //         ]
        //     },
        //     {
        //         "form_field_input_type_id": 5,
        //         "form_field_input_type_name": "Email",
        //         "form_field_input_type_description": null,
        //         "form_field_input_type_icon": null,
        //         "form_field_input_type_abbr": "email",
        //         "form_field_input_type_regex": "/^([a-zA-Z0-9\\._]+)@([a-zA-Z)-9])+.([a-z]+)(.[a-z]+)?$/",
        //         "form_field_input_type_property": [
        //             {
        //                 "form_field_input_type_property_id": 222,
        //                 "form_field_input_type_property_name": "Title",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 1,
        //                 "form_field_data_type_name": "Text",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 1,
        //                 "form_field_input_type_property_flag_title": true,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 223,
        //                 "form_field_input_type_property_name": "Is Required?",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 5,
        //                 "form_field_data_type_name": "Boolean",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 3,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 224,
        //                 "form_field_input_type_property_name": "Placeholder",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 1,
        //                 "form_field_data_type_name": "Text",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 2,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 225,
        //                 "form_field_input_type_property_name": "Hidden",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 5,
        //                 "form_field_data_type_name": "Boolean",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 4,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 226,
        //                 "form_field_input_type_property_name": "Tooltip",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 1,
        //                 "form_field_data_type_name": "Text",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 5,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             }
        //         ]
        //     },
        //     {
        //         "form_field_input_type_id": 6,
        //         "form_field_input_type_name": "File Upload",
        //         "form_field_input_type_description": null,
        //         "form_field_input_type_icon": null,
        //         "form_field_input_type_abbr": "file",
        //         "form_field_input_type_regex": null,
        //         "form_field_input_type_property": [
        //             {
        //                 "form_field_input_type_property_id": 227,
        //                 "form_field_input_type_property_name": "Title",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 1,
        //                 "form_field_data_type_name": "Text",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 1,
        //                 "form_field_input_type_property_flag_title": true,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 228,
        //                 "form_field_input_type_property_name": "Placeholder",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 1,
        //                 "form_field_data_type_name": "Text",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 2,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 229,
        //                 "form_field_input_type_property_name": "Maximum Number Of Files",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 2,
        //                 "form_field_data_type_name": "Integer",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 3,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": "1",
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 230,
        //                 "form_field_input_type_property_name": "Maximum File Size",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 4,
        //                 "form_field_data_type_name": "Size",
        //                 "form_field_data_type_unit": "Mb",
        //                 "form_field_input_type_property_seq_num": 4,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": "2",
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 231,
        //                 "form_field_input_type_property_name": "Minimum File Size",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 4,
        //                 "form_field_data_type_name": "Size",
        //                 "form_field_data_type_unit": "Mb",
        //                 "form_field_input_type_property_seq_num": 5,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": "2",
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 232,
        //                 "form_field_input_type_property_name": "Is Required?",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 5,
        //                 "form_field_data_type_name": "Boolean",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 5,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 233,
        //                 "form_field_input_type_property_name": "Hidden",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 5,
        //                 "form_field_data_type_name": "Boolean",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 6,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 234,
        //                 "form_field_input_type_property_name": "Tooltip",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 1,
        //                 "form_field_data_type_name": "Text",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 7,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             }
        //         ]
        //     },
        //     {
        //         "form_field_input_type_id": 7,
        //         "form_field_input_type_name": "Dropdown",
        //         "form_field_input_type_description": null,
        //         "form_field_input_type_icon": null,
        //         "form_field_input_type_abbr": "dropdown",
        //         "form_field_input_type_regex": null,
        //         "form_field_input_type_property": [
        //             {
        //                 "form_field_input_type_property_id": 235,
        //                 "form_field_input_type_property_name": "Title",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 1,
        //                 "form_field_data_type_name": "Text",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 1,
        //                 "form_field_input_type_property_flag_title": true,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 236,
        //                 "form_field_input_type_property_name": "Placeholder",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 1,
        //                 "form_field_data_type_name": "Text",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 2,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 237,
        //                 "form_field_input_type_property_name": "Is Required?",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 5,
        //                 "form_field_data_type_name": "Boolean",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 4,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 238,
        //                 "form_field_input_type_property_name": "Options",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 1,
        //                 "form_field_data_type_name": "Text",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 3,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 239,
        //                 "form_field_input_type_property_name": "Hidden",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 5,
        //                 "form_field_data_type_name": "Boolean",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 5,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 240,
        //                 "form_field_input_type_property_name": "Tooltip",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 1,
        //                 "form_field_data_type_name": "Text",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 6,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 241,
        //                 "form_field_input_type_property_name": "Default Value",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 1,
        //                 "form_field_data_type_name": "Text",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 7,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             }
        //         ]
        //     },
        //     {
        //         "form_field_input_type_id": 8,
        //         "form_field_input_type_name": "Multiple Choice",
        //         "form_field_input_type_description": null,
        //         "form_field_input_type_icon": null,
        //         "form_field_input_type_abbr": "multiple checkbox",
        //         "form_field_input_type_regex": null,
        //         "form_field_input_type_property": [
        //             {
        //                 "form_field_input_type_property_id": 242,
        //                 "form_field_input_type_property_name": "Title",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 1,
        //                 "form_field_data_type_name": "Text",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 1,
        //                 "form_field_input_type_property_flag_title": true,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 243,
        //                 "form_field_input_type_property_name": "Is Required?",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 5,
        //                 "form_field_data_type_name": "Boolean",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 3,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 244,
        //                 "form_field_input_type_property_name": "Options",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 1,
        //                 "form_field_data_type_name": "Text",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 2,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 245,
        //                 "form_field_input_type_property_name": "Hidden",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 5,
        //                 "form_field_data_type_name": "Boolean",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 4,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 246,
        //                 "form_field_input_type_property_name": "Tooltip",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 1,
        //                 "form_field_data_type_name": "Text",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 5,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 247,
        //                 "form_field_input_type_property_name": "Default Value",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 1,
        //                 "form_field_data_type_name": "Text",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 6,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 248,
        //                 "form_field_input_type_property_name": "Min Option Select",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 1,
        //                 "form_field_data_type_name": "Text",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 7,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 249,
        //                 "form_field_input_type_property_name": "Max Option Select",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 1,
        //                 "form_field_data_type_name": "Text",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 8,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             }
        //         ]
        //     },
        //     {
        //         "form_field_input_type_id": 9,
        //         "form_field_input_type_name": "Date",
        //         "form_field_input_type_description": null,
        //         "form_field_input_type_icon": null,
        //         "form_field_input_type_abbr": "date",
        //         "form_field_input_type_regex": null,
        //         "form_field_input_type_property": [
        //             {
        //                 "form_field_input_type_property_id": 250,
        //                 "form_field_input_type_property_name": "Title",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 1,
        //                 "form_field_data_type_name": "Text",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 1,
        //                 "form_field_input_type_property_flag_title": true,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 251,
        //                 "form_field_input_type_property_name": "Placeholder",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 1,
        //                 "form_field_data_type_name": "Text",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 2,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 252,
        //                 "form_field_input_type_property_name": "Is Required?",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 5,
        //                 "form_field_data_type_name": "Boolean",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 3,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 253,
        //                 "form_field_input_type_property_name": "Date Format",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 9,
        //                 "form_field_data_type_name": "Date",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 4,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": "YYYY-MM-DD",
        //                 "form_field_input_type_additional_property": [
        //                     {
        //                         "form_field_input_type_additional_property_id": 1,
        //                         "form_field_input_type_additional_property_name": "MM-DD-YYYY",
        //                         "form_field_input_type_additional_property_description": null,
        //                         "form_field_data_type_id": "9",
        //                         "form_field_data_type_name": "Date",
        //                         "form_field_data_type_unit": null,
        //                         "form_field_input_type_additional_property_seq_num": 1,
        //                         "form_field_input_type_additional_property_default_value": null
        //                     },
        //                     {
        //                         "form_field_input_type_additional_property_id": 2,
        //                         "form_field_input_type_additional_property_name": "DD-MM-YYYY",
        //                         "form_field_input_type_additional_property_description": null,
        //                         "form_field_data_type_id": "9",
        //                         "form_field_data_type_name": "Date",
        //                         "form_field_data_type_unit": null,
        //                         "form_field_input_type_additional_property_seq_num": 2,
        //                         "form_field_input_type_additional_property_default_value": null
        //                     },
        //                     {
        //                         "form_field_input_type_additional_property_id": 3,
        //                         "form_field_input_type_additional_property_name": "YYYY-MM-DD",
        //                         "form_field_input_type_additional_property_description": null,
        //                         "form_field_data_type_id": "9",
        //                         "form_field_data_type_name": "Date",
        //                         "form_field_data_type_unit": null,
        //                         "form_field_input_type_additional_property_seq_num": 3,
        //                         "form_field_input_type_additional_property_default_value": "YYYY-MM-DD"
        //                     }
        //                 ]
        //             },
        //             {
        //                 "form_field_input_type_property_id": 254,
        //                 "form_field_input_type_property_name": "Default Date",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 9,
        //                 "form_field_data_type_name": "Date",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 5,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": [
        //                     {
        //                         "form_field_input_type_additional_property_id": 4,
        //                         "form_field_input_type_additional_property_name": "NONE",
        //                         "form_field_input_type_additional_property_description": null,
        //                         "form_field_data_type_id": "9",
        //                         "form_field_data_type_name": "Date",
        //                         "form_field_data_type_unit": null,
        //                         "form_field_input_type_additional_property_seq_num": 1,
        //                         "form_field_input_type_additional_property_default_value": null
        //                     },
        //                     {
        //                         "form_field_input_type_additional_property_id": 5,
        //                         "form_field_input_type_additional_property_name": "CURRENT",
        //                         "form_field_input_type_additional_property_description": null,
        //                         "form_field_data_type_id": "9",
        //                         "form_field_data_type_name": "Date",
        //                         "form_field_data_type_unit": null,
        //                         "form_field_input_type_additional_property_seq_num": 2,
        //                         "form_field_input_type_additional_property_default_value": null
        //                     },
        //                     {
        //                         "form_field_input_type_additional_property_id": 6,
        //                         "form_field_input_type_additional_property_name": "CUSTOM",
        //                         "form_field_input_type_additional_property_description": null,
        //                         "form_field_data_type_id": "9",
        //                         "form_field_data_type_name": "Date",
        //                         "form_field_data_type_unit": null,
        //                         "form_field_input_type_additional_property_seq_num": 3,
        //                         "form_field_input_type_additional_property_default_value": null
        //                     }
        //                 ]
        //             },
        //             {
        //                 "form_field_input_type_property_id": 255,
        //                 "form_field_input_type_property_name": "Max Date",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 9,
        //                 "form_field_data_type_name": "Date",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 6,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 256,
        //                 "form_field_input_type_property_name": "Min Date",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 9,
        //                 "form_field_data_type_name": "Date",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 7,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 257,
        //                 "form_field_input_type_property_name": "Hidden",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 5,
        //                 "form_field_data_type_name": "Boolean",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 8,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 258,
        //                 "form_field_input_type_property_name": "Tooltip",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 1,
        //                 "form_field_data_type_name": "Text",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 9,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 259,
        //                 "form_field_input_type_property_name": "Default Value",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 1,
        //                 "form_field_data_type_name": "Text",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 10,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             }
        //         ]
        //     },
        //     {
        //         "form_field_input_type_id": 10,
        //         "form_field_input_type_name": "Hidden",
        //         "form_field_input_type_description": null,
        //         "form_field_input_type_icon": null,
        //         "form_field_input_type_abbr": "hidden",
        //         "form_field_input_type_regex": "/^(true|false|1|0)$/i",
        //         "form_field_input_type_property": [
        //             {
        //                 "form_field_input_type_property_id": 260,
        //                 "form_field_input_type_property_name": "Hidden",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 5,
        //                 "form_field_data_type_name": "Boolean",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 2,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 261,
        //                 "form_field_input_type_property_name": "Title",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 1,
        //                 "form_field_data_type_name": "Text",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 1,
        //                 "form_field_input_type_property_flag_title": true,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 262,
        //                 "form_field_input_type_property_name": "Tooltip",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 1,
        //                 "form_field_data_type_name": "Text",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 3,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             }
        //         ]
        //     },
        //     {
        //         "form_field_input_type_id": 11,
        //         "form_field_input_type_name": "Number",
        //         "form_field_input_type_description": null,
        //         "form_field_input_type_icon": null,
        //         "form_field_input_type_abbr": "number",
        //         "form_field_input_type_regex": "/^-?\\d+(\\.\\d+)?$/",
        //         "form_field_input_type_property": [
        //             {
        //                 "form_field_input_type_property_id": 263,
        //                 "form_field_input_type_property_name": "Title",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 1,
        //                 "form_field_data_type_name": "Text",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 1,
        //                 "form_field_input_type_property_flag_title": true,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 264,
        //                 "form_field_input_type_property_name": "Placeholder",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 1,
        //                 "form_field_data_type_name": "Text",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 2,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 265,
        //                 "form_field_input_type_property_name": "Is Required?",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 5,
        //                 "form_field_data_type_name": "Boolean",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 3,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 266,
        //                 "form_field_input_type_property_name": "Hidden",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 5,
        //                 "form_field_data_type_name": "Boolean",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 4,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 267,
        //                 "form_field_input_type_property_name": "Tooltip",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 1,
        //                 "form_field_data_type_name": "Text",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 5,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 268,
        //                 "form_field_input_type_property_name": "Default Value",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 1,
        //                 "form_field_data_type_name": "Text",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 6,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 269,
        //                 "form_field_input_type_property_name": "Min Value",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 1,
        //                 "form_field_data_type_name": "Text",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 7,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 270,
        //                 "form_field_input_type_property_name": "Max Value",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 1,
        //                 "form_field_data_type_name": "Text",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 8,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             }
        //         ]
        //     },
        //     {
        //         "form_field_input_type_id": 12,
        //         "form_field_input_type_name": "Phone",
        //         "form_field_input_type_description": null,
        //         "form_field_input_type_icon": null,
        //         "form_field_input_type_abbr": "number",
        //         "form_field_input_type_regex": "/^[1-9]\\d*(?:\\.\\d+)?$/",
        //         "form_field_input_type_property": [
        //             {
        //                 "form_field_input_type_property_id": 271,
        //                 "form_field_input_type_property_name": "Title",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 1,
        //                 "form_field_data_type_name": "Text",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 1,
        //                 "form_field_input_type_property_flag_title": true,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 272,
        //                 "form_field_input_type_property_name": "Placeholder",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 1,
        //                 "form_field_data_type_name": "Text",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 2,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 273,
        //                 "form_field_input_type_property_name": "Is Required?",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 5,
        //                 "form_field_data_type_name": "Boolean",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 3,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 274,
        //                 "form_field_input_type_property_name": "Hidden",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 5,
        //                 "form_field_data_type_name": "Boolean",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 4,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 275,
        //                 "form_field_input_type_property_name": "Tooltip",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 1,
        //                 "form_field_data_type_name": "Text",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 5,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             }
        //         ]
        //     },
        //     {
        //         "form_field_input_type_id": 13,
        //         "form_field_input_type_name": "Multiple Chip",
        //         "form_field_input_type_description": null,
        //         "form_field_input_type_icon": null,
        //         "form_field_input_type_abbr": "multiple chip",
        //         "form_field_input_type_regex": null,
        //         "form_field_input_type_property": [
        //             {
        //                 "form_field_input_type_property_id": 276,
        //                 "form_field_input_type_property_name": "Title",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 1,
        //                 "form_field_data_type_name": "Text",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 1,
        //                 "form_field_input_type_property_flag_title": true,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 277,
        //                 "form_field_input_type_property_name": "Placeholder",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 1,
        //                 "form_field_data_type_name": "Text",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 2,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 278,
        //                 "form_field_input_type_property_name": "Options",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 1,
        //                 "form_field_data_type_name": "Text",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 3,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 279,
        //                 "form_field_input_type_property_name": "Is Required?",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 5,
        //                 "form_field_data_type_name": "Boolean",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 4,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 280,
        //                 "form_field_input_type_property_name": "Tooltip",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 1,
        //                 "form_field_data_type_name": "Text",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 5,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 281,
        //                 "form_field_input_type_property_name": "Default Value",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 1,
        //                 "form_field_data_type_name": "Text",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 6,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 282,
        //                 "form_field_input_type_property_name": "Min Chips",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 1,
        //                 "form_field_data_type_name": "Text",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 7,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 283,
        //                 "form_field_input_type_property_name": "Max Chips",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 1,
        //                 "form_field_data_type_name": "Text",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 8,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             }
        //         ]
        //     },
        //     {
        //         "form_field_input_type_id": 14,
        //         "form_field_input_type_name": "Chip",
        //         "form_field_input_type_description": null,
        //         "form_field_input_type_icon": null,
        //         "form_field_input_type_abbr": "chip",
        //         "form_field_input_type_regex": null,
        //         "form_field_input_type_property": [
        //             {
        //                 "form_field_input_type_property_id": 284,
        //                 "form_field_input_type_property_name": "Title",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 1,
        //                 "form_field_data_type_name": "Text",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 1,
        //                 "form_field_input_type_property_flag_title": true,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 285,
        //                 "form_field_input_type_property_name": "Placeholder",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 1,
        //                 "form_field_data_type_name": "Text",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 2,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 286,
        //                 "form_field_input_type_property_name": "Is Required?",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 5,
        //                 "form_field_data_type_name": "Boolean",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 3,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 287,
        //                 "form_field_input_type_property_name": "Tooltip",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 1,
        //                 "form_field_data_type_name": "Text",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 4,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 288,
        //                 "form_field_input_type_property_name": "Default Value",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 1,
        //                 "form_field_data_type_name": "Text",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 5,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             }
        //         ]
        //     },
        //     {
        //         "form_field_input_type_id": 15,
        //         "form_field_input_type_name": "Multiple Chip Text Field",
        //         "form_field_input_type_description": null,
        //         "form_field_input_type_icon": null,
        //         "form_field_input_type_abbr": "multiple chip text field",
        //         "form_field_input_type_regex": null,
        //         "form_field_input_type_property": [
        //             {
        //                 "form_field_input_type_property_id": 289,
        //                 "form_field_input_type_property_name": "Title",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 1,
        //                 "form_field_data_type_name": "Text",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 1,
        //                 "form_field_input_type_property_flag_title": true,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 290,
        //                 "form_field_input_type_property_name": "Placeholder",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 1,
        //                 "form_field_data_type_name": "Text",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 2,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 291,
        //                 "form_field_input_type_property_name": "Is Required?",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 5,
        //                 "form_field_data_type_name": "Boolean",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 4,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 292,
        //                 "form_field_input_type_property_name": "Options",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 1,
        //                 "form_field_data_type_name": "Text",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 3,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 293,
        //                 "form_field_input_type_property_name": "Tooltip",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 1,
        //                 "form_field_data_type_name": "Text",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 5,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 294,
        //                 "form_field_input_type_property_name": "Default Value",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 1,
        //                 "form_field_data_type_name": "Text",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 6,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 295,
        //                 "form_field_input_type_property_name": "Min Chips",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 1,
        //                 "form_field_data_type_name": "Text",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 7,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 296,
        //                 "form_field_input_type_property_name": "Max Chips",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 1,
        //                 "form_field_data_type_name": "Text",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 8,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             }
        //         ]
        //     },
        //     {
        //         "form_field_input_type_id": 16,
        //         "form_field_input_type_name": "Multiple Chip Dropdown",
        //         "form_field_input_type_description": null,
        //         "form_field_input_type_icon": null,
        //         "form_field_input_type_abbr": "multiple chip dropdown",
        //         "form_field_input_type_regex": null,
        //         "form_field_input_type_property": [
        //             {
        //                 "form_field_input_type_property_id": 297,
        //                 "form_field_input_type_property_name": "Title",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 1,
        //                 "form_field_data_type_name": "Text",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 1,
        //                 "form_field_input_type_property_flag_title": true,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 298,
        //                 "form_field_input_type_property_name": "Placeholder",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 1,
        //                 "form_field_data_type_name": "Text",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 2,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 299,
        //                 "form_field_input_type_property_name": "Is Required?",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 5,
        //                 "form_field_data_type_name": "Boolean",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 4,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 300,
        //                 "form_field_input_type_property_name": "Options",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 1,
        //                 "form_field_data_type_name": "Text",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 3,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 301,
        //                 "form_field_input_type_property_name": "Tooltip",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 1,
        //                 "form_field_data_type_name": "Text",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 5,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 302,
        //                 "form_field_input_type_property_name": "Default Value",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 1,
        //                 "form_field_data_type_name": "Text",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 6,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 303,
        //                 "form_field_input_type_property_name": "Min Chips",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 1,
        //                 "form_field_data_type_name": "Text",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 7,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 304,
        //                 "form_field_input_type_property_name": "Max Chips",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 1,
        //                 "form_field_data_type_name": "Text",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 8,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             }
        //         ]
        //     },
        //     {
        //         "form_field_input_type_id": 17,
        //         "form_field_input_type_name": "Multiple Chip Text and Dropdown",
        //         "form_field_input_type_description": null,
        //         "form_field_input_type_icon": null,
        //         "form_field_input_type_abbr": "multiple chip text and dropdown",
        //         "form_field_input_type_regex": null,
        //         "form_field_input_type_property": [
        //             {
        //                 "form_field_input_type_property_id": 305,
        //                 "form_field_input_type_property_name": "Title",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 1,
        //                 "form_field_data_type_name": "Text",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 1,
        //                 "form_field_input_type_property_flag_title": true,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 307,
        //                 "form_field_input_type_property_name": "Is Required?",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 5,
        //                 "form_field_data_type_name": "Boolean",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 3,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 308,
        //                 "form_field_input_type_property_name": "Options",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 1,
        //                 "form_field_data_type_name": "Text",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 4,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 309,
        //                 "form_field_input_type_property_name": "Tooltip",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 1,
        //                 "form_field_data_type_name": "Text",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 5,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 310,
        //                 "form_field_input_type_property_name": "Default Value",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 1,
        //                 "form_field_data_type_name": "Text",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 6,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 311,
        //                 "form_field_input_type_property_name": "Min Chips",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 1,
        //                 "form_field_data_type_name": "Text",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 7,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 312,
        //                 "form_field_input_type_property_name": "Max Chips",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 1,
        //                 "form_field_data_type_name": "Text",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 8,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 306,
        //                 "form_field_input_type_property_name": "Placeholder",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 1,
        //                 "form_field_data_type_name": "Text",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 2,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             }
        //         ]
        //     },
        //     {
        //         "form_field_input_type_id": 18,
        //         "form_field_input_type_name": "Toggle Button",
        //         "form_field_input_type_description": null,
        //         "form_field_input_type_icon": null,
        //         "form_field_input_type_abbr": "toggle",
        //         "form_field_input_type_regex": null,
        //         "form_field_input_type_property": [
        //             {
        //                 "form_field_input_type_property_id": 313,
        //                 "form_field_input_type_property_name": "Title",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 1,
        //                 "form_field_data_type_name": "Text",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 1,
        //                 "form_field_input_type_property_flag_title": true,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 314,
        //                 "form_field_input_type_property_name": "Is Required?",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 5,
        //                 "form_field_data_type_name": "Boolean",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 2,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 315,
        //                 "form_field_input_type_property_name": "Options",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 1,
        //                 "form_field_data_type_name": "Text",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 3,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 316,
        //                 "form_field_input_type_property_name": "Hidden",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 5,
        //                 "form_field_data_type_name": "Boolean",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 4,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 317,
        //                 "form_field_input_type_property_name": "Tooltip",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 1,
        //                 "form_field_data_type_name": "Text",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 5,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             },
        //             {
        //                 "form_field_input_type_property_id": 318,
        //                 "form_field_input_type_property_name": "Default Value",
        //                 "form_field_input_type_property_description": null,
        //                 "form_field_data_type_id": 1,
        //                 "form_field_data_type_name": "Text",
        //                 "form_field_data_type_unit": null,
        //                 "form_field_input_type_property_seq_num": 6,
        //                 "form_field_input_type_property_flag_title": false,
        //                 "form_field_input_type_property_default_value": null,
        //                 "form_field_input_type_additional_property": []
        //             }
        //         ]
        //     }
        // ]
    )
    const [promptActionData, setPromptActionData] = useState();
    const [promptContextData, setPromptContextData] = useState();
    const GetTemplateList = async () => {
        const response = await agent.GetTemplateList.get();
        if (response.status) {
            setTemplateList(response.data);
        } else {
            setTemplateList(null)
        }
    }
    const payload = {
        templateList,
        setTemplateList,
        GetTemplateList,
        templateFormFieldsGenericData,
        templateFormFieldList,
        setTemplateFormFieldList,
        promptActionData,
        setPromptActionData,
        promptContextData,
        setPromptContextData
    };

    return (
        <TemplateContext.Provider value={payload}>
            {children}
        </TemplateContext.Provider>
    );
};

export { TemplateContext, TemplateProvider };
