import axios, { AxiosError } from 'axios';

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
// axios.defaults.baseURL = "http://192.168.1.2:3000";

const additionalKey = 'route_source'; // The key you want to add
const additionalValue = 'Web App'; // The value for that key

const addKeyToPayload = (body) => {
    // Automatically adds the key-value pair to every payload
    return { ...body, [additionalKey]: additionalValue };
};

const addKeyToParams = (url) => {
    // Automatically adds the key-value pair to every URL as query parameters
    const urlObject = new URL(url, window.location.origin);
    urlObject.searchParams.set(additionalKey, additionalValue);
    return urlObject.pathname + urlObject.search;
};
const responseBody = (response) => {
    if (response?.Title === undefined)
        return response.data
}

const Error = (err) => {
    // @ts-ignore
    if (err?.response !== null && err?.response !== undefined) {
        if ((err?.response.status === 401 || err?.response.status === 403) && window.location.pathname !== "/") {
            window.location.pathname = "/";
        }
        else if (err?.response?.status == 404 && !window.location.pathname.includes("page-not-found")) {
            window.location.pathname = "/page-not-found";
        }
    }
    return err;
}

const requests = {
    get: async (url) => {
        try {
            // controller = new AbortController();
            url = addKeyToParams(url)
            const response = await axios.get(url, {
                headers: {
                    "Content-Type": "application/json"
                },
            });
            // @ts-ignore
            if (response?.flagStatus === false && response?.error !== "" && response?.error !== null && response?.error !== undefined) {
                alert("Something went wrong.!");
            }
            else {
                return responseBody(response);
            }
        } catch (err) {
            return Error(err);
        }
    },
    post: async (url, body) => {
        try {
            body = addKeyToPayload(body);
            const response = await axios.post(url, body, {
                headers: {
                    "Content-Type": "application/json"
                }
            });
            return responseBody(response);
        } catch (err) {
            return Error(err);
        }
    },
    put: async (url, body) => {
        try {
            body = addKeyToPayload(body);
            const response = await axios.put(url, body, {
                headers: {
                    "Content-Type": "application/json"
                }
            });
            return responseBody(response);
        } catch (err) {
            return Error(err);
        }
    },
    delete: async (url) => {
        try {
            url = addKeyToParams(url); // Add key to URL params
            const response = await axios.delete(url, {
                headers: {
                    "Content-Type": "application/json"
                }
            });
            return responseBody(response);
        } catch (err) {
            return Error(err);
        }
    }
};
const UserMeAPI = {
    get: () => requests.get("/api/user/get-user-detail")
}
const SendLoginOTPAPI = {
    post: (payload) => requests.post("api/user/auth", payload)
}
const VerifyLoginOTPAPI = {
    put: (payload) => requests.put("api/user/auth", payload)
}
const GetPromptList = {
    get: (count) => requests.get(`/api/dev_portal/prompts/latest?counts=${count}`) //for showing chatgpt response
}
const CreatePrompt = {
    post: (payload) => requests.post("/api/dev_portal/prompts/create-prompt", payload)
}
const DeletePrompt = {
    delete: (prompt_id) => requests.delete(`/api/dev_portal/prompts/delete-prompt?prompt_id=${prompt_id}`)
}
const UpdatePrompt = {
    put: (payload) => requests.put("/api/dev_portal/prompts/update-prompt", payload)
}
const GetTemplateList = {
    get: () => requests.get("api/template/get-template-list")
}
const GetPrompts = {
    get: () => requests.get("api/ai/get-prompt-list")
}
const GetPromptDetails = {
    get: (prompt_id) => requests.get(`api/dev_portal/prompts/get-prompt-detail?prompt_id=${prompt_id}`)
}
const createCustomTemplate = {
    post: (payload) => requests.post('api/template/create-custom-template', payload)
}
const updateCustomTemplate = {
    put: (payload) => requests.put('api/template/update-custom-template', payload)
}
const getGenericFormFieldDetails = {
    get: () => requests.get('api/dev_portal/form-fields/get-form-field-data')
}
const executePrompt = {
    post: (payload) => requests.post('api/ai/execute-prompt', payload)
}
const updateTemplateVisibility = {
    put: (payload) => requests.put('api/dev_portal/templates/update-template-visibility', payload)
}
const GetCustomerList = {
    get: () => requests.get('api/dev_portal/user/get-user-data')
}
const LogoutUser = {
    post: () => requests.post("api/user/logout-user", {})
}

const getUserDetails = {
    get: (customer_email) => requests.get(`api/dev_portal/user/user-details?customer_email=${customer_email}`)
}
const getPromptPropertyList = {
    get: (prompt_id) => requests.get(`api/dev_portal/prompts/get-prompt-property-list?prompt_id=${prompt_id}`)
}
const createPromptProperty = {
    post: (createPromptPropertyPayload) => requests.post("api/dev_portal/prompts/create-prompt-property", createPromptPropertyPayload)
}
const getGenericOutputFormatData = {
    get: () => requests.get("api/global/get-output-format-generic-list")
}
const getGenericModelList = {
    get: () => requests.get("api/dev_portal/prompts/get-model-generic-list")
}
const createPromptVariant = {
    post: (createPromptVariantPayload) => requests.post("api/dev_portal/prompts/create-prompt-variant", createPromptVariantPayload)
}
const updatePromptVariant = {
    put: (updatePromptVariantPayload) => requests.put("api/dev_portal/prompts/update-prompt-variant", updatePromptVariantPayload)
}
const deletePromptProperty = {
    delete: (prompt_property_id) => requests.delete(`api/dev_portal/prompts/delete-prompt-property?prompt_property_id=${prompt_property_id}`)
}
const updatePromptProperty = {
    put: (updatePromptProperty) => requests.put(`api/dev_portal/prompts/update-prompt-property`, updatePromptProperty)
}
const deletePromptVariant = {
    delete: (prompt_id, prompt_variant_id) => requests.delete(`/api/dev_portal/prompts/delete-prompt-variant?prompt_id=${prompt_id}&prompt_variant_id=${prompt_variant_id}`)
}
const getLoaderUserTypeDataList = {
    get: () => requests.get('api/popup/get-loader-user-type-list')
}
const getModuleDataList = {
    get: () => requests.get('api/popup/get-module-data-list')
}
const getImageGroups = {
    get: () => requests.get('api/popup/get-image-groups')
}
const uploadFileToGoogleStorage = {
    post: (payload) => requests.post('api/storage/file', payload)
}
const createImageGroupAssignment = {
    post: (payload) => requests.post('api/popup/image-group-assignment', payload)
}
const updateImageGroupAssignment = {
    put: (payload) => requests.put('api/popup/image-group-assignment', payload)
}
const deleteImageGroupAssignment = {
    delete: (image_group_assignment_id, image_group_id) => requests.delete(`api/popup/image-group-assignment?image_group_assignment_id=${image_group_assignment_id}&image_group_id=${image_group_id}`)
}
const contWithGoogle = {
    get: (currentUrl, gc_integration_type_id) => requests.get(`apiIntegration/api/oauth/outbound/auth-url?currentUrl=${currentUrl}&gc_integration_type_id=${gc_integration_type_id}`)
}

const getCustomerPromptLogDetails = {
    get: (
        prompt_log_id,
        user_assignment_id,
        from_date,
        to_date,
        private_user_first_name,
        customer_dev_flag,
        private_user_email,
        prompt_name,
        page_number,
        records_per_page,

    ) => requests.get(`api/dev_portal/customers?prompt_log_id=${prompt_log_id}&user_assignment_id=${user_assignment_id}&from_date=${from_date}&to_date=${to_date}&private_user_first_name=${private_user_first_name}&customer_dev_flag=${customer_dev_flag}&private_user_email=${private_user_email}&prompt_name=${prompt_name}&page_number=${page_number}&records_per_page=${records_per_page}`)
}
const agent = {
    GetPromptList,
    CreatePrompt,
    DeletePrompt,
    UpdatePrompt,
    UserMeAPI,
    SendLoginOTPAPI,
    VerifyLoginOTPAPI,
    GetTemplateList,
    GetPrompts,
    GetPromptDetails,
    createCustomTemplate,
    updateCustomTemplate,
    getGenericFormFieldDetails,
    executePrompt,
    updateTemplateVisibility,
    GetCustomerList,
    LogoutUser,
    getUserDetails,
    getPromptPropertyList,
    createPromptProperty,
    getGenericOutputFormatData,
    getGenericModelList,
    createPromptVariant,
    deletePromptProperty,
    updatePromptProperty,
    deletePromptVariant,
    updatePromptVariant,
    getLoaderUserTypeDataList,
    getModuleDataList,
    getImageGroups,
    uploadFileToGoogleStorage,
    createImageGroupAssignment,
    updateImageGroupAssignment,
    deleteImageGroupAssignment,
    getCustomerPromptLogDetails,
    contWithGoogle
};

export default agent;