import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import { Grid, Snackbar, Alert, } from "@mui/material";
// import { FormSubmissionAPI, GetWidgetDetailsAPI } from '../app.service';
import { useParams } from "react-router-dom";
import GlobalForm from "../utils/formwiz/globalForm";
import { useDebounce, useDebouncedCallback } from "use-debounce";
import GlobalButton from "../components/button";
import globalStyles from "../assets/styles/global.module.scss";
import RoundLoader from "../components/roundLoader";
const TemplateFormPreview = (props) => {
    const userToken = useParams();
    const [widget_favicon_url, set_Widget_favicon_url] = useState("");
    const [widget_logo_url, set_Widget_logo_url] = useState("");
    const [submissionID, setsubmissionID] = useState(null);
    const [showForm, setShowForm] = useState(true);
    const [loaderFlag, setLoaderFlag] = useState(false);
    const [flagFirstRenderDone, setFlagFirstRenderDone] = useState(false)
    const [renderValue, setRenderValue] = useState({
        status: null,
        message: "",
        submission_id: null
    })

    // const [props.formwizFieldsData, props.setFormwizFieldsData] = useState({
    //     data: {
    //         global_form_field: [
    //             {
    //                 "page_field_id": 1666,
    //                 "label": "What is this blog about",
    //                 "placeholder": "What is this blog about",
    //                 "required": true,
    //                 "name": "1666_First_Name ",
    //                 "type": "text",
    //                 "value": "travel vlog to Kyoto",
    //                 "hidden": false,
    //                 "position": null,
    //                 "font_color": null,
    //                 "col": 6,
    //             },
    //             {
    //                 "page_field_id": 1667,
    //                 "label": "Last Name",
    //                 "placeholder": "Enter Last Name",
    //                 "required": false,
    //                 "name": "1667_First_Name ",
    //                 "type": "text",
    //                 "value": "",
    //                 "hidden": false,
    //                 "position": null,
    //                 "font_color": null,
    //                 "col": 6
    //             },
    //             {
    //                 "page_field_id": 1668,
    //                 "label": "Email",
    //                 "placeholder": "Enter Email",
    //                 "required": true,
    //                 "name": "1668_Email ",
    //                 "type": "email",
    //                 "value": "",
    //                 "hidden": false,
    //                 "position": null,
    //                 "font_color": null,
    //                 "col": 12
    //             },
    //             {
    //                 "page_field_id": 1669,
    //                 "label": "Phone",
    //                 "placeholder": "Enter Phone",
    //                 "required": false,
    //                 "name": "1669_Phone ",
    //                 "type": "phone",
    //                 "value": "",
    //                 "hidden": false,
    //                 "position": null,
    //                 "font_color": null,
    //                 "col": 12
    //             },
    //             {
    //                 "page_field_id": 1676,
    //                 "label": "Phone",
    //                 "placeholder": "Enter phone",
    //                 "required": false,
    //                 "name": "1676_Phone ",
    //                 "type": "number",
    //                 "value": "",
    //                 "hidden": false,
    //                 "position": null,
    //                 "font_color": null,
    //                 "col": 12
    //             },
    //             {
    //                 "page_field_id": 1591,
    //                 "required": true,
    //                 "name": "1669_recaptcha",
    //                 "type": "recaptcha",
    //                 "value": "",
    //                 "hidden": false,
    //                 "position": "center",
    //                 "font_color": null,
    //                 "reCAPTCHAsitekey": "",
    //                 "col": 12,
    //                 "label": "reCAPTCHA",

    //             },
    //             // {
    //             //   "page_field_id": 1669,
    //             //   "required": true,
    //             //   "name": "1669_recaptcha",
    //             //   "type": "recaptcha",
    //             //   "value": "",
    //             //   "hidden": false,
    //             //   "position": null,
    //             //   "font_color": null,
    //             //   "col": 12
    //             // },
    //             {
    //                 "page_field_id": 1670,
    //                 "label": "Register",
    //                 "placeholder": null,
    //                 "required": false,
    //                 "name": "1670_Submit ",
    //                 "type": "submit",
    //                 "value": "",
    //                 "hidden": false,
    //                 "position": "center",
    //                 "font_color": "#FFFFFF",
    //                 "background_color": "#D37F2B",
    //                 "col": 12,
    //                 "flag_ai_suggestion": false
    //             }
    //         ],
    //         doc_page_theme: [
    //             {
    //                 "page_id": "682",
    //                 "page_theme_font_type": "Open Sans",
    //                 "page_theme_font_style": "normal",
    //                 "page_theme_font_align": "left",
    //                 "page_theme_font_weight": "bold",
    //                 "page_theme_font_size": "15",
    //                 "page_theme_background_color": "#000 !important",
    //                 "page_theme_heading_color": "#EFE4F1",
    //                 "page_theme_text_decoration": "unset",
    //                 "page_hide_button": false,
    //                 "page_auto_save": false
    //             }
    //         ]
    //     }
    // });
    const [flagPending, setFlagPending] = useState(false)
    const [googleIdFinal, setGoogleIdFinal] = useState([]);

    // const [debouncedInputValue] = useDebounce(props.formwizFieldsData, 2000);
    // setting the value in props.formwizFieldsData in 500 instead of 2000
    const [debouncedInputValue] = useDebounce(props.formwizFieldsData, 500);

    useEffect(() => {
        // setLoaderFlag(true)

        // GetWidgetDetailsAPI(
        //     userToken.token,
        //     (res) => {
        //         props.setFormwizFieldsData(res.data)
        //         if (res.data.status) {
        //             document.title = res.data.data.widget_name
        //             setShowForm(true)
        //             setLoaderFlag(false)
        //         }
        //         else {
        //             setShowForm(false)
        //             window.open('https://app.filemaster.io/login', '_self');
        //         }
        //     },
        //     (err) => {
        //         console.log("err", err);
        //     });
    }, [])

    useEffect(() => {

        let widget_favicon_url = "https://api.filemaster.io/api/storage/file?file_id=" + props.formwizFieldsData.data.widget_favicon_id +
            "&flag_info=false";
        set_Widget_favicon_url(widget_favicon_url)
        let widget_logo_url = "https://api.filemaster.io/api/storage/file?file_id=" + props.formwizFieldsData.data.widget_logo_id + "&flag_info=false";
        set_Widget_logo_url(widget_logo_url)
        if (flagFirstRenderDone)
            debounced()

    }, [props.formwizFieldsData])

    const debounced = useDebouncedCallback(
        () => {
            if (props.formwizFieldsData.data.widget_flag_auto_save)
                // here changing the 3000 timespan to 1500
                formSubmitFunction(props.formwizFieldsData)
        }, 1500);


    const handleCloseSnackBar = () => {
        setRenderValue({
            status: null,
            message: "",
            submission_id: null
        })
    }

    const setFieldValue = (data) => {
        // check here for disable condition of button

        var tempdata = { ...props.formwizFieldsData }
        tempdata.data.global_form_field.map((newvalue) => {

            if (newvalue.options) {
                newvalue.options.map((option_value) => {
                    if (option_value.field_option_data_id == data.field_option_data_id) {
                        option_value.field_option_flag = data.page_field_value
                    }
                })
            }
            if (newvalue.page_field_id === data.page_field_id) {
                newvalue.value = data.page_field_value
            }
            if (newvalue.type == "file") {
                setGoogleIdFinal(newvalue.value)
            }
        })
        // checking for generatebuttonDisableCondition
        const globalFormFields = tempdata.data.global_form_field;
        let isAnyRequiredFieldEmpty = false;
        for (const field of globalFormFields) {
            if (field.required == true && (field.value === "" || field.value === null)) {
                isAnyRequiredFieldEmpty = true; // Found at least one empty required field
                break;
            }
        }
        // setFlagGenerateButtonDisabled(isAnyRequiredFieldEmpty);
        props.setFormwizFieldsData(tempdata)
        setFlagFirstRenderDone(true)
    }

    const formSubmitFunction = async (data) => {


        var finalData = { ...debouncedInputValue.data };

        if (data.submit_button || (props.formwizFieldsData.data.widget_flag_auto_save && !data.submit_button)) {

            delete finalData.timestamp
            delete finalData.token
            delete finalData.widget_domain
            delete finalData.widget_font_color
            delete finalData.widget_favicon_id
            delete finalData.widget_flag_auto_save
            delete finalData.widget_flag_hide_button
            delete finalData.widget_logo_align
            delete finalData.widget_logo_id
            delete finalData.widget_name
            delete finalData.widget_page
            delete finalData.doc_page_theme
            delete finalData.widget_logo_size
            delete finalData.widget_preview_color
            delete finalData.widget_sub_heading
            delete finalData.widget_type
            delete finalData.widget_title
        }
        if (data.submit_button) {
            finalData['widget_flag_submit'] = true
        }
        if (props.formwizFieldsData.data.widget_flag_auto_save && !data.submit_button) {
            finalData['widget_flag_submit'] = false
        }

    }

    var faviconId = props.formwizFieldsData.data.widget_favicon_id ? props.formwizFieldsData.data.widget_favicon_id : null;
    var logoId = props.formwizFieldsData.data.widget_logo_id ? props.formwizFieldsData.data.widget_logo_id : null;

    return (
        <>
            {/* {error.flagShowError && (
                <GlobalSnackbar
                    type="error"
                    message={
                        error.message == undefined
                            ? "Something went wrong, Please try again!"
                            : error.message
                    }
                    open={error.flagShowError}
                    handleClose={() => {
                        setError({ ...error, flagShowError: false });
                    }}
                />
            )} */}
            {loaderFlag ? <RoundLoader /> : ""}
            {showForm ?
                <>
                    <Grid item>
                        <Grid container direction="column" justifyContent={"start"} alignItems={"start"}>
                            <Grid item className={globalStyles.formWrapper} style={{ width: "100%", background: props.formwizFieldsData.data.widget_preview_color, borderRadius: "0px 0px 18px 18px" }}>
                                <GlobalForm
                                    fields={props.formwizFieldsData.data.global_form_field}
                                    submitFunction={formSubmitFunction}
                                    checkFormSubmit={renderValue}
                                    handleCloseSnackBar={handleCloseSnackBar}
                                    pageThemeData={props.formwizFieldsData.data.doc_page_theme[0]}
                                    formData={props.formwizFieldsData.data}
                                    submissionID={submissionID}
                                    setFieldValue={setFieldValue}
                                    // getAIsuggestionList={(e) => { getAIsuggestionList(e) }}
                                    formEditable={true}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </>
                : ""}
        </>
    );
}
export default TemplateFormPreview;