import * as React from "react";
import { Button, Grid, FormControl, InputLabel, MenuItem, Select, TextField, TextareaAutosize } from '@mui/material';
import Required from "../../components/required";
import { useNavigate } from "react-router-dom";
import globalStyles from "../../assets/styles/global.module.scss";
import GlobalButton from "../../components/button";
const PromptPropertyForm = (props) => {
    const navigate = useNavigate();
    const handleCreatePrompt = async () => {
        console.log("response of create prompt call");
    }
    console.log("props.promptPropertyForm", props.promptPropertyForm)
    return (
        <>
            <Grid container direction={"column"} sx={{ padding: "50px" }}>

                {/* prompt property name */}
                <Grid item >
                    <InputLabel className={globalStyles.labelStyle}>Prompt Property Name<Required /></InputLabel>
                    <TextField
                        className={globalStyles.globalTextfieldStyle}
                        fullWidth
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={props.promptPropertyForm.prompt_property_name}
                        onChange={(e) => { props.setPromptPropertyForm({ ...props.promptPropertyForm, prompt_property_name: e.target.value }) }}
                    />
                </Grid>

                {/* prompt property flag context */}
                <Grid item >
                    <InputLabel className={globalStyles.labelStyle}>Prompt Property Flag Context <Required /></InputLabel>
                    <Select
                        className={globalStyles.globalTextfieldStyle}
                        sx={{ width: "100%" }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={props.promptPropertyForm.prompt_property_flag_context
                        }
                        disabled={props.buttonName == "Update Property" ? true : false}
                        onChange={(e) => { props.setPromptPropertyForm({ ...props.promptPropertyForm, prompt_property_flag_context: e.target.value }) }}
                    >
                        <MenuItem value={true}>True</MenuItem>
                        <MenuItem value={false}>False</MenuItem>
                    </Select>
                </Grid>

                <Grid item >
                    <InputLabel className={globalStyles.labelStyle}>Prompt Property Flag Is Required <Required /></InputLabel>
                    <Select
                        className={globalStyles.globalTextfieldStyle}
                        sx={{ width: "100%" }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={props.promptPropertyForm.prompt_property_flag_is_required
                        }
                        onChange={(e) => { props.setPromptPropertyForm({ ...props.promptPropertyForm, prompt_property_flag_is_required: e.target.value }) }}
                    >
                        <MenuItem value={true}>True</MenuItem>
                        <MenuItem value={false}>False</MenuItem>
                    </Select>
                </Grid>


                {/* create prompt button */}
                <Grid item >
                    <GlobalButton className="primaryButtonStyle" buttonText={props.buttonName} onClick={props.handleCreatePromptProperty} />
                </Grid>

            </Grid>
        </>
    )
}

export default PromptPropertyForm;