import React, { useState } from "react";
import agent from "../api/api";

const PromptContext = React.createContext();

const PromptProvider = ({ children }) => {
    const [outputFormatGenericData, setOutputFormatGenericData] = useState([])
    const [modelGenericList, setModelGenericList] = useState([])

    const GetOutputGenericData = async () => {
        const response = await agent.getGenericOutputFormatData.get();
        if (response.status) {
            setOutputFormatGenericData(response.data)
        } else {
            setOutputFormatGenericData([])
        }
        return response;
    };
    const GetModelGenericList = async () => {
        const response = await agent.getGenericModelList.get();
        if (response.status) {
            let temp = response.data.map((model) => ({ ...model, checked: false, strict_model_flag: false }))
            setModelGenericList(temp)
        } else {
            setModelGenericList([])
        }
        return response;
    }

    const payload = {
        GetOutputGenericData,
        setOutputFormatGenericData,
        outputFormatGenericData,
        GetModelGenericList,
        modelGenericList,
        setModelGenericList
    };

    return (
        <PromptContext.Provider value={payload}>{children}</PromptContext.Provider>
    );
};

export { PromptContext, PromptProvider };
