import * as React from 'react';
import GlobalDataGrid from "../components/globalDataGrid";
import styles from "../assets/styles/customizableDataGrid.module.scss"

const initialColumns = [
    { field: 'id', headerName: '#', hide: true, width: 60 }, // Hidden ID column for selection
    { field: 'timestamp', headerName: 'Timestamp', width: 180 },
    { field: 'modelName', headerName: 'Model Name', width: 120 },
    { field: 'promptName', headerName: 'Prompt Name', width: 200 },
    { field: 'userFirstname', headerName: 'User Firstname', width: 130 },
    { field: 'userEmail', headerName: 'User Emailaddress', width: 220 },
    { field: 'documentName', headerName: 'Document Name', width: 200 },
    { field: 'systemVariable', headerName: 'System Variable', width: 250 },
    { field: 'userVariable', headerName: 'User Variable', width: 250 },
    { field: 'chatGptResponse', headerName: 'ChatGPT Response', width: 300 },
];

const DataGridComponent = ({ data, refresh, title, filteredRows }) => {
    const [showDetails, setShowDetails] = React.useState(false);
    // Get all keys from the first item in the data array
    const topLevelKeys = data !== null && Object.keys(data[0]);

    // Create columns dynamically based on topLevelKeys
    const dynamicColumns = topLevelKeys.length > 0 && topLevelKeys
        .filter(key => key !== 'log_data' && key !== 'prompt_name' && key !== 'document_name' && key !== 'model_name' && key !== 'user_name' && key !== 'user_email' && key !== 'created_at_timestamp') // Exclude "log_data" column
        .map(key => ({
            field: key,
            headerName: key,
            width: 175,
        }));

    const columns = showDetails ? [...initialColumns, ...dynamicColumns] : initialColumns;

    const rows = data !== null && data.map((item, index) => ({
        id: index,
        timestamp: new Date(item.created_at_timestamp).toLocaleString(),
        modelName: item.model_name,
        promptName: item.prompt_name,
        userFirstname: item.user_name,
        userEmail: item.user_email,
        documentName: item.document_name ? item.document_name : "-",
        systemVariable: item.prompt_system_variable.replace(/\n/g, '\n '),
        userVariable: item.prompt_user_variable.replace(/\n/g, '\n '),
        chatGptResponse: item.log_data.length > 0 ? item.log_data.map((prompt) => prompt.prompt_log_data_response_value) : null,

        ...(showDetails ?
            Object.entries(item).reduce((acc, [key, value]) => ({ ...acc, [key]: !value ? "-" : typeof value === 'string' ? value.replace(/\n/g, '\n ') : value }), {})
            : {}),
    }));

    const filterRows = filteredRows !== null && filteredRows.map((item, index) => ({
        id: index,
        timestamp: new Date(item.created_at_timestamp).toLocaleString(),
        modelName: item.model_name,
        promptName: item.prompt_name,
        userFirstname: item.user_name,
        userEmail: item.user_email,
        documentName: item.document_name ? item.document_name : "-",
        systemVariable: item.prompt_system_variable.replace(/\n/g, '\n '),
        userVariable: item.prompt_user_variable.replace(/\n/g, '\n '),
        chatGptResponse: item.log_data.length > 0 ? item.log_data.map((prompt) => prompt.prompt_log_data_response_value) : null,

        ...(showDetails ?
            Object.entries(item).reduce((acc, [key, value]) => ({ ...acc, [key]: !value ? "-" : typeof value === 'string' ? value.replace(/\n/g, '\n ') : value }), {})
            : {}),
    }));

    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const now = new Date();
    const offsetMinutes = now.getTimezoneOffset();
    const offsetHours = Math.floor(Math.abs(offsetMinutes) / 60);
    const offsetMinutesRemainder = Math.abs(offsetMinutes) % 60;
    const offsetSign = offsetMinutes < 0 ? '+' : '-';
    const offsetFormatted = `GMT${offsetSign}${offsetHours}:${offsetMinutesRemainder}`;

    const Header = () => {
        return (
            <label className={styles.recentPromptsLabel}>
                <div className={styles.recentPromptsTitle}>
                    {title ? title : 'Recent Prompts'}
                </div>

                <div>
                    <input type="checkbox" className={styles.showDetailsCheckbox} disabled={data == null} checked={showDetails} onChange={() => setShowDetails(!showDetails)} />
                    Show Details
                </div>

                <div>
                    {timezone && (
                        <div className={styles.timezoneFormat}>
                            {`${timezone} (${offsetFormatted})`}
                        </div>
                    )}
                </div>

            </label>
        );
    }
    return (
        <div className={styles.globalDataGridComponent}>
            <GlobalDataGrid rows={data == null ? [] : rows} filterRows={filterRows} Header={Header} columns={columns} hideFooter={false} hideFooterPagination={false} hideFooterSelectedRowCount={true} showSelectedRowDetails={true} refresh={refresh} promptsPage={true} />
        </div>
    );
};

export default DataGridComponent;
