import * as React from "react";
import { Grid, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import agent from "../api/api";
import { UserContext } from "../context/userContext";
import Required from "../components/required";
import GlobalButton from "../components/button";
import globalStyles from "../assets/styles/global.module.scss";
import CloseIcon from '@mui/icons-material/Close';
import { checkForErrorCode, getErrorMessage } from "../utils/constants";

const UpdatePrompt = (props) => {
    const { setSuccess, success, error, setError } = React.useContext(UserContext);
    const [templateList, setTemplateList] = React.useState([])
    const handleUpdatePrompt = async () => {

        const response = await agent.UpdatePrompt.put(props.updatePromptFormData)
        if (response.status) {
            setSuccess({
                ...success,
                flagShowSuccess: response.status,
                message: response.data.message,
            });
        } else {
            let errorCode = checkForErrorCode(response)
            setError({ ...error, flagShowError: true, message: getErrorMessage(errorCode) })
        }
    }

    React.useEffect(() => {
        (async () => {
            const response = await agent.GetTemplateList.get();
            let temp = response.data[0].category_list
                .filter((category) => category.category_name !== "Custom")
                .flatMap((category) => category.template_data_list);
            setTemplateList(temp);
        })()
    }, [])

    return (
        <>
            <Grid container direction={"column"} className={globalStyles.globalContainer}>
                {/* Template id */}
                <CloseIcon className={globalStyles.closeIcon} onClick={() => { props.setShowPrompt(false) }} />
                <Grid item>
                    <InputLabel className={globalStyles.labelStyle}>Select Template<Required /></InputLabel>
                    <FormControl fullWidth>
                        <Select
                            className={globalStyles.globalTextfieldStyle}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={props.updatePromptFormData.template_id === null ? "None" : props.updatePromptFormData.template_id}
                            onChange={(e) => { props.setUpdatePromptFormData({ ...props.updatePromptFormData, template_id: e.target.value === "None" ? null : e.target.value }) }}
                        >
                            {templateList.map((template) => (
                                <MenuItem value={template.template_id}>{template.template_name}</MenuItem>
                            ))
                            }
                            <MenuItem value={"None"}>None</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>

                {/* prompt name */}
                <Grid item>
                    <InputLabel className={globalStyles.labelStyle}>Prompt Name<Required /></InputLabel>
                    <TextField
                        className={globalStyles.globalTextfieldStyle}
                        fullWidth
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={props.updatePromptFormData.prompt_name || ''}
                        onChange={(e) => { props.setUpdatePromptFormData({ ...props.updatePromptFormData, prompt_name: e.target.value }) }}
                    />
                </Grid>
                {/* prompt generic name */}
                <Grid item>
                    <InputLabel className={globalStyles.labelStyle}>Prompt Generic Name (it should not have space, use underscore instead)<Required /></InputLabel>
                    <TextField
                        className={globalStyles.globalTextfieldStyle}

                        fullWidth
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={props.updatePromptFormData.prompt_generic_name}
                        onChange={(e) => { props.setUpdatePromptFormData({ ...props.updatePromptFormData, prompt_generic_name: e.target.value }) }}
                    />
                </Grid>

                {/* Max Tokens */}
                <Grid item >
                    <InputLabel className={globalStyles.labelStyle}>Max Tokens (Upper Limit is 3000)<Required /></InputLabel>
                    <TextField
                        fullWidth
                        className={globalStyles.globalTextfieldStyle}

                        type="number"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={props.updatePromptFormData.max_tokens}
                        onChange={(e) => { props.setUpdatePromptFormData({ ...props.updatePromptFormData, max_tokens: e.target.value }) }}
                    />
                </Grid>

                {/* credits required */}
                <Grid item>
                    <InputLabel className={globalStyles.labelStyle}>Credits Required<Required /></InputLabel>
                    <TextField
                        type="number"
                        className={globalStyles.globalTextfieldStyle}
                        fullWidth
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={props.updatePromptFormData.credits_required}
                        onChange={(e) => { props.setUpdatePromptFormData({ ...props.updatePromptFormData, credits_required: e.target.value }) }}
                    />
                </Grid>

                {/* Prompt Type Name */}
                <Grid item >
                    <InputLabel className={globalStyles.labelStyle}>Prompt Type Name <Required /></InputLabel>
                    <Select
                        className={globalStyles.globalTextfieldStyle}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={props.updatePromptFormData.prompt_type_name
                        }
                        onChange={(e) => { props.setUpdatePromptFormData({ ...props.updatePromptFormData, prompt_type_name: e.target.value }) }}
                    >
                        <MenuItem value={"primary"}>Primary</MenuItem>
                        <MenuItem value={"user-defined"}>User Defined</MenuItem>
                    </Select>
                </Grid>
                <Grid item >
                    <GlobalButton buttonText="Update prompt" onClick={handleUpdatePrompt} className="primaryButtonStyle" />
                </Grid>
                
            </Grid>

        </>
    )
}

export default UpdatePrompt;