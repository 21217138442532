import * as React from "react";
import { Grid, IconButton, InputLabel, LinearProgress, MenuItem, Select, Typography, } from '@mui/material';
import { useSearchParams } from "react-router-dom";
import agent from "../../api/api";
import GlobalButton from "../../components/button";
import axios from "axios";
import GlobalForm from "../../utils/formwiz/globalForm";
import GlobalDataGrid from "../../components/globalDataGrid";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import CloseIcon from '@mui/icons-material/Close';
import globalStyles from "../../assets/styles/global.module.scss";
const AddImageToGoogleStorage = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [lastUpdatedData, setLastUpdatedData] = React.useState([])
    const [fileUploaded, setFileUploaded] = React.useState(false)
    const [fileSelected, setFileSelected] = React.useState(null)
    const [progressBar, setProgressBar] = React.useState(false)
    const [uploadedFile, setUploadedFile] = React.useState([]);
    const [fileFlag, setFileFlag] = React.useState('general_flag')
    const [privateFlag, setPrivateFlag] = React.useState(false)
    const handlePNGFilesUploading = async (e) => {
        // debugger;
        const file = e.target.files[0]
        setUploadedFile(file)
        if (!file) return;

        // if (file.type !== "image/png") {
        //     UpdateSnackbarMessage({
        //         status: "error",
        //         message: "Only PNG files supported",
        //     })
        //     return
        // }

        // const fileSize = file.size / (1024 * 1024)
        // if (fileSize > 2) {
        //     UpdateSnackbarMessage({
        //         status: "error",
        //         message: "File size exceeds 2 MB",
        //     });
        //     return;
        // }

        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        formData.append("AGENCY_LOGO_PATH", true);

        // const response = await agent.uploadFileToGoogleStorage.post(formData)
        // if (response.status) {
        //     setProgressBar(false)
        //     setFileUploaded(true)
        //     setFileSelected(file);
        //     response.data.id = 0
        //     setLastUpdatedData([response.data])
        // } else {
        //     console.log("err",)
        // }



        setProgressBar(true)
        setProgressBar(false)
        setFileUploaded(true)
    }
    const uploadFile = async () => {
        // debugger;
        const formData = new FormData();
        formData.append("file", uploadedFile);
        formData.append(fileFlag, true);
        formData.append("private_file_flag", privateFlag)
        try {
            setProgressBar(true);
            const response = await axios.post('api/storage/file', formData); // Use your actual upload URL
            if (response.status === 200) {
                // Handle successful upload
                console.log('File uploaded successfully', response.data);
                if (response.status) {
                    response.data.data.id = 1
                    setLastUpdatedData([response.data.data])
                } else {
                    setLastUpdatedData([])
                }
                setFileUploaded(true);
            } else {
                // Handle server error
                console.error('Upload failed', response);
            }
        } catch (error) {
            console.error('Error uploading file', error);
        } finally {
            setProgressBar(false);
        }

    }
    const columns = [
        { field: 'google_storage_id', headerName: 'Google Storage ID', width: 150 }, // Hidden ID column for selection
        { field: 'original_file_name', headerName: 'Original File Name', width: 125 },
        { field: 'file_name', headerName: 'File Name', width: 125 },
    ]
    const handleFileDelete = () => {
        setUploadedFile([]);
        setFileUploaded(false)
    }
    return (
        <>

            <Grid container direction="column" gap={"15px"} justifyContent={"space-between"} sx={{ flexWrap: "nowrap" }}>
                <Grid item >
                    <Grid container direction={"column"} justifyContent={"space-between"} sx={{ flexWrap: "nowrap" }}>
                        {/* image details table */}
                        <Grid item className="ag-details-file-card ">
                            <Grid container>
                                <Grid item className="ag-details-file-inputs">
                                    {!fileUploaded ?
                                        <>
                                            <input
                                                type="file"
                                                multiple
                                                className="ag-details-file-inputs"
                                                accept="image/png"
                                                onChange={(e) => handlePNGFilesUploading(e)}
                                            ></input>
                                        </>
                                        :
                                        <Grid container sx={{ alignItems: "center" }}>

                                            <Grid item sx={{ flex: 1, overflow: "hidden" }}>
                                                <Grid container className='ag-details-uploadedfile-conatiner'>

                                                    <Grid item>
                                                        {uploadedFile.name}
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item >
                                                <Grid container sx={{ justifyContent: "center", alignItems: "center" }}>
                                                    <IconButton
                                                        onClick={() => {
                                                            handleFileDelete("logo")
                                                        }}>
                                                        <CloseIcon />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>

                                        </Grid>
                                    }
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                {progressBar && <LinearProgress />}
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Select
                                className={globalStyles.globalTextfieldStyle}
                                sx={{ width: "100%" }}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="template_id"
                                value={fileFlag}
                                onChange={(e) => setFileFlag(e.target.value)}
                            >
                                <MenuItem value={'persona_flag'}>Persona</MenuItem>
                                <MenuItem value={'extention_flag'}>Extension</MenuItem>
                                <MenuItem value={'template_flag'}>Template</MenuItem>
                                <MenuItem value={'banner_flag'}>Banner</MenuItem>
                                <MenuItem value={'category_flag'}>Category</MenuItem>
                                <MenuItem value={'general_flag'}>General</MenuItem>
                                <MenuItem value={'email_image_flag'}>Email Template</MenuItem>
                                <MenuItem value={'chatbot_flag'}>Chatbot</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item>
                            <Grid container direction={"column"}>
                                <Grid item>
                                    <InputLabel>Private</InputLabel>
                                </Grid>
                                <Grid item>
                                    <Select
                                        className={globalStyles.globalTextfieldStyle}
                                        sx={{ width: "100%" }}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="template_id"
                                        value={privateFlag}
                                        onChange={(e) => setPrivateFlag(e.target.value)}
                                    >
                                        <MenuItem value={true}>True</MenuItem>
                                        <MenuItem value={false}>False</MenuItem>
                                    </Select>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>
                <Grid item><GlobalButton buttonText="Add Image" className="primaryButtonStyle" onClick={uploadFile} /></Grid>
                <Grid item>
                    Last Added Image Details:
                    <Grid container>
                        <GlobalDataGrid rows={lastUpdatedData} columns={columns} hideFooter={true} hideFooterPagination={true} hideFooterSelectedRowCount={true} showSelectedRowDetails={true} refresh={() => { }} />
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default AddImageToGoogleStorage;