import * as React from "react";
import { Fragment, useContext } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import PromptLogs from "../pages/promptLogs";
import Login from "../pages/login";
import { UserContext } from "../context/userContext";
import CreatePrompt from "../pages/createPromptForm";
import PromptList from "../pages/promptList";
import RoundLoader from "../components/roundLoader";
import DevDashboard from "../pages/devDashboard";
import Template from "../pages/template";
import CustomTemplate from "../pages/customTemplate";
import GlobalSnackbar from "../components/globalSnackbar";
import ExistingCustomers from "../pages/existingCustomers";
import AccessDeniedPage from "../pages/AccessDenied/accessDenied";
import User from "../pages/User/User";
import PromptDetails from "../pages/promptDetails/promptDetails";
import LoaderDetails from "../pages/LoaderDetails/loaderDetails";
import { GenericDataContext } from "../context/GenericData";
import PromptLogsV2 from "../pages/promptLogsV2";
import { GetCurrentActiveHeaderName } from "../utils/constants";


const GlobalRoutes = () => {
    const { userDetail, UserMe, flagLoading, error, setError, success, setSuccess, setHeaderTitle, currentActiveMenu } = useContext(UserContext)
    const { getLoaderUserTypeDataList, getModuleDataList, getImageGroupData } = useContext(GenericDataContext)
    React.useEffect(() => {
        UserMe();
        getLoaderUserTypeDataList();
        getModuleDataList();
        getImageGroupData();
    }, [])
    React.useEffect(()=>{
        let activeHeaderName = GetCurrentActiveHeaderName(window.location.pathname)
        setHeaderTitle(activeHeaderName)
    },[currentActiveMenu])
    return (
        <Fragment>
            {flagLoading && <RoundLoader />}
            {error.flagShowError && (
                <GlobalSnackbar
                    type="error"
                    message={
                        error.message == undefined
                            ? "Something went wrong, Please try again!"
                            : error.message
                    }
                    open={error.flagShowError}
                    handleClose={() => {
                        setError({ ...error, flagShowError: false });
                    }}
                />
            )}
            {success.flagShowSuccess && (
                <GlobalSnackbar
                    type="success"
                    message={
                        success.message == undefined
                            ? "Success"
                            : success.message
                    }
                    open={success.flagShowSuccess}
                    handleClose={() => {
                        setSuccess({ ...success, flagShowSuccess: false });
                    }}
                />
            )}
            <Routes>
                {(userDetail.flagUser) ?
                    <>
                        {!(userDetail.user_type == "Public") && (
                            userDetail.user_role.user_role_name == "Developer" ||
                            userDetail.user_role.user_role_name == "Super Admin" ||
                            userDetail.user_role.user_role_name == "Admin") ?
                            <>
                                <Route path="/dashboard" element={<DevDashboard />}>
                                    <Route index
                                        path="/dashboard"
                                        element={<Navigate to="/dashboard/existingCustomers" />} />
                                    <Route index
                                        path="/dashboard/existingCustomers"
                                        element={<ExistingCustomers />} />
                                    <Route path="/dashboard/promptLogs" element={<PromptLogs />} />
                                    <Route path="/dashboard/templates" element={<Template />} />
                                    <Route path="/dashboard/prompts" element={<PromptList />} />
                                    <Route path="/dashboard/prompts/createPrompt" element={<CreatePrompt />} />
                                    <Route path="/dashboard/prompts/promptDetails" element={<PromptDetails />} />
                                    <Route path="/dashboard/loaderDetails" element={<LoaderDetails />} />
                                    <Route path="/dashboard/templates/createTemplate" element={<CustomTemplate />} />
                                    <Route path="/dashboard/existing-customers" element={<ExistingCustomers />} />
                                    <Route path="/dashboard/promptLogsV2" element={<PromptLogsV2 />} />
                                    <Route path="/dashboard/user" element={<User />} />
                                </Route>
                                <Route path="*" element={<Navigate to="/dashboard/existingCustomers" />} />
                            </>
                            :
                            <>
                                <Route path="*" element={<AccessDeniedPage />} />
                            </>
                        }

                    </>
                    :
                    <Route path="/" element={<Login />} />}
            </Routes>
        </Fragment>
    );
};

export default GlobalRoutes;
