import * as React from "react";
import { Avatar, Button, CssBaseline, TextField, Paper, Box, Grid, Typography, InputLabel, IconButton, Divider } from "@mui/material";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useState, useContext, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import OtpInput from "react18-input-otp";
import { UserContext } from "../context/userContext";
import styles from "../assets/styles/login.module.scss";
import writewizWhiteLogo from "../assets/whiteWELogo.svg";
import updated from "../assets/updated.svg";
import back from "../assets/loginBackArrow.svg";
import edit from "../assets/edit.png";
import globalStyles from '../assets/styles/global.module.scss'
import googleIcon from '../assets/google.svg';
import agent from "../api/api";

const theme = createTheme();
export default function Login() {
    const SiteKey = "6Ld0tIgnAAAAAM6flWQfozA41EW4o13PRW78EILx";
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    const { SendLoginOTP, Login, setUserDetail, setFlagLoading, error, setError } = useContext(UserContext)
    const [user, setUser] = useState({
        email: "",
        google_recaptcha_token: "",
    });

    const emptyErrorObj = {
        flagShowError: false,
        message: "",
    };
    const emptySuccessObj = {
        flagShowSuccess: false,
        message: "",
    };

    const [otp, setOtp] = useState("");
    const [flagInvalidOtp, setInvalidOtp] = useState(false);
    const [flagEmailValid, setFlagEmailValid] = useState(true);
    const [flagShowVerificationBlock, setFlagShowVerificationBlock] = useState(false);
    const [flagTimerActive, setFlagTimerActive] = useState(false);
    const [seconds, setSeconds] = useState(120);
    const [success, setSuccess] = useState(emptySuccessObj);
    const recaptchaRef = React.useRef(null);
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    useEffect(() => {
        let timerId
        if (flagTimerActive && seconds > 0) {
            timerId = setInterval(() => {
                setSeconds(seconds - 1);
            }, 1000);
        }
        if (seconds === 0) {
            setFlagTimerActive(false);
        }
        return () => clearInterval(timerId);
    }, [flagTimerActive, seconds]);

    const handleSendOtp = async () => {
        setFlagLoading(true);
        const payload = {
            user_email: user.email,
            google_recaptcha_token: user.google_recaptcha_token,
        };
        const response = await SendLoginOTP(payload);
        setFlagLoading(false);
        if (response.status) {
            if (response.data) {
                setSuccess({
                    ...success,
                    flagShowSuccess: response.status,
                    message: response.data.message,
                });
                setInvalidOtp(false);
                setOtp("");
                handleResetTimer();
                handleStartTimer();
                setFlagShowVerificationBlock(true);
            }
        } else {
            recaptchaRef.current?.reset();
            setError({
                ...error,
                flagShowError: true,
                message: response?.error?.message,
            });
            setUser({ ...user, google_recaptcha_token: "" })
        }
    };
    const handleLogin = async () => {
        setFlagLoading(true)
        const payload = {
            user_email: user.email,
            authentication_code: otp,
        };
        const response = await Login(payload);
        setFlagLoading(false)
        if (response.status) {
            if (response.data) {
                setUserDetail({ ...response.data[0], flagUser: response.status });
                console.log("setting userdetail in chrome storage", response.data[0])

                let tempUserDetail = {
                    flaguserAnonymous: response.data[0].flag_user_anonymous,
                    userfirstName: response.data[0].user_first_name,
                    userLastName: response.data[0].user_last_name,
                    userFullName: ` ${response.data[0].user_first_name} ${response.data[0].user_last_name}`,
                    userStatus: response.status
                }
                window.postMessage({ type: "SET_WRITEWIZ_LOGIN_DETAILS", data: tempUserDetail }, "*");
                localStorage.setItem('writeWizUserDetail', JSON.stringify(tempUserDetail));
            }
        } else {
            setError({
                ...error,
                flagShowError: true,
                message: response?.error?.message,
            });
            recaptchaRef.current?.reset();
            setUser({ ...user, google_recaptcha_token: "" })
            setInvalidOtp(true);
        }
    };

    const handleStartTimer = () => setFlagTimerActive(true);


    const handleResetTimer = () => {
        setFlagTimerActive(false);
        setSeconds(120);
    };
    const handleFlagOtp = () => {
        setFlagShowVerificationBlock(!flagShowVerificationBlock);
        setOtp("");
    };

    const contWithGoogle = async () => {
        setFlagLoading(true);;
        let currentUrl
        const searchParams = new URLSearchParams(window.location.search); 
        let redirect = searchParams.get("redirect")
        if (redirect) {
          currentUrl = decodeURIComponent(redirect);
        } else {
          currentUrl = window.location.href.concat("dashboard/existingCustomers")
        }
        const response = await agent.contWithGoogle.get(currentUrl, 1);
        if (response.status) {
          if (response.data) {
            let url = response.data
            window.location.href = url;
          }
        } else {
          recaptchaRef.current?.reset();
          setError({
            ...error,
            flagShowError: true,
            message: "Something went wrong! Please try again later.",
          });
        }
        setTimeout(() => {
          setFlagLoading(false);
        }, 500)
    };
    const LogoComponent = () => (
        <img src={edit} alt="Logo" width="17px" height="17px" />
    );
    
    const handleKeyDown = (event) => {
        if(event.keyCode === 13 && otp.length === 6){
            handleLogin();
        }
    }
    useEffect(() => {
        document.addEventListener("keydown", handleKeyDown);
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [otp]);
    
    return (

        <ThemeProvider theme={theme}>
            <Grid container component="main" sx={{ height: "100vh" }}>
                
                <CssBaseline />
                
                <Grid item className={styles.rightBlockWrapper} xs={false} sm={false} md={6}>
                    <Grid container className={styles.rightBlock} justifyContent={"center"} alignItems="center" direction="column" gap={1}>
                        <Grid item>
                            <img src={writewizWhiteLogo} />
                        </Grid>
                        <Grid item>
                            {/* pic */}
                            <img src={updated} style={{ margin: "50px 0px" }} />
                        </Grid>
                        <Grid item className={styles.italicText}>
                            “AI Content Generator, Writing Assistant, & Chatbot”
                        </Grid>
                    </Grid>
                </Grid>


                <Grid item xs={12} sm={12} md={6} component={Paper} elevation={6} square>
                    
                    <Grid item className={styles.leftBlockWrapper} >
                        <Grid container direction="column" className={styles.leftBlock}>
                            <Grid item>
                                <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                                    <VpnKeyIcon />
                                </Avatar>
                            </Grid>
                            <Grid item className={styles.authLabel}>Sign In - Writewiz Dev-Portal</Grid>
                            
                            {!flagShowVerificationBlock && 
                            <Grid item mt={1} sx={{width: "100%"}}>
                                <Button onClick={contWithGoogle} className={globalStyles.continueWithGoogleButton}>
                                    <img src={googleIcon} />
                                    <div style={{ paddingLeft: "26px" }}>
                                        Continue with Google
                                    </div>
                                </Button>
                                <Divider className={`${globalStyles.fullwidth} ${globalStyles.dividerStyle}`}>OR</Divider>
                            </Grid>}
                        
                        <Box sx={{width: '100%'}}>
                            {flagShowVerificationBlock == false ?
                                (<>
                                    <Grid container direction={"column"}>
                                        {/* user email textfield */}
                                        <Grid item>
                                            <TextField
                                                className={styles.emailTextfield}
                                                margin="normal"
                                                required
                                                fullWidth
                                                id="email"
                                                placeholder="Email Address"
                                                name="email"
                                                autoComplete="email"
                                                autoFocus
                                                value={user.email}
                                                onChange={(e) => {
                                                    setUser({ ...user, email: e.target.value });
                                                    setError(emptyErrorObj);
                                                    setFlagEmailValid(true)
                                                }}
                                                onBlur={() => {
                                                    if (user.email.length > 0) {
                                                        setFlagEmailValid(emailRegex.test(user.email));
                                                    } else {
                                                        setFlagEmailValid(true); 
                                                    }
                                                }}
                                                error={!flagEmailValid} 
                                                onKeyDown={(e) => {handleKeyDown(e)}}
                                                helperText={
                                                    !flagEmailValid && "Please enter a valid Email Address"
                                                }
                                            />
                                        </Grid>
                                        {/* recaptcha block */}
                                        <Grid item sx={{ marginTop: "20px" }} className={styles.recaptchaBlock}>
                                            <ReCAPTCHA
                                                className={styles.mainRecaptchaBlock}
                                                ref={recaptchaRef}
                                                sitekey={SiteKey}
                                                onChange={(value) => {
                                                    setUser({ ...user, google_recaptcha_token: value });
                                                }}
                                            />
                                        </Grid>
                                        {/* cont button */}
                                        <Grid item sx={{ marginTop: "20px" }} className={styles.authButtonStyleDisable}>
                                            <Button
                                                className={styles.authButtonStyle}
                                                disabled={
                                                    user.email !== "" && user.google_recaptcha_token !== "" && flagEmailValid
                                                        ? false
                                                        : true
                                                }
                                                onClick={() => {
                                                    console.log("call login");
                                                    handleSendOtp();
                                                }}
                                            >Continue</Button>
                                        </Grid>
                                    </Grid>
                                </>)
                                :
                                (
                                    <>
                                        <Grid item sx={{ marginBlock: "30px" }}>
                                            <Grid container className={styles.clickable} onClick={() => { setFlagShowVerificationBlock(false) }} alignItems={"center"} sx={{ position: "relative" }}>
                                                <Grid item sx={{ position: "absolute", top: "10%", left: 0 }}><img src={back} /></Grid>
                                                <Grid item><Typography sx={{ fontSize: "16px", fontWeight: "600", color: "#6A097D", marginLeft: "25px" }}>Back</Typography></Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            container
                                            sx={{ justifyContent: "space-between" }}
                                            direction={"column"}
                                            style={{marginBlock: "15px"}}
                                        >
                                            <Grid item>
                                                <InputLabel className={styles.inputLabel} >Email</InputLabel>
                                            </Grid>
                                            <Grid item>
                                                <TextField
                                                    className={styles.fullWidth}
                                                    value={user.email}
                                                    sx={{ '& .MuiInputBase-root': { paddingRight: "0px !important" } }}
                                                    onChange={() => { setFlagShowVerificationBlock(!flagShowVerificationBlock) }}
                                                    disabled={flagShowVerificationBlock}
                                                    InputProps={{
                                                        endAdornment: flagShowVerificationBlock && (
                                                          <IconButton className={styles.editIconStyle} onClick={handleFlagOtp}>
                                                            {flagShowVerificationBlock ? (
                                                              <LogoComponent />
                                                            ) : (
                                                              ""
                                                            )}
                                                          </IconButton>
                                                        ),
                                                      }}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item sx={{ width: "100%" }}>
                                            <Grid
                                                container
                                                sx={{ flexWrap: "nowrap" }}
                                                justifyContent={"center"}
                                                alignContent={"center"}
                                                direction={"column"}
                                            >
                                                <Grid item className={styles.fullwidth} sx={{ fontSize: "16px", fontWeight: "600", color: "#4C4A4D", margin: "15px 0px !important", display: "flex", flexDirection: "column" }}>
                                                    Verification Code
                                                    <span style={{ fontSize: "14px", color: "#7A7A7A", fontWeight: "500", marginTop: "3px" }}>Sent to {user.email}</span>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid container direction="column" sx={{ width: "100%" }}>
                                            {/* otp block */}
                                            <Grid item sx={{ marginBottom: "40px"}}>

                                                <OtpInput
                                                    // {...field}
                                                    value={otp}
                                                    // id="otp"
                                                    onChange={(value) => {
                                                        console.log("onSubmit  vaaa: ", value);
                                                        setOtp(value);
                                                        setInvalidOtp(false);
                                                    }}
                                                    isInputNum={true}
                                                    shouldAutoFocus={true}
                                                    numInputs={6}
                                                    inputStyle={
                                                        flagInvalidOtp ? styles.otpInputError : styles.inputOtpStyle
                                                    }
                                                />
                                            </Grid>

                                            <Grid item>
                                                <Grid container direction="column">
                                                    {/* login button */}
                                                    <Grid item className={styles.authButtonStyleDisable}>
                                                        <Button
                                                            // buttonText="Login"
                                                            disabled={otp.length < 6 ? true : false}
                                                            className={styles.authButtonStyle}
                                                            onClick={() => {
                                                                console.log("call login");
                                                                handleLogin();
                                                            }}
                                                        >Login
                                                        </Button>
                                                    </Grid>

                                                    {/* timer or resend button */}
                                                    <Grid item sx={{ marginTop: "20px" }}>
                                                        <>
                                                            {(seconds > 0) || minutes > 0 ? (
                                                                // timer
                                                                <>
                                                                    <a
                                                                        style={{
                                                                            fontSize: "16px",
                                                                            color: "#4C4A4D",
                                                                            fontWeight: "600",
                                                                        }}
                                                                    >
                                                                        {" "}
                                                                        Haven’t received code? Resend Code in{" "}
                                                                    </a>
                                                                    <a
                                                                        style={{
                                                                            fontSize: "15px",
                                                                            color: "rgba(30, 136, 229, 1)",
                                                                            marginRight: "20px",
                                                                        }}
                                                                    >
                                                                        {`${minutes}:${remainingSeconds < 10 ? "0" : ""
                                                                            }${remainingSeconds}`}
                                                                    </a>
                                                                </>
                                                            )
                                                                :
                                                                (
                                                                    <>
                                                                        <a
                                                                            style={{
                                                                                fontSize: "16px",
                                                                                color: "#4C4A4D",
                                                                                fontWeight: "600",
                                                                            }}
                                                                        >
                                                                            {" "}
                                                                            Haven’t received code?<span style={{ color: "#6A097D" }} onClick={handleSendOtp} className={`${styles.clickable} ${styles.resendButtonStyle}`}>Resend</span>
                                                                        </a>
                                                                    </>
                                                                )}
                                                        </>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </>
                                )
                            }
                        </Box>
                    </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </ThemeProvider>
    );
}
