import * as React from "react";
import { Grid, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import agent from "../api/api";
import { UserContext } from "../context/userContext";
import Required from "../components/required";
import { useNavigate } from "react-router-dom";
import globalStyles from "../assets/styles/global.module.scss";
import GlobalButton from "../components/button";
import { checkForErrorCode, getErrorMessage } from "../utils/constants";
const CreatePrompt = () => {
    const navigate = useNavigate();
    const { setSuccess, success, error, setError } = React.useContext(UserContext)
    const [createPromptFormData, setCreatePromptFormData] = React.useState(
        {
            max_tokens: "",
            prompt_name: "",
            template_id: null,
            credits_required: "",
            prompt_type_name: "primary",
            prompt_generic_name: ""
        }
    )
    const [templateList, setTemplateList] = React.useState([])
    React.useEffect(() => {
        (async () => {
            const response = await agent.GetTemplateList.get();
            if (response.status) {
                if(response.data) {
                    let temp = response.data[0].category_list
                        .filter((category) => category.category_name !== "Custom")
                        .flatMap((category) => category.template_data_list);
                    setTemplateList(temp);
                }
            }
            else {
                let errorCode = checkForErrorCode(response);
                setError({ ...error, flagShowError: true, message: getErrorMessage(errorCode) })
            }
        })()
    }, [])
    const handleCreatePrompt = async () => {
        const response = await agent.CreatePrompt.post(createPromptFormData)
        if (response.status) {
            setSuccess({
                ...success,
                flagShowSuccess: response.status,
                message: response.data.message,
            });
            navigate(`/dashboard/prompts/promptDetails?prompt_id=${response.prompt_id}`)
        } else {
            let errorCode = checkForErrorCode(response)
            setError({ ...error, flagShowError: true, message: getErrorMessage(errorCode) })
        }
    }
    return (
        <>
            <Grid container mt={"20px"} direction={"column"} className={`${globalStyles.globalContainer}`}>
                {/* Template id */}
                <Grid item >
                    <InputLabel className={globalStyles.labelStyle} >Select Template<Required /></InputLabel>
                    <FormControl fullWidth>
                        <Select
                            className={globalStyles.globalTextfieldStyle}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={createPromptFormData.template_id === null ? "None" : createPromptFormData.template_id}
                            onChange={(e) => { setCreatePromptFormData({ ...createPromptFormData, template_id: e.target.value === "None" ? null : e.target.value }) }}
                        >
                            {templateList.map((template) => (
                                <MenuItem value={template.template_id}>{template.template_name}</MenuItem>
                            ))
                            }
                            <MenuItem value={"None"}>None</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>

                {/* prompt name */}
                <Grid item >
                    <InputLabel className={globalStyles.labelStyle}>Prompt Name<Required /></InputLabel>
                    <TextField
                        className={globalStyles.globalTextfieldStyle}
                        fullWidth
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={createPromptFormData.prompt_name || ''}
                        onChange={(e) => { setCreatePromptFormData({ ...createPromptFormData, prompt_name: e.target.value }) }}
                    />
                </Grid>
                {/* prompt generic name */}
                <Grid item >
                    <InputLabel className={globalStyles.labelStyle}>Prompt Generic Name (it should not have space, use underscore instead)<Required /></InputLabel>
                    <TextField
                        className={globalStyles.globalTextfieldStyle}
                        fullWidth
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={createPromptFormData.prompt_generic_name}
                        onChange={(e) => { setCreatePromptFormData({ ...createPromptFormData, prompt_generic_name: e.target.value }) }}
                    />
                </Grid>

                {/* Max Tokens */}
                <Grid item >
                    <InputLabel className={globalStyles.labelStyle}>Max Tokens (Upper Limit is 3000)<Required /></InputLabel>
                    <TextField
                        className={globalStyles.globalTextfieldStyle}
                        fullWidth
                        type="number"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={createPromptFormData.max_tokens}
                        onChange={(e) => { setCreatePromptFormData({ ...createPromptFormData, max_tokens: e.target.value }) }}
                    />
                </Grid>

                {/* credits required */}
                <Grid item >
                    <InputLabel className={globalStyles.labelStyle}>Credits Required<Required /></InputLabel>
                    <TextField
                        className={globalStyles.globalTextfieldStyle}
                        type="number"
                        fullWidth
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={createPromptFormData.credits_required}
                        onChange={(e) => { setCreatePromptFormData({ ...createPromptFormData, credits_required: e.target.value }) }}
                    />
                </Grid>

                {/* Prompt Type Name */}
                <Grid item >
                    <InputLabel className={globalStyles.labelStyle}>Prompt Type Name <Required /></InputLabel>
                    <Select
                        className={globalStyles.globalTextfieldStyle}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={createPromptFormData.prompt_type_name
                        }
                        onChange={(e) => { setCreatePromptFormData({ ...createPromptFormData, prompt_type_name: e.target.value }) }}
                    >
                        <MenuItem value={"primary"}>Primary</MenuItem>
                        <MenuItem value={"user-defined"}>User Defined</MenuItem>
                    </Select>
                </Grid>


                {/* create prompt button */}
                <Grid item >
                    <GlobalButton buttonText={"Create prompt"} className="primaryButtonStyle" onClick={handleCreatePrompt} />
                </Grid>

            </Grid>
        </>
    )
}

export default CreatePrompt;