import * as React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Button, ButtonGroup, Checkbox, Grid, IconButton, InputAdornment, InputLabel, Paper, Popover, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, TextField, TextareaAutosize } from '@mui/material';
import CustomDataGridTable from "../components/customizableDataGrid";
import agent from "../api/api";
import RoundLoader from "../components/roundLoader";
import globalStyles from "../assets/styles/global.module.scss";
import UpdateIcon from '@mui/icons-material/Update';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import ClearIcon from '@mui/icons-material/Clear';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useSearchParams } from "react-router-dom";
import Globalbutton from "../components/button"
import CloseIcon from '@mui/icons-material/Close';
import { UserContext } from "../context/userContext";
import promptStyle from './../assets/styles/promptLogsV2.module.scss';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PromptLogsV2Table from "./promptLogsV2Table";
import { FormControl, MenuItem, Select } from "@mui/base";
import { checkError, convertISTtoUTC, convertUTCtoIST } from "../utils/constants";

const PromptLogsV2 = () => {
    const [customerPromptLogDetails, setCustomerPromptLogDetails] = React.useState(null);
    const { setSuccess, success, setError, error, setFlagLoading } = React.useContext(UserContext)
    const [orderBy, setOrderBy] = React.useState("");
    const [order, setOrder] = React.useState("asc");
    const [showRowDetail, setShowRowDetail] = React.useState(false);
    const [totalRowCount, setTotalRowCount] = React.useState("");
    const [isExpanded, setIsExpanded] = React.useState(true);
    const [timeFormat, setTimeFormat] = React.useState("UTC")
    const toggleAccordion = () => {
        setIsExpanded(!isExpanded);
    };

    const [currentRowToShow, setCurrentRowToShow] = React.useState({
        rowData: {},
        rowIndex: null
    });
    // const moment = require('moment-timezone');
    // function convertISTtoUTC(dateTimeStringIST) {
    //     if (dateTimeStringIST !== "") {
    //         // Parse the input date-time as IST
    //         const momentIST = moment.tz(dateTimeStringIST, "YYYY-MM-DD HH:mm:ss", "Asia/Kolkata");

    //         // Convert the moment instance to UTC
    //         const momentUTC = momentIST.utc();

    //         // Format the UTC date-time in the desired format
    //         return momentUTC.format("YYYY-MM-DD HH:mm:ss");
    //     } else {
    //         return ""
    //     }
    // }
    // function convertUTCtoIST(dateTimeStringUTC) {
    //     if (dateTimeStringUTC !== "") {
    //         // Parse the input date-time as UTC
    //         const momentUTC = moment.utc(dateTimeStringUTC, "YYYY-MM-DD HH:mm:ss");

    //         // Convert the moment instance to IST
    //         const momentIST = momentUTC.tz("Asia/Kolkata");

    //         // Format the IST date-time in the desired format
    //         return momentIST.format("YYYY-MM-DD HH:mm:ss");
    //     } else {
    //         return "";
    //     }
    // }
    // function convertISTtoUTC(dateTimeStringIST) {
    //     debugger;
    //     if (dateTimeStringIST !== "") {

    //         // Parse the date string
    //         const dateTimeParts = dateTimeStringIST.split(/[- :]/); // Split by hyphen, space, or colon
    //         const year = parseInt(dateTimeParts[0], 10);
    //         const month = parseInt(dateTimeParts[1], 10) - 1; // JS months are zero-indexed
    //         const day = parseInt(dateTimeParts[2], 10);
    //         const hour = parseInt(dateTimeParts[3], 10);
    //         const minute = parseInt(dateTimeParts[4], 10);
    //         const second = parseInt(dateTimeParts[5], 10);

    //         // Create a Date object in IST
    //         // Note: The Date object automatically uses local time zone of the system it's running on.
    //         // Since we want to treat input as IST, we manually adjust it later.
    //         const dateIST = new Date(Date.UTC(year, month, day, hour, minute, second));

    //         // Adjust for IST to UTC conversion (-5 hours 30 minutes)
    //         dateIST.setHours(dateIST.getUTCHours() - 5);
    //         dateIST.setMinutes(dateIST.getUTCMinutes() - 30);

    //         // Format the date back to a string
    //         function pad(number) {
    //             return number < 10 ? '0' + number : number;
    //         }

    //         const utcDateString = dateIST.getUTCFullYear() + '-' +
    //             pad(dateIST.getUTCMonth() + 1) + '-' +
    //             pad(dateIST.getUTCDate()) + ' ' +
    //             pad(dateIST.getUTCHours()) + ':' +
    //             pad(dateIST.getUTCMinutes()) + ':' +
    //             pad(dateIST.getUTCSeconds());
    //         console.log("utcDateString", utcDateString)
    //         return utcDateString;
    //     } else {
    //         return ""
    //     }
    // }
    const urlParams = new URLSearchParams(window.location.search);
    const [filterValues, setFilterValues] = React.useState({
        prompt_log_id: urlParams.get('prompt_log_id') || "",
        from_date: urlParams.get('from_date') || "",
        page_number: urlParams.get('page_number') || 1,
        private_user_first_name: urlParams.get('private_user_first_name') || "",
        customer_dev_flag: urlParams.get('customer_dev_flag') || "false",
        private_user_email: urlParams.get('private_user_email') || "",
        // private_user_id: urlParams.get('private_user_id') || "",
        // public_user_id: urlParams.get('public_user_id') || "",
        prompt_name: urlParams.get('prompt_name') || "",
        records_per_page: urlParams.get('records_per_page') || 200,
        to_date: urlParams.get('to_date') || "",
        user_assignment_id: urlParams.get('user_assignment_id') || ""
    })
    const [showLoader, setShowLoader] = React.useState(false)
    const [logCount, setLogCount] = React.useState(50);
    const getCustomerPromptLogDetails = async (recordsPerPage, PageNo, flagCallAfterClearingFilters) => {
        const searchParams = new URLSearchParams();
        let response
        setFlagLoading(true)
        if (flagCallAfterClearingFilters) {
            searchParams.set('prompt_log_id', "");
            searchParams.set('user_assignment_id', "");
            searchParams.set('from_date', "");
            searchParams.set('to_date', "");
            searchParams.set('private_user_first_name', "");
            searchParams.set('customer_dev_flag', "false");
            searchParams.set('private_user_email', "");
            // searchParams.set('private_user_id', "");
            // searchParams.set('public_user_id', "");
            searchParams.set('prompt_name', "");
            searchParams.set('page_number', PageNo);
            searchParams.set('records_per_page', recordsPerPage);
            response = await agent.getCustomerPromptLogDetails.get("", "", "", "", "", "false", "", "", PageNo, recordsPerPage);
        } else {
            searchParams.set('prompt_log_id', filterValues.prompt_log_id);
            searchParams.set('from_date', filterValues.from_date);
            searchParams.set('page_number', PageNo);
            searchParams.set('private_user_first_name', filterValues.private_user_first_name);
            searchParams.set('customer_dev_flag', filterValues.customer_dev_flag);
            searchParams.set('private_user_email', filterValues.private_user_email);
            // searchParams.set('private_user_id', filterValues.private_user_id);
            // searchParams.set('public_user_id', filterValues.public_user_id);
            searchParams.set('prompt_name', filterValues.prompt_name);
            searchParams.set('records_per_page', recordsPerPage);
            searchParams.set('to_date', filterValues.to_date);
            searchParams.set('user_assignment_id', filterValues.user_assignment_id);

            response = await agent.getCustomerPromptLogDetails.get(filterValues.prompt_log_id, filterValues.user_assignment_id, timeFormat == "IST" ? convertISTtoUTC(filterValues.from_date) : filterValues.from_date, timeFormat == "IST" ? convertISTtoUTC(filterValues.to_date) : filterValues.to_date, filterValues.private_user_first_name, filterValues.customer_dev_flag, filterValues.private_user_email, filterValues.prompt_name, PageNo, recordsPerPage);
        }
        setFlagLoading(false)
        // let errorCode = response.isAxiosError ? (response.response && response.response.data ? (response?.response?.data?.error?.code || response?.data?.error?.code || response) : response) : response?.error?.code
        if (response.status) {
            if (response.data) {
                const temp = response.data.map((log, index) => {
                    return Object.keys(log).reduce((acc, key) => {
                        acc['id'] = index;
                        if (typeof log[key] === 'object' && !Array.isArray(log[key])) {
                            acc = { ...acc, ...log[key] };
                        } else {
                            acc[key] = log[key];
                        }
                        return acc;
                    }, {});
                });
                setCustomerPromptLogDetails(temp);
                setTotalRowCount(response.total_prompt_log_count);
            } else {
                setError({ ...error, flagShowError: true, message: response.error?.message || "Something Went Wrong!" });
            }
        } else {
            // if(response.isAxiosError){
            //     if(response.response && response.response.data && response.response.data.error && response.response.data.error.message){
            //         setError({...error, flagShowError:true, message:response.response.data.error.message})
            //     }
            // }
            // setError({ ...error, flagShowError: true, message: response.error?.message || "Something Went Wrong!" });
            setError({ error, flagShowError: true, message: checkError(response) });
        }

        window.history.replaceState({}, '', '?' + searchParams.toString());
    }
    React.useEffect(() => {
        getCustomerPromptLogDetails(200, 1)
    }, [])
    const handleLogCountChange = (e) => {
        setLogCount(e.target.value)
    }
    const initialColumns = [
        { id: 'prompt_log_id', label: 'Prompt log Id', minWidth: 150, align: "center" },
        { id: 'prompt_id', label: 'Prompt Id', minWidth: 150, align: "center" },
        { id: 'user_assignment_id', label: 'User Assignment Id', minWidth: 150, align: "center" },
        { id: 'model_id', label: 'Model Id', minWidth: 150, align: "center" },
        { id: 'prompt_system_variable', label: 'Prompt System Variable', minWidth: 150, align: "center" },
        { id: 'prompt_user_variable', label: 'Prompt User variable', minWidth: 150, align: "center" },
        { id: 'document_id', label: 'Document Id', minWidth: 150, align: "center" },
        { id: 'created_at_timestamp', label: 'Created at Timestamp', minWidth: 150, align: "center" },
        { id: 'output_log_response_metadata', label: 'output log response metadata', minWidth: 150, align: "center" },
        { id: 'output_log_response_metadata_original', label: 'original- output log response metadata ', minWidth: 150, align: "center" },
        { id: 'output_log_usage_prompt_tokens', label: 'Output log usage prompt tokens', minWidth: 150, align: "center" },
        { id: 'output_log_usage_completion_tokens', label: 'output log usage completion tokens', minWidth: 150, align: "center" },
        { id: 'output_log_usage_total_tokens', label: 'output log usage total tokens', minWidth: 150, align: "center" },
        { id: 'output_log_response_id', label: 'output log response Id', minWidth: 150, align: "center" },
        { id: 'credits_required', label: 'Credits Required', minWidth: 150, align: "center" },
        { id: 'key_model_combination_used', label: 'Key model combination used', minWidth: 150, align: "center" },
        { id: 'route_source_id', label: 'Route Source Id', minWidth: 150, align: "center" },
        { id: 'prompt_variant_id', label: 'Prompt Variant Id', minWidth: 150, align: "center" },
        { id: 'prompt_name', label: 'Prompt Name', minWidth: 150, align: "center" },
        { id: 'document_name', label: 'Document Name', minWidth: 150, align: "center" },
        { id: 'document_persona_id', label: 'Document Persona Id', minWidth: 150, align: "center" },
        { id: 'document_html_body', label: 'Document HTML Body', minWidth: 150, align: "center" },
        { id: 'user_type_public_flag', label: 'User Type Public Flag', minWidth: 150, align: "center" },
        { id: 'public_user_id', label: 'Public User Id', minWidth: 150, align: "center" },
        { id: 'public_user_first_name', label: 'Public User First Name', minWidth: 150, align: "center" },
        { id: 'public_user_last_name', label: 'Public User Last Name', minWidth: 150, align: "center" },
        { id: 'private_user_email', label: 'Private User Email', minWidth: 150, align: "center" },
        { id: 'model_name', label: 'Model name', minWidth: 150, align: "center" },
        { id: 'route_source_name', label: 'Route Source Name', minWidth: 150, align: "center" },
        { id: 'flag_america', label: 'flag America', minWidth: 150, align: "center" },
    ];
    const handleChangeRowsPerPage = (
        event
    ) => {
        setFilterValues({ ...filterValues, records_per_page: +event.target.value, page_number: 1 })
    };
    const handleChangePage = async (rowsPerPage, newPage) => {
        await getCustomerPromptLogDetails(rowsPerPage, newPage);
        setFilterValues({ ...filterValues, page_number: newPage })
        // if (Object.keys(filterValues).length === 0) {
        //     console.log("pageNumber", newPage);
        //     await getCustomerPromptLogDetails(rowsPerPage, newPage);
        //     setFilterValues({ ...filterValues, page_number: newPage })
        // } else {
        //     await getCustomerPromptLogDetails(rowsPerPage, newPage)
        //     setFilterValues({ ...filterValues, page_number: newPage })
        // }
    };
    const handleRowsChange = async (event) => {
        if (Object.keys(filterValues).length === 0) {
            handleChangeRowsPerPage(event)
        } else {
            handleChangeRowsPerPage(event)
        }
    }
    const handleFilterInputChange = (event, filterName) => {
        if (filterName == 'from_date' || filterName == "to_date") {
            if (event == null) {
                setFilterValues((prevFilterValues) => ({
                    ...prevFilterValues,
                    [filterName]: "",
                }));
            } else {
                // console.log(dayjs(event))
                let dateVal = dayjs(event).format('YYYY-MM-DD HH:mm:ss');
                // console.log("dateVal", dateVal)      

                setFilterValues((prevFilterValues) => ({
                    ...prevFilterValues,
                    [filterName]: dateVal,
                }));
            }
        } else {
            let value
            if (filterName == "customer_dev_flag") {
                value = event.target.checked;
            } else {
                value = event.target.value;
            }
            setFilterValues((prevFilterValues) => ({
                ...prevFilterValues,
                [filterName]: value,
            }));
        }
    };
    const handleClearFilters = () => {
        window.history.replaceState({}, '', '?');

        // setFilterValues((prevState) => {
        //     const newState = { ...prevState };
        //     Object.keys(newState).forEach(key => {
        //         if (key !== "records_per_page" || key !== "page_number") {
        //             newState[key] = ""
        //         } else {
        //             newState[key] = newState.key
        //         }
        //     })
        //     return newState;
        // })
        setFilterValues({
            from_date: "",
            page_number: 1,
            private_user_first_name: "",
            private_user_email: "",
            customer_dev_flag: "false",
            prompt_log_id: "",
            prompt_name: "",
            records_per_page: 200,
            to_date: "",
            user_assignment_id: ""
        })
        getCustomerPromptLogDetails(200, 1, true)
    }

    dayjs.extend(utc);
    const IST_OFFSET = 5.5;
    const changeTimeFormatUTC = () => {
        setTimeFormat("UTC");
        let fromDate = convertISTtoUTC(filterValues.from_date)
        let toDate = convertISTtoUTC(filterValues.to_date)
        setFilterValues({ ...filterValues, from_date: fromDate, to_date: toDate })
    }
    const changeTimeFormatIST = () => {
        setTimeFormat("IST");
        let fromDate = convertUTCtoIST(filterValues.from_date)
        let toDate = convertUTCtoIST(filterValues.to_date)
        setFilterValues({ ...filterValues, from_date: fromDate, to_date: toDate })
    }

    const getFormattedDate = (date) => {
        return dayjs(date);
        // let formatDate;
        // if(timeFormat === "IST"){
        //     formatDate = dayjs(convertUTCtoIST(date));
        //     return formatDate;
        // }
        // else{
        //     return dayjs(date);
        // }   
    };

    const changeTimeFormat = (date, filterName) => {
        if (timeFormat === "IST") {
            setFilterValues((prevFilterValues) => ({
                ...prevFilterValues,
                [filterName]: convertUTCtoIST(date)
            }));
        }
        else {
            setFilterValues((prevFilterValues) => ({
                ...prevFilterValues,
                [filterName]: date
            }))
        }
    }

    const clearField = (fieldName) => {
        setFilterValues({ ...filterValues, [fieldName]: '' })
    }

    return (
        <>
            <Grid container className={globalStyles.containerflexWrapStyle} direction={"column"} sx={{ flexWrap: "nowrap" }}>
                {/* filters */}

                <Accordion className={promptStyle.accordionStyle} expanded={isExpanded} onChange={toggleAccordion}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        className={promptStyle.accordionsummaryStyle}
                    >
                        {isExpanded ? 'Hide Filters' : 'Show Filters'}
                    </AccordionSummary>

                    <AccordionDetails className={promptStyle.accordiondetailStyle}>
                        <Grid item>
                            <Grid container gap="10px" alignItems={"flex-end"} sx={{ flexWrap: "nowrap" }}>
                                {/* prompt log Id */}
                                <Grid item>
                                    <Grid container direction="column" gap={1}>
                                        <Grid item>
                                            <InputLabel className={promptStyle.gridLabel}>Prompt Log Id</InputLabel>
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                placeholder="prompt_log_id"
                                                className={promptStyle.textfieldStyle}
                                                value={filterValues.prompt_log_id}
                                                onChange={(event) => handleFilterInputChange(event, 'prompt_log_id')}
                                                InputProps={{
                                                    endAdornment: (
                                                        filterValues.prompt_log_id && (
                                                            <IconButton
                                                                onClick={() => clearField('prompt_log_id')}
                                                                edge="end"
                                                            >
                                                                <ClearIcon />
                                                            </IconButton>
                                                        )
                                                    )
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {/* from date */}
                                <Grid item>
                                    <Grid container direction="column" gap={1}>
                                        <Grid item>
                                            <Grid container alignItems={"center"} justifyContent={"space-between"}>
                                                <Grid item>
                                                    <InputLabel className={promptStyle.gridLabel}>From Date</InputLabel>
                                                </Grid>
                                                <Grid item>
                                                    <ButtonGroup variant="text" aria-label="Small button group" className={promptStyle.utcButton}
                                                        onChange={() => changeTimeFormat(filterValues.from_date, 'from_date')}
                                                    >
                                                        <Button value="UTC" variant={timeFormat === "UTC" ? "contained" : "outlined"} disableRipple onClick={changeTimeFormatUTC} style={{ backgroundColor: timeFormat === "IST" ? "white" : "#6A097D", color: timeFormat === "IST" ? 'black' : 'white' }}>UTC</Button>
                                                        <Button value="IST" variant={timeFormat === "IST" ? "contained" : "outlined"} disableRipple onClick={changeTimeFormatIST} style={{ backgroundColor: timeFormat === "IST" ? "#6A097D" : "white", color: timeFormat === "IST" ? 'white' : 'black' }}>IST</Button>
                                                    </ButtonGroup>

                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DateTimePicker
                                                    className={promptStyle.textfieldStyle}
                                                    format="DD/MM/YYYY HH:mm:ss"
                                                    value={filterValues.from_date ? getFormattedDate(filterValues.from_date) : null}
                                                    onChange={(date) => handleFilterInputChange(date, 'from_date')}
                                                // clearable={true}
                                                // clearButton={<Button onClick={() => { setFilterValues({ ...filterValues, from_date: "" }) }}>Clear</Button>}                                                  
                                                />

                                            </LocalizationProvider>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {filterValues.from_date && (
                                    <Grid item>
                                        <IconButton
                                            onClick={() => clearField('from_date')} size="small"
                                        >
                                            <ClearIcon />
                                        </IconButton>
                                    </Grid>
                                )}
                                {/* to date */}
                                <Grid item>
                                    <Grid container direction="column" gap={1}>
                                        <Grid item>
                                            <Grid container alignItems={"center"} justifyContent={"space-between"}>
                                                <Grid item>
                                                    <InputLabel className={promptStyle.gridLabel}>To Date</InputLabel>
                                                </Grid>
                                                <Grid item>
                                                    <ButtonGroup variant="text" aria-label="Small button group" className={promptStyle.utcButton}>
                                                        <Button variant={timeFormat === "UTC" ? "contained" : "outlined"} disableRipple onClick={changeTimeFormatUTC} style={{ backgroundColor: timeFormat === "IST" ? "white" : "#6A097D", color: timeFormat === "IST" ? 'black' : 'white' }}>UTC</Button>
                                                        <Button variant={timeFormat === "IST" ? "contained" : "outlined"} disableRipple onClick={changeTimeFormatIST} style={{ backgroundColor: timeFormat === "IST" ? "#6A097D" : "white", color: timeFormat === "IST" ? 'white' : 'black' }}>IST</Button>
                                                    </ButtonGroup>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DateTimePicker
                                                    format="DD/MM/YYYY HH:mm:ss"
                                                    className={promptStyle.textfieldStyle}
                                                    clearable={true}
                                                    value={filterValues.to_date ? getFormattedDate(filterValues.to_date) : null}
                                                    onChange={(event) => handleFilterInputChange(event, 'to_date')}
                                                />
                                            </LocalizationProvider>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {filterValues.to_date && (
                                    <Grid item>
                                        <IconButton
                                            onClick={() => clearField('to_date')} size="small"
                                        >
                                            <ClearIcon />
                                        </IconButton>
                                    </Grid>
                                )}
                                {/* public user Id */}
                                <Grid item>
                                    <Grid container direction="column" gap={1}>
                                        <Grid item>
                                            <InputLabel className={promptStyle.gridLabel}>User Assignment Id</InputLabel>
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                placeholder="user_assignment_id"
                                                className={promptStyle.textfieldStyle}
                                                value={filterValues.user_assignment_id}
                                                onChange={(event) => handleFilterInputChange(event, 'user_assignment_id')}
                                                InputProps={{
                                                    endAdornment: (
                                                        filterValues.user_assignment_id && (
                                                            <IconButton
                                                                onClick={() => clearField('user_assignment_id')}
                                                                edge="end"
                                                            >
                                                                <ClearIcon />
                                                            </IconButton>
                                                        )
                                                    )
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {/* user email */}
                                <Grid item>
                                    <Grid container direction="column" gap={1}>
                                        <Grid item>
                                            <InputLabel className={promptStyle.gridLabel}>User Email</InputLabel>
                                        </Grid>
                                        <Grid item>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <TextField
                                                    placeholder="user_email"
                                                    className={promptStyle.textfieldStyle}
                                                    value={filterValues.private_user_email}
                                                    onChange={(event) => handleFilterInputChange(event, 'private_user_email')}
                                                    InputProps={{
                                                        endAdornment: (
                                                            filterValues.private_user_email && (
                                                                <IconButton
                                                                    onClick={() => clearField('private_user_email')}
                                                                    edge="end"
                                                                >
                                                                    <ClearIcon />
                                                                </IconButton>
                                                            )
                                                        )
                                                    }}
                                                />
                                            </LocalizationProvider>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {/* first name */}
                                <Grid item>
                                    <Grid container direction="column" gap={1}>
                                        <Grid item>
                                            <InputLabel className={promptStyle.gridLabel}>User First Name</InputLabel>
                                        </Grid>
                                        <Grid item>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <TextField
                                                    placeholder="private_user_first_name"
                                                    className={promptStyle.textfieldStyle}
                                                    value={filterValues.private_user_first_name}
                                                    onChange={(event) => handleFilterInputChange(event, 'private_user_first_name')}
                                                    InputProps={{
                                                        endAdornment: (
                                                            filterValues.private_user_first_name && (
                                                                <IconButton
                                                                    onClick={() => clearField('private_user_first_name')}
                                                                    edge="end"
                                                                >
                                                                    <ClearIcon />
                                                                </IconButton>
                                                            )
                                                        )
                                                    }}
                                                />
                                            </LocalizationProvider>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {/* private user Id */}
                                {/* <Grid item>
                                    <Grid container direction="column" gap={1}>
                                        <Grid item>
                                            <InputLabel className={promptStyle.gridLabel}>Private User Id</InputLabel>
                                        </Grid>
                                        <Grid item>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <TextField
                                                    placeholder="private_user_id"
                                                    className={promptStyle.textfieldStyle}
                                                    value={filterValues.private_user_id}
                                                    onChange={(event) => handleFilterInputChange(event, 'private_user_id')}
                                                    InputProps={{
                                                        endAdornment: (
                                                            filterValues.private_user_id && (
                                                                <IconButton
                                                                    onClick={() => clearField('private_user_id')}
                                                                    edge="end"
                                                                >
                                                                    <ClearIcon />
                                                                </IconButton>
                                                            )
                                                        )
                                                    }}
                                                />
                                            </LocalizationProvider>
                                        </Grid>
                                    </Grid>
                                </Grid> */}
                                {/* public user Id */}
                                {/* <Grid item>
                                    <Grid container direction="column" gap={1}>
                                        <Grid item>
                                            <InputLabel className={promptStyle.gridLabel}>Public User Id</InputLabel>
                                        </Grid>
                                        <Grid item>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <TextField
                                                    placeholder="public_user_id"
                                                    className={promptStyle.textfieldStyle}
                                                    value={filterValues.public_user_id}
                                                    onChange={(event) => handleFilterInputChange(event, 'public_user_id')}
                                                    InputProps={{
                                                        endAdornment: (
                                                            filterValues.public_user_id && (
                                                                <IconButton
                                                                    onClick={() => clearField('public_user_id')}
                                                                    edge="end"
                                                                >
                                                                    <ClearIcon />
                                                                </IconButton>
                                                            )
                                                        )
                                                    }}
                                                />
                                            </LocalizationProvider>
                                        </Grid>
                                    </Grid>
                                </Grid> */}
                                {/* prompt name */}
                                <Grid item>
                                    <Grid container direction="column" gap={1}>
                                        <Grid item>
                                            <InputLabel className={promptStyle.gridLabel}>Prompt Name</InputLabel>
                                        </Grid>
                                        <Grid item>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <TextField
                                                    placeholder="prompt_name"
                                                    className={promptStyle.textfieldStyle}
                                                    value={filterValues.prompt_name}
                                                    onChange={(event) => handleFilterInputChange(event, 'prompt_name')}
                                                    InputProps={{
                                                        endAdornment: (
                                                            filterValues.prompt_name && (
                                                                <IconButton
                                                                    onClick={() => clearField('prompt_name')}
                                                                    edge="end"
                                                                >
                                                                    <ClearIcon />
                                                                </IconButton>
                                                            )
                                                        )
                                                    }}
                                                />
                                            </LocalizationProvider>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                {/* dev flag */}
                                <Grid item>
                                    <Grid container direction="column" gap={1}>
                                        <Grid item>
                                            <InputLabel className={promptStyle.gridLabel}>Flag Dev</InputLabel>
                                        </Grid>
                                        <Grid item >
                                            <Checkbox onChange={(event) => handleFilterInputChange(event, 'customer_dev_flag')} checked={(filterValues.customer_dev_flag == "true" || filterValues.customer_dev_flag == true) ? true : false} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Globalbutton onClick={() => getCustomerPromptLogDetails(200, 1)} buttonText="Apply" className="primaryButtonStyle">Apply</Globalbutton>
                                </Grid>
                                <Grid item>
                                    <Globalbutton onClick={() => { handleClearFilters() }} buttonText="Clear" className="primaryButtonStyle">Clear</Globalbutton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>

                {/* table */}
                <Grid item>
                    <Grid container sx={{ flexWrap: "nowrap" }}>
                        <Grid item xs={showRowDetail ? 6 : 12}>
                            <PromptLogsV2Table customerPromptLogDetails={customerPromptLogDetails} setShowRowDetail={setShowRowDetail} setCurrentRowToShow={setCurrentRowToShow} currentRowToShow={currentRowToShow} totalRowCount={totalRowCount} handleChangePage={handleChangePage} records_per_page={filterValues.records_per_page} page_number={filterValues.page_number} />
                        </Grid>
                        {/* on row click form */}
                        {showRowDetail &&
                            <>
                                <Grid item xs={showRowDetail ? 6 : 12} sx={{ padding: "15px", marginLeft: '25px', maxHeight: "calc(100vh - 227px)", overflow: "scroll" }} className={`${promptStyle.scrollBarHeight} ${promptStyle.gridShadow}`}>
                                    <CloseIcon className={globalStyles.clickable} onClick={() => { setShowRowDetail(false) }} style={{ position: 'absolute', right: '55px', backgroundColor: 'rgba(0,0,0,0.05)', borderRadius: '50%', cursor: 'pointer', zIndex: '2' }} />
                                    <Grid container direction="row" gap="16px" className={promptStyle.gridContainer} flexWrap='nowrap'>
                                        <Grid item xs={6}>
                                            <Grid container direction="column" gap={1}>
                                                <Grid item>
                                                    <InputLabel className={promptStyle.gridLabel}>Prompt name</InputLabel>
                                                </Grid>
                                                <Grid item>
                                                    <TextField
                                                        className={`${globalStyles.fullwidth} ${promptStyle.gridtextField}`}
                                                        value={currentRowToShow.rowData.prompt_name || ''}
                                                        disabled={true}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Grid container direction="column" gap={1}>
                                                <Grid item>
                                                    <InputLabel className={promptStyle.gridLabel}>Prompt id</InputLabel>
                                                </Grid>
                                                <Grid item>
                                                    <TextField
                                                        className={`${globalStyles.fullwidth} ${promptStyle.gridtextField}`}
                                                        value={currentRowToShow.rowData.prompt_id || ''}
                                                        disabled={true}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid container direction="row" gap='16px' className={promptStyle.gridContainer} flexWrap='nowrap'>
                                        <Grid item xs={6}>
                                            <Grid container direction="column" gap={1}>
                                                <Grid item>
                                                    <InputLabel className={promptStyle.gridLabel}>Prompt variant name</InputLabel>
                                                </Grid>
                                                <Grid item>
                                                    <TextField
                                                        className={`${globalStyles.fullwidth} ${promptStyle.gridtextField}`}
                                                        value={currentRowToShow.rowData.prompt_variant_name || ''}
                                                        disabled={true}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Grid container direction="column" gap={1}>
                                                <Grid item>
                                                    <InputLabel className={promptStyle.gridLabel}>Prompt variant id</InputLabel>
                                                </Grid>
                                                <Grid item>
                                                    <TextField
                                                        className={`${globalStyles.fullwidth} ${promptStyle.gridtextField}`}
                                                        value={currentRowToShow.rowData.prompt_variant_id || ''}
                                                        disabled={true}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid container direction="row" gap="16px" className={promptStyle.gridContainer} flexWrap='nowrap'>
                                        <Grid item xs={6}>
                                            <Grid container direction="column" gap={1}>
                                                <Grid item>
                                                    <InputLabel className={promptStyle.gridLabel}>Model name</InputLabel>
                                                </Grid>
                                                <Grid item>
                                                    <TextField
                                                        className={`${globalStyles.fullwidth} ${promptStyle.gridtextField}`}
                                                        value={currentRowToShow.rowData.model_name || ''}
                                                        disabled={true}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Grid container direction="column" gap={1}>
                                                <Grid item>
                                                    <InputLabel className={promptStyle.gridLabel}>Model id</InputLabel>
                                                </Grid>
                                                <Grid item>
                                                    <TextField
                                                        className={`${globalStyles.fullwidth} ${promptStyle.gridtextField}`}
                                                        value={currentRowToShow.rowData.model_id || ''}
                                                        disabled={true}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid container direction="column" gap={1} className={promptStyle.gridContainer}>
                                        <Grid item>
                                            <InputLabel className={promptStyle.gridLabel}>Prompt log id</InputLabel>
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                className={`${globalStyles.fullwidth} ${promptStyle.gridtextField}`}
                                                value={currentRowToShow.rowData.prompt_log_id || ''}
                                                disabled={true}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container direction="column" gap={1} className={promptStyle.gridContainer}>
                                        <Grid item>
                                            <InputLabel className={promptStyle.gridLabel}>User assignment id</InputLabel>
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                className={`${globalStyles.fullwidth} ${promptStyle.gridtextField}`}
                                                value={currentRowToShow.rowData.user_assignment_id || ''}
                                                disabled={true}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container direction="column" gap={1} className={promptStyle.gridContainer}>
                                        <Grid item>
                                            <InputLabel className={promptStyle.gridLabel}>Prompt system variable</InputLabel>
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                className={`${globalStyles.fullwidth} ${promptStyle.gridtextField}`}
                                                value={currentRowToShow.rowData.prompt_system_variable || ''}
                                                disabled={true}
                                                multiline
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container direction="column" gap={1} className={promptStyle.gridContainer}>
                                        <Grid item>
                                            <InputLabel className={promptStyle.gridLabel}>Prompt user variable</InputLabel>
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                className={`${globalStyles.fullwidth} ${promptStyle.gridtextField}`}
                                                value={currentRowToShow.rowData.prompt_user_variable || ''}
                                                disabled={true}
                                                multiline
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container direction="row" gap='16px' className={promptStyle.gridContainer} flexWrap='nowrap'>
                                        <Grid item xs={6}>
                                            <Grid container direction="column" gap={1}>
                                                <Grid item>
                                                    <InputLabel className={promptStyle.gridLabel}>Document name</InputLabel>
                                                </Grid>
                                                <Grid item>
                                                    <TextField
                                                        className={`${globalStyles.fullwidth} ${promptStyle.gridtextField}`}
                                                        value={currentRowToShow.rowData.document_name || ''}
                                                        disabled={true}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Grid container direction="column" gap={1}>
                                                <Grid item>
                                                    <InputLabel className={promptStyle.gridLabel}>Document id</InputLabel>
                                                </Grid>
                                                <Grid item>
                                                    <TextField
                                                        className={`${globalStyles.fullwidth} ${promptStyle.gridtextField}`}
                                                        value={currentRowToShow.rowData.document_id || ''}
                                                        disabled={true}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid container direction="row" gap='16px' className={promptStyle.gridContainer} flexWrap='nowrap'>
                                        <Grid item xs={6}>
                                            <Grid container direction="column" gap={1}>
                                                <Grid item>
                                                    <InputLabel className={promptStyle.gridLabel}>Created at timestamp (UTC)</InputLabel>
                                                </Grid>
                                                <Grid item>
                                                    <TextField
                                                        className={`${globalStyles.fullwidth} ${promptStyle.gridtextField}`}
                                                        value={currentRowToShow.rowData.created_at_timestamp || ''}
                                                        disabled={true}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Grid container direction="column" gap={1}>
                                                <Grid item>
                                                    <InputLabel className={promptStyle.gridLabel}>Created at timestamp (IST)</InputLabel>
                                                </Grid>
                                                <Grid item>
                                                    <TextField
                                                        className={`${globalStyles.fullwidth} ${promptStyle.gridtextField}`}
                                                        value={convertUTCtoIST(currentRowToShow.rowData.created_at_timestamp) || ''}
                                                        disabled={true}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid container direction="column" gap={1} className={promptStyle.gridContainer}>
                                        <Grid item>
                                            <InputLabel className={promptStyle.gridLabel}>Output in app</InputLabel>
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                className={`${globalStyles.fullwidth} ${promptStyle.gridtextField}`}
                                                value={currentRowToShow.rowData.log_data.length > 0 ? currentRowToShow.rowData.log_data.map((prompt) => prompt.prompt_log_data_response_value) : null || ''}
                                                disabled={true}
                                                multiline
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container direction="column" gap={1} className={promptStyle.gridContainer}>
                                        <Grid item>
                                            <InputLabel className={promptStyle.gridLabel}>Output log response metadata</InputLabel>
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                className={`${globalStyles.fullwidth} ${promptStyle.gridtextField}`}
                                                value={currentRowToShow.rowData.output_log_response_metadata || ''}
                                                disabled={true}
                                                multiline
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container direction="column" gap={1} className={promptStyle.gridContainer}>
                                        <Grid item>
                                            <InputLabel className={promptStyle.gridLabel}>Original - Output log response metadata</InputLabel>
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                className={`${globalStyles.fullwidth} ${promptStyle.gridtextField}`}
                                                value={currentRowToShow.rowData.output_log_response_metadata_original || ''}
                                                disabled={true}
                                                multiline
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container direction="row" gap='16px' className={promptStyle.gridContainer} flexWrap='nowrap'>
                                        <Grid item xs={6}>
                                            <Grid container direction="column" gap={1}>
                                                <Grid item>
                                                    <InputLabel className={promptStyle.gridLabel}>Output log usage prompt tokens</InputLabel>
                                                </Grid>
                                                <Grid item>
                                                    <TextField
                                                        className={`${globalStyles.fullwidth} ${promptStyle.gridtextField}`}
                                                        value={currentRowToShow.rowData.output_log_usage_prompt_tokens || ''}
                                                        disabled={true}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Grid container direction="column" gap={1}>
                                                <Grid item>
                                                    <InputLabel className={promptStyle.gridLabel}>Output log usage completion tokens</InputLabel>
                                                </Grid>
                                                <Grid item>
                                                    <TextField
                                                        className={`${globalStyles.fullwidth} ${promptStyle.gridtextField}`}
                                                        value={currentRowToShow.rowData.output_log_usage_completion_tokens || ''}
                                                        disabled={true}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid container direction="column" gap={1} className={promptStyle.gridContainer}>
                                        <Grid item>
                                            <InputLabel className={promptStyle.gridLabel}>Output log usage total tokens</InputLabel>
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                className={`${globalStyles.fullwidth} ${promptStyle.gridtextField}`}
                                                value={currentRowToShow.rowData.output_log_usage_total_tokens || ''}
                                                disabled={true}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container direction="column" gap={1} className={promptStyle.gridContainer}>
                                        <Grid item>
                                            <InputLabel className={promptStyle.gridLabel}>Output log response id</InputLabel>
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                className={`${globalStyles.fullwidth} ${promptStyle.gridtextField}`}
                                                value={currentRowToShow.rowData.output_log_response_id || ''}
                                                disabled={true}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container direction="column" gap={1} className={promptStyle.gridContainer}>
                                        <Grid item>
                                            <InputLabel className={promptStyle.gridLabel}>Credits required</InputLabel>
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                className={`${globalStyles.fullwidth} ${promptStyle.gridtextField}`}
                                                value={currentRowToShow.rowData.credits_required || ''}
                                                disabled={true}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container direction="column" gap={1} className={promptStyle.gridContainer}>
                                        <Grid item>
                                            <InputLabel className={promptStyle.gridLabel}>Key model combination used</InputLabel>
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                className={`${globalStyles.fullwidth} ${promptStyle.gridtextField}`}
                                                value={currentRowToShow.rowData.key_model_combination_used || ''}
                                                disabled={true}
                                                multiline
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container direction="column" gap={1} className={promptStyle.gridContainer}>
                                        <Grid item>
                                            <InputLabel className={promptStyle.gridLabel}>Document persona id</InputLabel>
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                className={`${globalStyles.fullwidth} ${promptStyle.gridtextField}`}
                                                value={currentRowToShow.rowData.document_persona_id || ''}
                                                disabled={true}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container direction="column" gap={1} className={promptStyle.gridContainer}>
                                        <Grid item>
                                            <InputLabel className={promptStyle.gridLabel}>Document html body</InputLabel>
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                className={`${globalStyles.fullwidth} ${promptStyle.gridtextField}`}
                                                value={currentRowToShow.rowData.document_html_body || ''}
                                                disabled={true}
                                                multiline
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container direction="column" gap={1} className={promptStyle.gridContainer}>
                                        <Grid item>
                                            <InputLabel className={promptStyle.gridLabel}>User type public flag</InputLabel>
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                className={`${globalStyles.fullwidth} ${promptStyle.gridtextField}`}
                                                value={currentRowToShow.rowData.user_type_public_flag || ''}
                                                disabled={true}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container direction="column" gap={1} className={promptStyle.gridContainer}>
                                        <Grid item>
                                            <InputLabel className={promptStyle.gridLabel}>User id</InputLabel>
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                className={`${globalStyles.fullwidth} ${promptStyle.gridtextField}`}
                                                value={currentRowToShow.rowData.user_id || ''}
                                                disabled={true}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container direction="row" gap='16px' className={promptStyle.gridContainer} flexWrap='nowrap'>
                                        <Grid item xs={6}>
                                            <Grid container direction="column" gap={1}>
                                                <Grid item>
                                                    <InputLabel className={promptStyle.gridLabel}>User first name</InputLabel>
                                                </Grid>
                                                <Grid item>
                                                    <TextField
                                                        className={`${globalStyles.fullwidth} ${promptStyle.gridtextField}`}
                                                        value={currentRowToShow.rowData.user_first_name || ''}
                                                        disabled={true}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Grid container direction="column" gap={1}>
                                                <Grid item>
                                                    <InputLabel className={promptStyle.gridLabel}>User last name</InputLabel>
                                                </Grid>
                                                <Grid item>
                                                    <TextField
                                                        className={`${globalStyles.fullwidth} ${promptStyle.gridtextField}`}
                                                        value={currentRowToShow.rowData.user_last_name || ''}
                                                        disabled={true}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid container direction="column" gap={1} className={promptStyle.gridContainer}>
                                        <Grid item>
                                            <InputLabel className={promptStyle.gridLabel}>User status id</InputLabel>
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                className={`${globalStyles.fullwidth} ${promptStyle.gridtextField}`}
                                                value={currentRowToShow.rowData.user_status_id || ''}
                                                disabled={true}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container direction="column" gap={1} className={promptStyle.gridContainer}>
                                        <Grid item>
                                            <InputLabel className={promptStyle.gridLabel}>User email</InputLabel>
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                className={`${globalStyles.fullwidth} ${promptStyle.gridtextField}`}
                                                value={currentRowToShow.rowData.user_email || ''}
                                                disabled={true}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container direction="row" gap='16px' className={promptStyle.gridContainer} flexWrap='nowrap'>
                                        <Grid item xs={6}>
                                            <Grid container direction="column" gap={1}>
                                                <Grid item>
                                                    <InputLabel className={promptStyle.gridLabel}>Route source name</InputLabel>
                                                </Grid>
                                                <Grid item>
                                                    <TextField
                                                        className={`${globalStyles.fullwidth} ${promptStyle.gridtextField}`}
                                                        value={currentRowToShow.rowData.route_source_name || ''}
                                                        disabled={true}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Grid container direction="column" gap={1}>
                                                <Grid item>
                                                    <InputLabel className={promptStyle.gridLabel}>Route source id</InputLabel>
                                                </Grid>
                                                <Grid item>
                                                    <TextField
                                                        className={`${globalStyles.fullwidth} ${promptStyle.gridtextField}`}
                                                        value={currentRowToShow.rowData.route_source_id || ''}
                                                        disabled={true}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>


                                    <Grid container direction="column" gap={1} className={promptStyle.gridContainer}>
                                        <Grid item>
                                            <InputLabel className={promptStyle.gridLabel}>Flag america</InputLabel>
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                className={`${globalStyles.fullwidth} ${promptStyle.gridtextField}`}
                                                value={currentRowToShow.rowData.flag_america || ''}
                                                disabled={true}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </>
                        }
                    </Grid>
                </Grid>
            </Grid>

        </>
    )
}

export default PromptLogsV2;
