import * as React from "react";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Divider, Grid, TextareaAutosize } from "@mui/material";
import { useNavigate } from "react-router-dom";
import GlobalButton from "./button";


export default function ImportFromFormwiz(props) {
    const navigate = useNavigate();

    //   const handleCreateUser = async (value: any) => {
    //     const payload = {
    //       google_recaptcha_token: value,
    //     };
    //     const response: CommonResponseModel<RegisterDataResponseModel> =
    //       await Register(payload);
    //     console.log("response of register api", response);
    //     if (response.status) {
    //       if(response.data){
    //         setUserDetail({...response.data[0],flagUser:response.status})
    //       }
    //     }
    //     console.log("printing recaptcha value", value);
    //   };

    const [flagContinueAsGuest, setFlagContinueAsGuest] = React.useState(false);

    return (
        <div>
            <DialogContent dividers>
                <Grid item>
                    <Grid container direction="column">
                        <Grid item>Paste your JSON here</Grid>
                        <Grid item><TextareaAutosize value={props.importedJSONData} onChange={(e) => { props.setImportedJSONData(e.target.value) }} /></Grid>
                        <GlobalButton buttonText="Get started" className="primaryButtonStyle" onClick={() => { props.getStartedfunc() }} />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions></DialogActions>
        </div>
    );
}
