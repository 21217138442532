import React, { useState } from "react";
import agent from "../api/api";

const UserContext = React.createContext();

const UserProvider = ({ children }) => {
    const [flagLoading, setFlagLoading] = useState(false);
    const emptyUserDetail = {
        flagUser: null,
        flag_user_anonymous: null,
        flag_email_verified: null,
        user_id: 0,
        user_type: "",
        user_first_name: "",
        user_last_name: "",
        user_email: "",
        user_assignment_id: 0,
        user_phone_number: "",
        user_status: {
            user_status_id: 0,
            user_status_name: ""
        }
    };
    const emptyErrorObj = {
        flagShowError: false,
        message: "",
    };
    const emptySuccessObj = {
        flagShowSuccess: false,
        message: "",
    };
    const [userDetail, setUserDetail] = useState(emptyUserDetail);
    const [error, setError] = useState(emptyErrorObj);
    const [success, setSuccess] = useState(emptySuccessObj);
    const [headerTitle,setHeaderTitle] = useState("Existing Customers")
    const [currentActiveMenu, setCurrentActiveMenu] = React.useState("home");
    const UserMe = async () => {
        setFlagLoading(true);
        const response = await agent.UserMeAPI.get();
        if (response.status) {
            setUserDetail({
                ...userDetail,
                flagUser: response.status,
                ...response.data[0],
            });
            let tempUserDetail = {
                flaguserAnonymous: response.data[0].flag_user_anonymous,
                userfirstName: response.data[0].user_first_name,
                userLastName: response.data[0].user_last_name,
                userFullName: ` ${response.data[0].user_first_name} ${response.data[0].user_last_name}`,
                userStatus: response.status
            }
            window.postMessage({ type: "SET_WRITEWIZ_LOGIN_DETAILS", data: tempUserDetail }, "*");
        } else {
            setUserDetail({ ...emptyUserDetail, flagUser: false });
            window.postMessage({ type: "SET_WRITEWIZ_LOGIN_DETAILS", data: null }, "*");

        }
        setFlagLoading(false);
        return response;
    };

    const SendLoginOTP = async (payload) => {
        const response = await agent.SendLoginOTPAPI.post(payload);
        return response;
    };
    const Login = async (payload) => {
        const response = await agent.VerifyLoginOTPAPI.put(payload);
        return response;
    };

    const payload = {
        flagLoading,
        setFlagLoading,
        userDetail,
        setUserDetail,
        UserMe,
        SendLoginOTP,
        Login,
        error,
        setError,
        success,
        setSuccess,
        headerTitle,
        setHeaderTitle,
        currentActiveMenu, 
        setCurrentActiveMenu
    };

    return (
        <UserContext.Provider value={payload}>{children}</UserContext.Provider>
    );
};

export { UserContext, UserProvider };
