import logo from './logo.svg';
import './App.css';
import { Fragment } from 'react';
import GlobalRoutes from './routes/routes';
import { UserProvider } from './context/userContext';
import { TemplateProvider } from './context/templateContext';
import { UserDetailsProvider } from './context/userDetailsContext';
import { PromptProvider } from './context/promptContext';
import { GenericDataProvider } from './context/GenericData';

function App() {
  return (
    <Fragment>
      <GenericDataProvider>
        <UserProvider>
          <TemplateProvider>
            <UserDetailsProvider>
              <PromptProvider>
                <GlobalRoutes />
              </PromptProvider>
            </UserDetailsProvider>
          </TemplateProvider>
        </UserProvider>
      </GenericDataProvider>
    </Fragment>
  );
}

export default App;
