import * as React from 'react';
import Button from '@mui/material/Button';
import thunderbolt from "../assets/thunderBolt.png";
import flagMoveForwardImg from "../assets/moveForwardArrow.png"
import styles from "../assets/styles/button.module.scss";
import globalStyles from "../assets/styles/global.module.scss";

export default function GlobalButton(props) {


    return (
        <Button
            disabled={props.disabled}

            sx={{ opacity: props.disabled ? "0.5" : "1", textTransform: "unset", height: props.height }}
            onClick={props.onClick} className={props.flagFullWidth ? `${globalStyles.fullwidth} ${styles[props.className]}` : styles[props.className]}
            variant="contained">
            {props.flagGenerate && <img src={thunderbolt} style={{ width: "9px", height: "auto", marginRight: "10px" }} />}
            {props.buttonText}
            {props.flagMoveForward && <img src={flagMoveForwardImg} style={{ width: "24px", height: "auto", display: "flex", paddingLeft: "5px" }} />}
        </Button>
    );
}
