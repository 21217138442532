import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import writewizLogo from "../assets/writewizLogo.svg";
import styles from "../assets/styles/sideNavigationPabel.module.scss"
import { useNavigate } from 'react-router-dom';
import { Grid, Menu } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Logout from '@mui/icons-material/Logout';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import agent from '../api/api';
import profileIcon from "../assets/profileIcon.svg";
import { UserContext } from '../context/userContext';

const drawerWidth = 240;


export default function SideNavigationPanel(props) {
    const { userDetail, UserMe, setFlagLoading, currentActiveMenu, setCurrentActiveMenu } = React.useContext(UserContext)
    const [hoveredMenu, setHoveredMenu] = React.useState("");
    const navigate = useNavigate();

    const [openMenu, setOpenMenu] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setOpenMenu(true);
    };
    const handleClose = () => {
        setAnchorEl(null);
        setOpenMenu(false);
    };

    React.useEffect(() => {
        UserMe();
    }, []);

    React.useEffect(() => {
        if (window.location.pathname == "/dashboard/prompts") {
            setCurrentActiveMenu("prompts")
        } else if (window.location.pathname.includes("/dashboard/prompts/promptDetails")) {
            setCurrentActiveMenu("prompts")
        } else if (window.location.pathname == "/dashboard/existingCustomers") {
            setCurrentActiveMenu("existingcustomers")
        } else if (window.location.pathname == "/dashboard/prompts/createPrompt") {
            setCurrentActiveMenu("createPrompt")
        } else if (window.location.pathname == "/dashboard/templates") {
            setCurrentActiveMenu("templates")
        } else if (window.location.pathname == "/dashboard/templates/createTemplate") {
            setCurrentActiveMenu("templates")
        } else if (window.location.pathname == "/dashboard/promptLogs") {
            setCurrentActiveMenu("promptLogs")
        } else if (window.location.pathname == "/dashboard/user") {
            setCurrentActiveMenu("user")
        } else if (window.location.pathname == "/dashboard/loaderDetails") {
            setCurrentActiveMenu("loaderDetails")
        } else if (window.location.pathname == "/dashboard/promptLogsV2") {
            setCurrentActiveMenu("promptLogsV2")
        }
        else {
            setCurrentActiveMenu(window.location.pathname)
        }
    }, [window.location.pathname])

    const drawer = (
        <>
            <Box display={"flex"} flexDirection={"column"} justifyContent={"space-between"} flexGrow={1}>
                <div>
                    <Toolbar>
                        <Grid container direction="column" alignItems={"center"}>
                            <Grid item>
                                <img src={writewizLogo} />
                            </Grid>
                            <Grid item>
                                <Typography className={styles.devPortalStyle}>Dev-Portal</Typography>
                            </Grid>
                        </Grid>
                    </Toolbar>
                    <List>
                        {props.menuItems.map((menuItem, index) => (
                            <ListItem className={currentActiveMenu == menuItem.menuKey ? styles.selectedListItemStyle : styles.listItemStyle} onMouseEnter={() => { setHoveredMenu(menuItem.menuKey) }} onMouseLeave={() => { setHoveredMenu("") }} onClick={() => { setCurrentActiveMenu(menuItem.menuKey); navigate(menuItem.navigationLink) }} key={menuItem.menuKey} disablePadding>
                                <ListItemButton>
                                    <ListItemIcon>
                                        <img src={(hoveredMenu == menuItem.menuKey || currentActiveMenu == menuItem.menuKey) ? menuItem.whiteImgSrc : menuItem.imgSrc} />
                                    </ListItemIcon>
                                    <ListItemText primary={menuItem.menuName} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </div>
            </Box>

            <Box className={styles.mainBlock}>
                <Box className={styles.subMainBlock}>
                    <img src={profileIcon} className={styles.profileIconStyle} />
                    <Typography className={styles.nameStyle}>{`${userDetail.user_first_name} ${userDetail.user_last_name !== null ? userDetail.user_last_name : ""}`}</Typography>
                </Box>
                <ArrowForwardIosIcon
                    onClick={handleClick}
                    aria-controls={openMenu ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={openMenu ? 'true' : undefined}
                    className={styles.arrowForwardIconStyle}
                />
            </Box>


            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={openMenu}
                onClose={handleClose}
                onClick={handleClose}
                className={styles.menuStyleSidePanel}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        // mt: -2,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
            >
                <MenuItem className={styles.menuLogout}
                    onClick={async () => {
                        setFlagLoading(true);
                        const response = await agent.LogoutUser.post();
                        if (response.status) {
                            setFlagLoading(false);
                            navigate("/login")
                        } else {
                            alert("something went wrong!");
                            setFlagLoading(false);
                        }
                    }}>
                    <ListItemIcon >
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
        </>
    );


    return (
        <>
            {/* { flagloading && <RoundLoader /> } */}

            <Box className={styles.drawerStyleBox}>
                <Box
                    component="nav"
                    sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                    aria-label="mailbox folders"
                >
                    {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                    <Drawer
                        className={styles.sideNavigationPanelStyle}
                        // container={container}
                        variant="temporary"
                        open={props.open}
                        onClose={props.onClose}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                        sx={{
                            display: { xs: 'block', sm: 'none' },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                        }}
                    >
                        {drawer}
                    </Drawer>
                    <Drawer
                        className={styles.sideNavigationPanelStyle}
                        variant="permanent"
                        sx={{
                            display: { xs: 'none', sm: 'block' },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                        }}
                        open
                    >
                        {drawer}
                    </Drawer>
                </Box>
            </Box>
        </>
    );
}
