import React from 'react';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import { DialogTitle, IconButton, Backdrop } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiPaper-root": {
        minWidth: "500px"
    },
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));


function BootstrapDialogTitle(props) {
    const { title, flagShowCloseIcon, handleClose } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2, textAlign: 'center' }}>
            {title}
            {flagShowCloseIcon && handleClose ? (
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

const GlobalPopUp = ({
    title,
    open,
    flagShowCloseIcon,
    handleClose,
    children,
    ...dialogProps
}) => {
    const handleBackdropClick = (event) => {
        // Prevent closing when clicking on the backdrop
        if (event.target === event.currentTarget) {
            return;
        }
    };

    return (
        <BootstrapDialog
            open={open}
            onClose={handleClose}
            {...dialogProps}
            disableEscapeKeyDown
        >
            {/* <Backdrop
                open={open}
                onClick={handleBackdropClick}
                sx={{ opacity: "-1 !important" }}
            >
            </Backdrop> */}
            <BootstrapDialogTitle
                open={open}
                title={title}
                flagShowCloseIcon={flagShowCloseIcon}
                handleClose={handleClose}
                disableEscapeKeyDown={true}
            />
            {children}
        </BootstrapDialog>
    );
};

export default GlobalPopUp;
