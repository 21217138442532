import * as React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, IconButton, InputAdornment, InputLabel, Paper, Popover, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, TextField, TextareaAutosize } from '@mui/material';
import CustomDataGridTable from "../components/customizableDataGrid";
import agent from "../api/api";
import RoundLoader from "../components/roundLoader";
import globalStyles from "../assets/styles/global.module.scss";
import UpdateIcon from '@mui/icons-material/Update';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import RefreshIcon from '@mui/icons-material/Refresh';
import { useSearchParams } from "react-router-dom";
import Globalbutton from "../components/button"
import CloseIcon from '@mui/icons-material/Close';
import { UserContext } from "../context/userContext";
import promptStyle from './../assets/styles/promptLogsV2.module.scss';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DisabledByDefault } from "@mui/icons-material";
import { convertUTCtoIST } from "../utils/constants";

const PromptLogsV2Table = React.memo(({ customerPromptLogDetails, setShowRowDetail, setCurrentRowToShow, currentRowToShow, totalRowCount, handleChangePage, records_per_page, page_number
}) => {
    const initialColumns = [
        { id: 'prompt_log_id', label: 'Prompt log Id', minWidth: 150, align: "center" },
        { id: 'created_at_timestamp', label: 'Created at Timestamp (UTC)', minWidth: 150, align: "center" },
        { id: 'created_at_timestamp_IST', label: 'Created at Timestamp (IST)', minWidth: 150, align: "center" },
        { id: 'user_assignment_id', label: 'User Assignment Id', minWidth: 150, align: "center" },
        { id: 'user_email', label: 'User Email', minWidth: 150, align: "center" },
        { id: 'chatgpt_response', label: 'Chatgpt Response', minWidth: 150, align: "center" },
        { id: 'prompt_name', label: 'Prompt Name', minWidth: 150, align: "center" },
        { id: 'model_name', label: 'Model name', minWidth: 150, align: "center" },
        { id: 'prompt_system_variable', label: 'Prompt System Variable', minWidth: 150, align: "center" },
        { id: 'prompt_user_variable', label: 'Prompt User variable', minWidth: 150, align: "center" },
        { id: 'output_log_response_metadata', label: 'output log response metadata', minWidth: 150, align: "center" },
        { id: 'output_log_response_metadata_original', label: 'original- output log response metadata ', minWidth: 150, align: "center" },
        // { id: 'output_log_usage_prompt_tokens', label: 'Output log usage prompt tokens', minWidth: 150, align: "center" },
        { id: 'output_log_usage_completion_tokens', label: 'output log usage completion tokens', minWidth: 150, align: "center" },
        { id: 'output_log_usage_total_tokens', label: 'output log usage total tokens', minWidth: 150, align: "center" },
        { id: 'output_log_response_id', label: 'output log response Id', minWidth: 150, align: "center" },
        { id: 'credits_required', label: 'Credits Required', minWidth: 150, align: "center" },
        { id: 'key_model_combination_used', label: 'Key model combination used', minWidth: 150, align: "center" },
        { id: 'prompt_variant_id', label: 'Prompt Variant Id', minWidth: 150, align: "center" },
        { id: 'document_name', label: 'Document Name', minWidth: 150, align: "center" },
        { id: 'document_persona_id', label: 'Document Persona Id', minWidth: 150, align: "center" },
        { id: 'document_html_body', label: 'Document HTML Body', minWidth: 150, align: "center" },
        { id: 'user_type_public_flag', label: 'User Type Public Flag', minWidth: 150, align: "center" },
        { id: 'user_id', label: 'Public User Id', minWidth: 150, align: "center" },
        { id: 'user_first_name', label: ' User First Name', minWidth: 150, align: "center" },
        { id: 'user_last_name', label: ' User Last Name', minWidth: 150, align: "center" },
        { id: 'route_source_name', label: 'Route Source Name', minWidth: 150, align: "center" },
        { id: 'flag_america', label: 'flag America', minWidth: 150, align: "center" },
        { id: 'prompt_id', label: 'Prompt Id', minWidth: 150, align: "center" },
        { id: 'model_id', label: 'Model Id', minWidth: 150, align: "center" },
        { id: 'document_id', label: 'Document Id', minWidth: 150, align: "center" },
        { id: 'route_source_id', label: 'Route Source Id', minWidth: 150, align: "center" },


    ];
    // const moment = require('moment-timezone');
    // function convertUTCtoIST(dateTimeStringUTC) {
    //     if (dateTimeStringUTC !== "") {
    //         // Parse the input date-time as UTC
    //         const momentUTC = moment.utc(dateTimeStringUTC, "YYYY-MM-DD HH:mm:ss");

    //         // Convert the moment instance to IST
    //         const momentIST = momentUTC.tz("Asia/Kolkata");

    //         // Format the IST date-time in the desired format
    //         return momentIST.format("YYYY-MM-DD HH:mm:ss");
    //     } else {
    //         return "";
    //     }
    // }

    return (
        <>
            <Grid item>
                <Grid container>
                    <Paper sx={{
                        maxWidth: "100%",
                    }}>
                        <TableContainer className={`${globalStyles.tableContainerStyle} ${promptStyle.scrollBarHeight}`} sx={{ maxHeight: "calc(100vh - 280px)" }}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {initialColumns.map((column) => (
                                            <TableCell
                                                className={promptStyle.tablecellStyle}
                                                size={'small'}
                                                key={column.id}
                                                align={column.align}
                                                style={{ minWidth: column.minWidth, maxWidth: `${column.minWidth} !important` }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {customerPromptLogDetails !== null && customerPromptLogDetails
                                        .map((row, rowIndex) => (
                                            <TableRow
                                                key={rowIndex}
                                                onClick={() => { setShowRowDetail(true); setCurrentRowToShow({ ...currentRowToShow, rowData: row, rowIndex: rowIndex }); }} sx={{ background: currentRowToShow.rowIndex == rowIndex ? "#FBF4FF" : "" }} hover role="checkbox" tabIndex={-1} key={rowIndex}>
                                                {initialColumns.map((column) => {
                                                    const value = (column.id == "chatgpt_response" ? (row.log_data.length > 0 ? row.log_data.map((prompt) => prompt.prompt_log_data_response_value) : null) : row[column.id]) || (column.id === "created_at_timestamp_IST" ? convertUTCtoIST(row["created_at_timestamp"]) : row[column.id]);
                                                    return (
                                                        <TableCell
                                                            className={`${globalStyles.cellStyle} ${promptStyle.tablecellStyle}`}
                                                            style={{ minWidth: column.minWidth, maxWidth: `${column.minWidth} !important`, width: "100px" }}
                                                            key={column.id} align={column.align}>
                                                            {/* {(value !== null && (Array.isArray(value) ? value.length !== 0 : value !== "")) ? value.toString() : "-"} */}
                                                            {(value !== null && (Array.isArray(value) ? value.length !== 0 : value !== "")) ? (
                                                                <span title={value}>
                                                                    {(value !== null && typeof (value) == "string" && value.length > 40) ? `${value.slice(0, 40)}...` : JSON.stringify(value)}
                                                                </span>
                                                            ) : (
                                                                "-"
                                                            )}
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[200]} // Only allow 200 rows per page
                            component="div"
                            count={totalRowCount} // Total number of rows
                            rowsPerPage={200} // Always set to 200, as per your requirement
                            page={page_number - 1} // Adjust page number to be 0-based
                            onPageChange={(event, newPage) => {
                                handleChangePage(200, newPage + 1); // Adjust page number to be 1-based
                            }}
                        />
                    </Paper>
                </Grid>
            </Grid>
        </>
    )
}, (prevProps, nextProps) => {
    if (JSON.stringify(prevProps.customerPromptLogDetails) === JSON.stringify(nextProps.customerPromptLogDetails) && JSON.stringify(prevProps.currentRowToShow) === JSON.stringify(nextProps.currentRowToShow)) {
        // console.log("Props are deeply equal, returning true, no rerender required");
        return true;
    }
    else {
        // console.log("Props are not equal, returning false");
        return false;
    }
}
)
export default PromptLogsV2Table;
