import * as React from "react";
import { Grid } from '@mui/material';
import { useSearchParams } from "react-router-dom";
import agent from "../../api/api";
import GlobalDataGrid from "../../components/globalDataGrid";
import { UserContext } from "../../context/userContext";
import { PromptContext } from "../../context/promptContext";
import GlobalButton from "../../components/button";
import PreviewLoader from "./previewLoader";
import CreateLoaderDetail from "./createLoaderDetailsForm";
import AddImageToGoogleStorage from "./AddImageToGoogleStorageForm";
import { GenericDataContext } from "../../context/GenericData";

let defaultImageGroupAssignmentData = {
    image_group_id: null,
    image_group_assignment_data: [
        {
            image_group_assignment_id: null,
            background_image_google_storage_id: null,
            icon_image_google_storage_id: null,
            image_heading: "",
            image_description: "",
            cta_button_text: "",
            cta_button_icon_google_storage_id: null,
            cta_button_link: "",
            cta_button_related_field: "",
            module_type_id: null,
            loader_user_type_id: [],
        }
    ]
}
const LoaderDetails = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { setSuccess, success, setError, error } = React.useContext(UserContext)
    const { GetModelGenericList, GetOutputGenericData } = React.useContext(PromptContext);
    const { imageGroupData,
        getImageGroupData } = React.useContext(GenericDataContext)
    const [createImageGroupAssignmentData, setCreateImageGroupAssignmentData] = React.useState(defaultImageGroupAssignmentData)

    const handleSetImageGroupData = (params) => {
        setCreateImageGroupAssignmentData({
            image_group_id: params.row.image_group_id,
            image_group_assignment_data: [
                {
                    image_group_assignment_id: params.row.image_group_assignment_id,
                    background_image_google_storage_id: params.row.background_image_google_storage_id,
                    icon_image_google_storage_id: params.row.icon_image_google_storage_id,
                    image_heading: params.row.image_heading,
                    image_description: params.row.image_description,
                    cta_button_text: params.row.cta_button_text,
                    cta_button_icon_google_storage_id: params.row.cta_button_icon_google_storage_id,
                    cta_button_link: params.row.cta_button_link,
                    cta_button_related_field: params.row.cta_button_related_field,
                    module_type_id: params.row.module_type_id,
                    loader_user_type_id: params.row.loader_user_type_data.map((user) => user.loader_user_type_id),
                    icon_image_url: params.row.icon_image_url ?? null
                }
            ]
        })
    }
    const deleteImageGroupAssignment = async (params) => {
        const response = await agent.deleteImageGroupAssignment.delete(params.row.image_group_assignment_id, params.row.image_group_id);
        if (response.status) {
            setSuccess({
                ...success,
                flagShowSuccess: response.status,
                message: response.data.message,
            });
            getImageGroupData()
        } else {
            setError({ ...error, flagShowError: true, message: response.error.message })

        }
    }

    React.useEffect(() => {
        (async () => {
            if (searchParams.has('prompt_id')) {
                await GetOutputGenericData();
                await GetModelGenericList();
            }
        })()
    }, [])


    const imageGroupAssignmentDataColumns = [
        { field: 'image_group_id', headerName: 'Image Group Id', width: 150 }, // Hidden ID column for selection
        { field: 'image_group_name', headerName: 'Image Group Name', width: 125 },
        { field: 'loader_image_group_flag', headerName: 'Loader Flag', width: 125 },
        { field: 'image_group_assignment_id', headerName: 'Image Group Assignment Id', width: 125 },
        { field: 'module_type_name', headerName: 'Module Type Name', width: 125 },
        { field: 'loader_user_type_name', headerName: 'Loader User Type Name', width: 125 },
        {
            field: 'update_prompt_variant', headerName: 'View/Update', width: 100,
            renderCell: (params) => {
                return (
                    <strong>
                        <GlobalButton
                            buttonText="View"
                            className="tableButtonStyle"
                            onClick={() => {
                                handleSetImageGroupData(params)
                            }}
                        />
                    </strong>
                )
            },
        },
        {
            field: 'delete_image_group_assignment', headerName: 'Delete', width: 100,
            renderCell: (params) => {
                return (
                    <strong>
                        <GlobalButton
                            buttonText="Delete"
                            className="tableButtonStyle"
                            onClick={() => {
                                deleteImageGroupAssignment(params)
                            }}
                        />
                    </strong>
                )
            },
        }
    ];
    
    const createLoaderDetail = async () => {
        // debugger;
        const modifiedData = {
            ...createImageGroupAssignmentData, image_group_assignment_data: createImageGroupAssignmentData.image_group_assignment_data.map(({ image_group_assignment_id, ...rest }) => {
                return rest
            })
        }
        const response = await agent.createImageGroupAssignment.post(modifiedData);
        if (response.status) {
            setSuccess({
                ...success,
                flagShowSuccess: response.status,
                message: response.data.message,
            });
            getImageGroupData()
        } else {
            setError({ ...error, flagShowError: true, message: response.error.message })

        }
    }
    const updateLoaderDetail = async () => {
        const response = await agent.updateImageGroupAssignment.put(createImageGroupAssignmentData);
        if (response.status) {
            setSuccess({
                ...success,
                flagShowSuccess: response.status,
                message: "Data updated Successfully",
            });
            getImageGroupData()
        } else {
            setError({ ...error, flagShowError: true, message: response.error.message })

        }
    }
    const resetImageGroupAssignmentData = () => {
        setCreateImageGroupAssignmentData(defaultImageGroupAssignmentData)
    }
    return (
        <>

            <Grid container gap={"15px"} justifyContent={"space-between"} sx={{ flexWrap: "nowrap" }}>
                {/* prompt property list */}
                {/* left block */}
                <Grid item xs={4}>
                    <Grid container direction={"column"} justifyContent={"space-between"} sx={{ flexWrap: "nowrap" }}>
                        {/* image details table */}
                        <Grid item>
                            <div style={{ marginTop: "10px" }}>
                                <AddImageToGoogleStorage />
                            </div>
                        </Grid>
                        {/* loader preview */}
                        <Grid item sx={{ marginTop: "20px" }} >
                            <Grid container alignContent={"start"} direction={"column"}>
                                <Grid item>PREVIEW</Grid>
                                <Grid item>
                                    <PreviewLoader
                                        btnName={createImageGroupAssignmentData.image_group_assignment_data[0].cta_button_text}
                                        image_description={createImageGroupAssignmentData.image_group_assignment_data[0].image_description}
                                        icon={createImageGroupAssignmentData.image_group_assignment_data[0].icon_image_url}
                                        // <div>Try our 'Blog Post Ideas' template for instant content inspiration and get unique angles on your topic.</div>}
                                        image_heading={createImageGroupAssignmentData.image_group_assignment_data[0].image_heading}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>


                </Grid>

                {/* right block */}
                <Grid item xs={7.5}>
                    <Grid container direction="column" sx={{ flexWrap: "nowrap" }}>
                        {/* loader details list */}
                        <Grid item>
                            <GlobalDataGrid rows={imageGroupData} columns={imageGroupAssignmentDataColumns} hideFooter={false} hideFooterPagination={false} hideFooterSelectedRowCount={true} showSelectedRowDetails={true} refresh={() => { }} />
                            <div style={{ marginTop: "10px" }}>
                                {createImageGroupAssignmentData.image_group_assignment_data[0].image_group_assignment_id ?
                                    <GlobalButton buttonText="Add Loader Detail" className={"borderedButtonStyle"} onClick={resetImageGroupAssignmentData} /> : <></>}
                            </div>
                        </Grid>
                        {/* loader detail form */}
                        <Grid item sx={{ marginTop: "20px" }}>
                            <Grid container direction={"column"}>
                                <div>
                                    <CreateLoaderDetail createImageGroupAssignmentData={createImageGroupAssignmentData} setCreateImageGroupAssignmentData={setCreateImageGroupAssignmentData} createLoaderDetail={createLoaderDetail} updateLoaderDetail={updateLoaderDetail} />
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>


            </Grid>
        </>
    )
}

export default LoaderDetails;