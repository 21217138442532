import { Grid } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../context/userContext';
import { useNavigate } from 'react-router-dom';
import DescriptionIcon from '@mui/icons-material/Description';
import styles from "../assets/styles/template.module.scss"
import globalStyles from "../assets/styles/global.module.scss"
import youtubeIcon from "../assets/youtube.svg";
import facebookIcon from "../assets/facebook.svg";
import googleIcon from "../assets/google.svg";
import blogIcon from "../assets/blog.svg";
import addIcon from "../assets/add.svg";
import agent from '../api/api';
import GlobalButton from '../components/button';
import { checkError } from '../utils/constants';

const featuredTemplateList = [
    {
        templateName: "Facebook Ads",
        imgSrc: facebookIcon
    },
    {
        templateName: "Google Ads",
        imgSrc: googleIcon
    },
    {
        templateName: "Youtube Video Ideas",
        imgSrc: youtubeIcon
    },
    {
        templateName: "Blog Post Ideas",
        imgSrc: blogIcon
    },
]
const categorylist = [
    {
        categoryName: "Story/Shorts/Reel Hook & Script Content",
        categoryId: 1,
        categoryDescription: "Lorem ipsum dolor sit amet consectetur. Molestie at gravida enim cursus. Pellentesque dui lobortis in fusce odio.",
        templateList: [
            {
                templateId: 0,
                imgSrc: DescriptionIcon,
                templateName: "Story/Shorts/Reel Hook & Script",
                templateDescription: "Lorem ipsum dolor sit amet consectetur. ullamcorperurna vitae augue. Porttitor cras lorem sit duis"
            },
            {
                templateId: 1,
                imgSrc: DescriptionIcon,
                templateName: "Story Content",
                templateDescription: "Lorem ipsum dolor sit amet consectetur. ullamcorperurna vitae augue. Porttitor cras lorem sit duis"
            },
            {
                templateId: 2,
                imgSrc: DescriptionIcon,
                templateName: "Reel Hook Content",
                templateDescription: "Lorem ipsum dolor sit amet consectetur. ullamcorperurna vitae augue. Porttitor cras lorem sit duis"
            },
        ]
    },
    {
        categoryName: "Google Ads",
        categoryId: 2,
        categoryDescription: "Lorem ipsum dolor sit amet consectetur. Molestie at gravida enim cursus. Pellentesque dui lobortis in fusce odio.",
        templateList: [
            {
                templateId: 1,
                imgSrc: DescriptionIcon,
                templateName: "Story/Shorts/Reel Hook & Script",
                templateDescription: "Lorem ipsum dolor sit amet consectetur. ullamcorperurna vitae augue. Porttitor cras lorem sit duis"
            },
            {
                templateId: 1,
                imgSrc: DescriptionIcon,
                templateName: "Story Content",
                templateDescription: "Lorem ipsum dolor sit amet consectetur. ullamcorperurna vitae augue. Porttitor cras lorem sit duis"
            },
            {
                templateId: 1,
                imgSrc: DescriptionIcon,
                templateName: "Reel Hook Content",
                templateDescription: "Lorem ipsum dolor sit amet consectetur. ullamcorperurna vitae augue. Porttitor cras lorem sit duis"
            },
        ]
    },
    {
        categoryName: "Facebook Ads",
        categoryId: 3,
        categoryDescription: "Lorem ipsum dolor sit amet consectetur. Molestie at gravida enim cursus. Pellentesque dui lobortis in fusce odio.",
        templateList: [
            {
                templateId: 1,
                imgSrc: DescriptionIcon,
                templateName: "Story/Shorts/Reel Hook & Script",
                templateDescription: "Lorem ipsum dolor sit amet consectetur. ullamcorperurna vitae augue. Porttitor cras lorem sit duis"
            },
            {
                templateId: 2,
                imgSrc: DescriptionIcon,
                templateName: "Story Content",
                templateDescription: "Lorem ipsum dolor sit amet consectetur. ullamcorperurna vitae augue. Porttitor cras lorem sit duis"
            },
            {
                templateId: 3,
                imgSrc: DescriptionIcon,
                templateName: "Reel Hook Content",
                templateDescription: "Lorem ipsum dolor sit amet consectetur. ullamcorperurna vitae augue. Porttitor cras lorem sit duis"
            },
        ]
    }
]
const categories = ['All Templates', 'Story/Shorts/Reel Hook & Script Content', 'Google Ads', 'Facebook Ads', 'Social Media Post Captions & Hashtags', 'Blog Post Ideas']
const Template = () => {
    const navigate = useNavigate()
    const { setFlagLoading, setError, error } = useContext(UserContext)
    const [flagShowRecaptchaPopUp, setFlagShowRecaptchaPopUp] = useState(false)
    const [currentClickedCategoryId, setCurrentClickedCategoryId] = useState(-1);
    const [templateList, setTemplateList] = useState(null)

    useEffect(() => {
        (async () => {
            const response = await agent.GetTemplateList.get();
            if (response.status) {
                setTemplateList(response.data)
            }
            else{
                setError({ ...error, flagShowError: true, message: checkError(response) })
            }
            setFlagLoading(false)
        })()
    }, [])



    return (
        <>

            <Grid item className={`${globalStyles.fullwidth} ${styles.templateWrapper}`}>
                <Grid container direction={"column"} alignItems={"center"} justifyContent={"start"} className={globalStyles.dashboardChildWrapper}>
                    {/* title header */}
                    <Grid item className={`${globalStyles.fullwidth} ${styles.templateSearchbarStyle}`}>
                        <Grid container justifyContent={"space-between"}>
                            <Grid item className={globalStyles.dashboardChildTitleTextStyle}>Templates</Grid>
                            <Grid item>
                                {/* <GlobalSearchBar data={data} onSearch={handleSearch} /> */}
                            </Grid>
                            <Grid item>
                                <GlobalButton
                                    buttonText="Create Dynamic Template"
                                    className="secondaryButtonStyle"
                                    flagMoveForward
                                    onClick={() => { navigate("/dashboard/templates/createTemplate") }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* featured list */}
                    <Grid item className={globalStyles.fullwidth}>
                        <Grid container direction="column">
                            {/* <Grid item className={globalStyles.dashboardChildSectionTextStyle}>Featured</Grid> */}
                            <Grid item pt={2}>
                                <Grid container className={`${styles.featuredTilesWrapper}`}>
                                    <Grid item className={`${globalStyles.clickable} ${styles.featuredTemplateTileStyle}`}>
                                        <Grid container alignItems={"center"}>
                                            <Grid item>
                                                <img src={addIcon} className={styles.featureIconStyle} />
                                            </Grid>
                                            <Grid item>New Document</Grid>
                                        </Grid>
                                    </Grid>
                                    {templateList !== null && templateList[0].featured_list.map((featuredTemplate) => (
                                        <>
                                            <Grid item className={`${globalStyles.clickable} ${styles.featuredTemplateTileStyle}`}>
                                                <Grid container alignItems={"center"}>
                                                    <Grid item>
                                                        <img src={featuredTemplate.template_icon_url == null ? "" : featuredTemplate.template_icon_url} className={styles.featureIconStyle} />
                                                    </Grid>
                                                    <Grid item>{featuredTemplate.template_name}</Grid>
                                                </Grid>
                                            </Grid>
                                        </>
                                    ))
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* category list */}
                    <Grid item className={`${globalStyles.fullwidth} ${styles.categoryListWrapper}`}>
                        <Grid container direction="column">
                            <Grid item className={globalStyles.dashboardChildSectionTextStyle}>Categories</Grid>
                            <Grid item>
                                <Grid container >
                                    <Grid item className={currentClickedCategoryId == -1 ? `${styles.selectedCategoryTileStyle} ${globalStyles.clickable}` : `${styles.categoryTileStyle} ${globalStyles.clickable}`}>
                                        <Grid container alignItems={"center"}>
                                            <Grid item onClick={() => { setCurrentClickedCategoryId(-1) }}>All Templates</Grid>
                                        </Grid>
                                    </Grid>
                                    {templateList !== null && templateList[0].category_list.map((category) => (
                                        category.template_data_list.length > 0 &&
                                        <>
                                            <Grid item className={currentClickedCategoryId == category.category_id ? `${styles.selectedCategoryTileStyle} ${globalStyles.clickable}` : `${styles.categoryTileStyle} ${globalStyles.clickable}`}>
                                                <Grid container alignItems={"center"} pb={0} mb={0}>
                                                    <Grid item onClick={() => { setCurrentClickedCategoryId(category.category_id) }}>
                                                        {category.category_name}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </>
                                    ))
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* template list */}
                    <Grid item className={globalStyles.fullwidth}>
                        <Grid container>
                            {templateList && templateList[0].category_list
                                .filter(
                                    (category) =>
                                        currentClickedCategoryId === -1 ||
                                        category.category_id === currentClickedCategoryId
                                )
                                ?.map((category) => (
                                    <>
                                        {category.template_data_list.length > 0 &&
                                            <Grid item xs={12}>
                                                <Grid container direction={"column"}>
                                                    <Grid item className={styles.categoryNameStyle}>
                                                        {category.category_name}
                                                    </Grid>
                                                    <Grid item className={styles.categoryDescriptionStyle}>
                                                        {category.category_description}
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container>
                                                            {category.template_data_list.map((template) => (
                                                                template.template_icon_url &&
                                                                <Grid item className={`${styles.templateTileStyle} ${globalStyles.clickable}`}>
                                                                    <Grid container direction="column">
                                                                        <Grid item className={styles.templateIconStyle}>
                                                                            <img src={template.template_icon_url} />
                                                                        </Grid>
                                                                        <Grid item className={styles.templateTitleTextstyle}>
                                                                            {template.template_name}
                                                                        </Grid>
                                                                        <Grid item className={styles.templateDescriptionTextStyle}>
                                                                            {template.template_description?.length > 104 ? template.template_description?.substring(0, 104) + "..." : template.template_description}
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>

                                                            ))}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        }
                                    </>
                                ))}
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>
        </>
    )
}

export default Template;