import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SideNavigationPanel from "../components/sideNavigationPanel";
import home from "../assets/home.svg";
import homeWhite from "../assets/homeWhite.svg";
import promptLogs from "../assets/promptLogs.svg";
import promptLogsWhite from "../assets/promptLogsWhite.svg";
import prompts from "../assets/prompts.svg";
import promptsWhite from "../assets/promptsWhite.svg";
import createPrompt from "../assets/createPrompt.svg";
import createPromptWhite from "../assets/createPromptWhite.svg";
import templates from "../assets/templates.svg";
import templatesWhite from "../assets/templatesWhite.svg";
import { Grid } from '@mui/material';
import { Outlet } from 'react-router-dom';
import globalStyles from "../assets/styles/global.module.scss";
import { UserContext } from '../context/userContext';
import Header from '../components/header';
import User from './User/User';
import userWhite from '../assets/UserWhiteIcon.png'
import userBlack from '../assets/UserBlackIcon.png'


const drawerWidth = 240;
const menuItems = [
    {
        menuName: "Existing Customers",
        menuKey: "existingcustomers",
        imgSrc: home,
        whiteImgSrc: homeWhite,
        navigationLink: "/dashboard/existingCustomers"
    },
    {
        menuName: "Prompt Logs",
        menuKey: "promptLogs",
        imgSrc: promptLogs,
        whiteImgSrc: promptLogsWhite,
        navigationLink: "/dashboard/promptLogs",
    },
    {
        menuName: "V2-Prompt Logs",
        menuKey: "promptLogsV2",
        imgSrc: promptLogs,
        whiteImgSrc: promptLogsWhite,
        navigationLink: "/dashboard/promptLogsV2",
    },
    {
        menuName: "Prompts",
        menuKey: "prompts",
        imgSrc: prompts,
        whiteImgSrc: promptsWhite,
        navigationLink: "/dashboard/prompts"
    },
    {
        menuName: "Create Prompt",
        menuKey: "createPrompt",
        imgSrc: createPrompt,
        whiteImgSrc: createPromptWhite,
        navigationLink: "/dashboard/prompts/createPrompt"
    },

    {
        menuName: "Templates",
        menuKey: "templates",
        imgSrc: templates,
        whiteImgSrc: templatesWhite,
        navigationLink: "/dashboard/templates"
    },
    {
        menuName: "User",
        menuKey: "user",
        imgSrc: userBlack,
        whiteImgSrc: userWhite,
        navigationLink: "/dashboard/user"
    },
    {
        menuName: "Loader Details",
        menuKey: "loaderDetails",
        imgSrc: userBlack,
        whiteImgSrc: userWhite,
        navigationLink: "/dashboard/loaderDetails"
    },
]
export default function DevDashboard() {
    const { userDetail, setFlagLoading } = React.useContext(UserContext);
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    return (
        <>
            <Grid container>
                <Grid item sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}>
                    <SideNavigationPanel
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        menuItems={menuItems}
                    />
                </Grid>
                <Grid item sx={{ width: { sm: `calc(100% - ${drawerWidth}px)` } }}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        className={globalStyles.dashboardNavigationToggleButtonStyle}
                        sx={{ mr: 2, display: { sm: 'none' } }}

                    >
                        <MenuIcon />
                    </IconButton>

                    <Grid container className={globalStyles.containerflexWrapStyle} sx={{ justifyContent: "space-between" }} direction={"column"}>
                        <Grid item>
                            <Header flagShowLogo={false} />
                        </Grid>
                        <Grid item sx={{ height: "calc(100vh - 64px)" }}>
                            <Grid className={globalStyles.dashboardOutletWrapper} container>
                                <Outlet />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}
