import * as React from "react";
import { Box, Grid, InputAdornment, InputLabel, TextField } from '@mui/material';
import CustomDataGridTable from "../components/customizableDataGrid";
import agent from "../api/api";
import RoundLoader from "../components/roundLoader";
import globalStyles from "../assets/styles/global.module.scss";
import UpdateIcon from '@mui/icons-material/Update';
import GlobalSearch from "../utils/globalSearch";
import { UserContext } from "../context/userContext";
import { checkForErrorCode, getErrorMessage } from "../utils/constants";
import RefreshIcon from '@mui/icons-material/Refresh';

const PromptLogs = () => {
    const [promptListData, setPromptListData] = React.useState(null);
    const [showLoader, setShowLoader] = React.useState(false)
    const [logCount, setLogCount] = React.useState(50);
    const [rowFilteredDetails, setRowFilteredDetails] = React.useState(null);
    const [filterValues, setFilterValues] = React.useState(null);
    const { setError, error } = React.useContext(UserContext);
    const getPromptListData = async (count) => {
        setShowLoader(true)
        const response = await agent.GetPromptList.get(count);
        setShowLoader(false)
        if (response.status) {
            if (response.data) {
                setPromptListData(response.data)
                setFilterValues("")
            }
        }
        else {
            let errorCode = checkForErrorCode(response)
            setError({ ...error, flagShowError: true, message: getErrorMessage(errorCode) })
        }
    }
    React.useEffect(() => {
        getPromptListData(50)
    }, [])
    const handleLogCountChange = (e) => {
        setLogCount(e.target.value)
    }

    const handleSearch = (event) => {
        setFilterValues(event.target.value);
        const query = event.target.value.toLowerCase();
        if(Array.isArray(promptListData)) {
            const rowFilterData = promptListData.filter((data) => {
                return (
                    (data.prompt_name !== null && data.prompt_name.toLowerCase().includes(query)) ||
                    (data.user_name !== null && data.user_name.toLowerCase().includes(query)) ||
                    (data.user_email !== null && data.user_email.toLowerCase().includes(query)) ||
                    (data.model_name !== null && data.model_name.toLowerCase().includes(query)) ||
                    (data.created_at_timestamp !== null && new Date(data.created_at_timestamp).toLocaleString().includes(query))
                );
            });
            setRowFilteredDetails(rowFilterData);
        }
    }

    const handleClearSearch = () => {
        setFilterValues("");
        setRowFilteredDetails(null);
    }

    return (
        <>{
            showLoader && <RoundLoader />
        }
            <Grid container className={globalStyles.containerflexWrapStyle} direction={"column"}>
                <Grid item className={globalStyles.searchBarFieldGrid}>
                    <InputLabel className={globalStyles.LogCountInputField}>Log Count</InputLabel>
                    <TextField value={logCount} onChange={handleLogCountChange} className={globalStyles.logCountField} InputProps={{
                        endAdornment: (
                            <InputAdornment className={globalStyles.clickable} position="end">
                                <UpdateIcon onClick={(e) => { getPromptListData(logCount) }} />
                            </InputAdornment>
                        ),
                    }} />
                    <GlobalSearch placeholder="Search Prompt Log" value={filterValues} onChange={(e) => {handleSearch(e)}} onClose={handleClearSearch} />
                    <Box display={"flex"} justifyContent={"end"} pb={"5px"}>
                        <RefreshIcon onClick={() => { getPromptListData(logCount) }} className={globalStyles.clickable} />
                    </Box>
                </Grid>
                <Grid item>
                    <CustomDataGridTable refresh={() => { getPromptListData(logCount) }} data={promptListData} filteredRows={rowFilteredDetails} title="Recent Prompts" />
                </Grid>
            </Grid>

        </>
    )
}

export default PromptLogs;