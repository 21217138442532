import React, { useState } from "react";
import agent from "../api/api";

const GenericDataContext = React.createContext();

const GenericDataProvider = ({ children }) => {
    const [loaderUserTypeDataList, setLoaderUserTypeDataList] = useState([])
    const [moduleDataList, setModuleDataList] = useState([])
    const [imageGroupData, setImageGroupData] = useState([])
    const getModuleDataList = async () => {
        const response = await agent.getModuleDataList.get();
        if (response.status) {
            setModuleDataList(response.data)
        } else {
            setModuleDataList([])
        }
    }
    const getLoaderUserTypeDataList = async () => {
        // debugger;
        const response = await agent.getLoaderUserTypeDataList.get();
        if (response.status) {
            setLoaderUserTypeDataList(response.data)
        } else {
            setLoaderUserTypeDataList([])
        }
    }
    const getImageGroupData = async () => {
        // debugger;
        const response = await agent.getImageGroups.get();
        if (response.status) {
            console.log("response", response)
            // let temp = response.data.flatMap(group => group.image_group_assignment_data.map((data, index) => ({
            //     ...data,
            //     image_group_id: group.image_group_id,
            //     image_group_name: group.image_group_name,
            //     loader_image_group_flag: group.loader_image_group_flag,
            //     id: index // Add the index as an id
            // })));
            let temp = response.data.flatMap(group => group.image_group_assignment_data.map((data, index) => {
                const { loader_user_type_data, ...rest } = data
                return {
                    ...rest,
                    image_group_id: group.image_group_id,
                    image_group_name: group.image_group_name,
                    loader_image_group_flag: group.loader_image_group_flag,
                    loader_user_type_name: loader_user_type_data.map((loaderUserTypeName) => loaderUserTypeName.loader_user_type_name).join(','),
                    loader_user_type_data: data.loader_user_type_data,
                    id: index // Add the index as an id
                }
            }));
            setImageGroupData(temp)
        } else {
            setImageGroupData([])
        }
    }
    const payload = {
        loaderUserTypeDataList,
        setLoaderUserTypeDataList,
        getLoaderUserTypeDataList,
        moduleDataList,
        setModuleDataList,
        getModuleDataList,
        imageGroupData,
        setImageGroupData,
        getImageGroupData
    };

    return (
        <GenericDataContext.Provider value={payload}>{children}</GenericDataContext.Provider>
    );
};

export { GenericDataContext, GenericDataProvider };
