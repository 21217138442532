import { FormControlLabel, Grid, InputLabel, MenuItem, Select, Switch, TextField, TextareaAutosize, Typography } from '@mui/material';
import { useEffect, useRef, useState, useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import DescriptionIcon from '@mui/icons-material/Description';
import styles from "../assets/styles/customTemplate.module.scss"
import globalStyles from "../assets/styles/global.module.scss";
import GlobalButton from '../components/button';
import TemplateFormPreview from './templateFormPreview';
import CreatableSelect from 'react-select/creatable';
import { MixedTags } from "@yaireo/tagify/dist/react.tagify"
import MixedModeTagify from '../utils/mixedTag';
import DraggableList from '../utils/customTemplateDrag&Drop';
import DraggableListComponent from '../utils/customTemplateDrag&Drop';
import { TemplateContext } from '../context/templateContext';
import agent from '../api/api';
import ImportFromFormwiz from '../components/importFromFormwizPopup';
import GlobalPopUp from '../utils/globalPopUp';
import MixedModeTagifyContext from '../utils/mixedTagContext';


const CustomTemplate = () => {
    const navigate = useNavigate()
    const { templateFormFieldList,
        setTemplateFormFieldList, promptActionData, setPromptActionData, templateFormFieldsGenericData, promptContextData,
        setPromptContextData } = useContext(TemplateContext);
    const [flagShowForm, setFlagShowForm] = useState(false)
    const [templateDescriptionVal, setTemplateDescriptionVal] = useState("")
    const [flagImportFromFormwiz, setFlagImportFromFormwiz] = useState();
    const [importedJSONData, setImportedJSONData] = useState();
    const [formwizFieldsData, setFormwizFieldsData] = useState({
        data: {
            widget_form_full_width: true,
            global_form_field: [
                {
                    page_field_id: 5454,
                    label: 'Input',
                    placeholder: 'Add Input Field',
                    required: false,
                    name: '5454_Input',
                    type: 'text',
                    value: '',
                    hidden: false,
                    position: null,
                    font_color: null,
                    col: 12
                },
            ],
            widget_flag_auto_save: false,
            widget_flag_hide_button: false,
            doc_page_theme: [
                {
                    "page_id": "682",
                    "page_theme_font_type": "Open Sans",
                    "page_theme_font_style": "normal",
                    "page_theme_font_align": "left",
                    "page_theme_font_weight": "bold",
                    "page_theme_font_size": "15",
                    "page_theme_background_color": "#FFFFFF",
                    "page_theme_heading_color": "#EFE4F1",
                    "page_theme_text_decoration": "unset",
                    "page_hide_button": false,
                    "page_auto_save": false,
                    "page_font_spacing": null,
                    "page_theme_row_spacing": 2,
                    "page_theme_column_spacing": 2
                }
            ]
        }
    });
    const [templateFormDetails, setTemplateFormDetails] = useState({
        category_id: 10,
        template_name: "",
        flag_show_field: true,
        template_icon_url: "",
        template_description: "",
        template_visibility_type_name: "Private",
        template_prompt_data: {
            prompt_action_data: "",
            prompt_context_data: "",
            prompt_num_of_output: null, //only for dev portal
            prompt_response_format: "" //only for dev portal
        },
        template_step_form_data: [
            {
                template_form_name: "",
                template_form_button_name: "",
                template_form_field_data: [
                    {
                        template_form_field_id: "ud_12",
                        form_field_input_type_id: 0,
                        template_form_field_seq_num: 0,
                        template_form_field_property: [
                            {
                                form_field_input_type_property_id: 0,
                                template_form_field_property_value: "",
                            }
                        ]
                    }
                ]
            }
        ],

    })
    const [lastUpdatedTemplateFormDetails, setLastUpdatedTemplateFormDetails] = useState()
    const [executePromptResponse, setExecutePromptResponse] = useState(null);

    const updateTemplateFormFields = (selectedValues) => {
        // Filter fields that exist in masterValues
        const filteredFields = formwizFieldsData.data.global_form_field.filter(field => {
            return selectedValues.some(selectedValue => selectedValue.page_field_id === field.page_field_id);
        });

        // Update formwizFieldsData with filtered fields
        setFormwizFieldsData(prevFormFields => ({
            ...prevFormFields,
            data: {
                ...prevFormFields.data,
                global_form_field: filteredFields,
            },
        }));
    };

    const addNewField = (newValue) => {
        // Generate a new page_field_id for the new field
        const lastField = formwizFieldsData.data.global_form_field[formwizFieldsData.data.global_form_field.length - 1];
        const newPageFieldId = lastField ? lastField.page_field_id + 1 : 1;

        // Create a new field object and add it to formwizFieldsData
        const newField = {
            page_field_id: newPageFieldId,
            label: newValue.label,
            placeholder: `Enter ${newValue.label}`,
            required: false,
            name: `${newPageFieldId}_${newValue.label}`,
            type: 'text',
            value: '',
            hidden: false,
            position: null,
            font_color: null,
            col: 12,
        };

        setFormwizFieldsData(prevFormFields => ({
            ...prevFormFields,
            data: {
                ...prevFormFields.data,
                global_form_field: [...prevFormFields.data.global_form_field, newField],
            },
        }));
    };
    const [masterValues, setMasterValues] = useState([])
    const [settings, setSettings] = useState({
        pattern: /@/, // <- must define "patten" in mixed mode
        dropdown: {
            enabled: 0,
            position: "text"
        },
        whitelist: []
    })
    console.log("masterValues", masterValues)

    useEffect(() => {
        // const options = formwizFieldsData.data.global_form_field.map(field => ({
        //     template: field.page_field_id,
        //     value: field.label,
        //     label: field.label
        // }));
        const options = templateFormFieldList.map(field => ({
            template_form_field_id: field.template_form_field_id,
            value: field.template_form_field_property.find(prop => prop.form_field_input_type_property_name === 'Title').template_form_field_property_value
        }));
        setMasterValues(options)
    }, [formwizFieldsData])
    // tagify

    // useEffect(() => {
    //     if (masterValues.length > 0) {
    //         updateTemplateFormFields(masterValues);
    //     }
    // }, [masterValues])
    // console.log("masterValues", masterValues)


    const handleMasterValuesChange = (newValue) => {
        if (newValue[newValue.length - 1].__isNew__) {
            addNewField(newValue[newValue.length - 1]);
            let lastValue = masterValues[masterValues.length - 1].page_field_id
            let updatedvalue = [...newValue];
            updatedvalue[updatedvalue.length - 1].page_field_id = lastValue + 1
            setMasterValues(updatedvalue);
        } else {
            updateTemplateFormFields(newValue)
            setMasterValues(newValue);
        }
        // Check if the new chip's value doesn't exist in global_form_field
        // const newValueValue = newValue[newValue.length - 1].value + 1;
        // if (!formwizFieldsData.data.global_form_field.some(field => field.page_field_id === newValueValue)) {
        //     // Add a new field and update masterValues
        //     addNewField(newValue[newValue.length - 1] + 1);
        //     setMasterValues(newValue);
        // } else {
        //     // If the value already exists, update masterValues
        //     setMasterValues(newValue);
        // }
    };
    // const [settings, setSettings] = useState({
    //     pattern: /@/, // <- must define "patten" in mixed mode
    //     dropdown: {
    //         enabled: 0,
    //         position: "text"
    //     },
    //     whitelist: masterValues.map((item, index) => ({ ...item, id: index }))
    // }
    // )
    // useEffect(() => {
    //     setSettings({
    //         pattern: /@/, // <- must define "patten" in mixed mode
    //         dropdown: {
    //             enabled: 0,
    //             position: "text"
    //         },
    //         whitelist: masterValues.map((item, index) => ({ ...item, id: index }))
    //     })
    // }, [masterValues])
    // console.log("settings", settings.whitelist)

    const [whiteListOptions, setWhiteListOptions] = useState([])
    // useEffect(() => {
    //     let options = masterValues.map((item, index) => ({
    //         page_field_id:`ud_${item.page_field_id}`,
    //         id: Number(index),
    //          value: `${item.value}test`, title: item.value }))
    //     setWhiteListOptions(options)
    //     setSettings({ ...settings, whitelist: options })
    // }, [masterValues])
    console.log("whiteListOptions", whiteListOptions)
    // const settings = {
    //     pattern: /@/, // <- must define "patten" in mixed mode
    //     dropdown: {
    //         enabled: 0,
    //         position: "text"
    //     },
    //     whitelist: whiteListOptions
    // }
    const handleTemplateDescSubmit = () => {
        setFlagShowForm(true);
    }

    // const transformToFormwizFormat = (arrayToTransform) => {
    //     console.log("arrayToTransform", arrayToTransform)
    //     debugger
    //     const outputArray = []
    //     arrayToTransform.forEach(item => {
    //         const pageFieldId = typeof item.template_form_field_id == "string" ? parseInt(item.template_form_field_id.replace('ud-', ''), 10) : item.template_form_field_id;
    //         const type = item.form_field_input_type_name;
    //         const labelItem = item.template_form_field_property.find(prop => prop.form_field_input_type_property_name === 'Title');
    //         const placeholderItem = item.template_form_field_property.find(prop => prop.form_field_input_type_property_name === 'Placeholder');
    //         const requiredItem = item.template_form_field_property.find(prop => prop.form_field_input_type_property_name === 'Is Required?');

    //         const label = labelItem ? labelItem.template_form_field_property_value : '';
    //         const placeholder = placeholderItem ? placeholderItem.template_form_field_property_value : '';
    //         const required = requiredItem ? requiredItem.template_form_field_property_value : false;

    //         outputArray.push({
    //             page_field_id: pageFieldId,
    //             label: label,
    //             placeholder: placeholder,
    //             required: required,
    //             name: `${pageFieldId}_${label.replace(/ /g, '_')}`,
    //             // type: 'text',
    //             type: type.replace(/ /gi, '').toLowerCase(),
    //             value: '',
    //             hidden: false,
    //             position: null,
    //             font_color: null,
    //             col: 12
    //         });
    //     });

    //     let tempData = { ...formwizFieldsData }
    //     tempData.data.global_form_field = outputArray
    //     setFormwizFieldsData(tempData)
    // }
    const transformToFormwizFormat = (arrayToTransform) => {
        console.log("arrayToTransform", arrayToTransform);
        const outputArray = [];
        if (true) {
            arrayToTransform.forEach(item => {
                const pageFieldId = typeof item.template_form_field_id == "string" ? parseInt(item.template_form_field_id.replace('ud-', ''), 10) : item.template_form_field_id;
                const type = item.form_field_input_type_name;
                const properties = {};

                item.template_form_field_property.forEach(prop => {
                    properties[prop.form_field_input_type_property_description] = prop.template_form_field_property_value;
                });
                console.log("properties", properties)
                const outputObject = {
                    page_field_id: pageFieldId,
                    // type: type.replace(/ /gi, '').toLowerCase(),
                    type: item.form_field_input_type_abbr,
                    name: `${pageFieldId}_${properties["label"].replace(/ /g, '_')}`,
                    value: properties["is_multiple"] ? [] : ""
                };

                // Copy other properties from properties object
                for (const key in properties) {
                    if (key !== "Options") { // Skip "Options"
                        outputObject[key.toLowerCase()] = properties[key];
                    }
                }

                // Handle "Options" property
                if (properties["options"]) {
                    const optionsArray = properties["options"] || [];
                    outputObject["options"] = Array.isArray(optionsArray)
                        ? optionsArray.map((option, optionIndex) => {
                            return {
                                "field_option_data_id": optionIndex,
                                "field_option_value": option.trim(),
                                "field_option_flag": false,
                            };
                        })
                        : [];
                }

                outputArray.push(outputObject);
            });
            console.log("outputArray", outputArray)
            let tempData = { ...formwizFieldsData };
            tempData.data.global_form_field = outputArray;
            setFormwizFieldsData(tempData);
        } else {
            const outputArray = []
            arrayToTransform.forEach(item => {
                const pageFieldId = typeof item.template_form_field_id == "string" ? parseInt(item.template_form_field_id.replace('ud-', ''), 10) : item.template_form_field_id;
                const type = item.form_field_input_type_name;
                const labelItem = item.template_form_field_property.find(prop => prop.form_field_input_type_property_name === 'Title');
                const placeholderItem = item.template_form_field_property.find(prop => prop.form_field_input_type_property_name === 'Placeholder');
                const requiredItem = item.template_form_field_property.find(prop => prop.form_field_input_type_property_name === 'Is Required?');

                const label = labelItem ? labelItem.template_form_field_property_value : '';
                const placeholder = placeholderItem ? placeholderItem.template_form_field_property_value : '';
                const required = requiredItem ? requiredItem.template_form_field_property_value : false;

                outputArray.push({
                    page_field_id: pageFieldId,
                    label: label,
                    placeholder: placeholder,
                    required: required,
                    name: `${pageFieldId}_${label.replace(/ /g, '_')}`,
                    // type: 'text',
                    type: type.replace(/ /gi, '').toLowerCase(),
                    value: '',
                    hidden: false,
                    position: null,
                    font_color: null,
                    col: 12
                });
            });

            let tempData = { ...formwizFieldsData }
            tempData.data.global_form_field = outputArray
            setFormwizFieldsData(tempData)
        }
    }

    useEffect(() => {
        transformToFormwizFormat(templateFormFieldList)

    }, [templateFormFieldList])
    console.log("settings", settings.whitelist)
    //for frontened after response from backend of create template

    const formatPromptActionData = (response) => {
        let modifiedPromptData = response.data[0].prompt_action_data
        const regex = /\{\{template_form_field_id:(\d+)\}\}/g;;
        let match;
        while ((match = regex.exec(modifiedPromptData)) !== null) {
            const templateFormFieldIdToFind = parseInt(match[1]);
            const matchingFormField = response.data[0].template_steps[0].template_step_form_data[0].template_form_field_data.find(field => field.template_form_field_id === templateFormFieldIdToFind);
            const propertyValueToInsert = matchingFormField ? matchingFormField.template_form_field_property_data.find(prop => prop.form_field_input_type_property_name === "Title")?.template_form_field_property_value : "";
            const replacement = `[[{template_form_field_id:${templateFormFieldIdToFind}, value:${propertyValueToInsert}}]]`;
            modifiedPromptData = modifiedPromptData.replace(match[0], replacement);
            // modifiedPromptData = modifiedPromptData.replace(`{\"template_form_field_id\":${templateFormFieldIdToFind}}`, `{\"template_form_field_id\":${templateFormFieldIdToFind}, "value": "${propertyValueToInsert}"}`);
        }
        console.log("modifiedPromptData", modifiedPromptData)


        let modifiedContextPromptData = response.data[0].template_prompt_data[0].prompt_context_data
        let matchContext;
        while ((matchContext = regex.exec(modifiedContextPromptData)) !== null) {
            const templateFormFieldIdToFind = parseInt(match[1]);
            const matchingFormField = response.data[0].template_steps[0].template_step_form_data[0].template_form_field_data.find(field => field.template_form_field_id === templateFormFieldIdToFind);
            const propertyValueToInsert = matchingFormField ? matchingFormField.template_form_field_property_data.find(prop => prop.form_field_input_type_property_name === "Title")?.template_form_field_property_value : "";
            const replacement = `[[{template_form_field_id:${templateFormFieldIdToFind}, value:${propertyValueToInsert}}]]`;
            modifiedContextPromptData = modifiedContextPromptData.replace(match[0], replacement);
            // modifiedPromptData = modifiedPromptData.replace(`{\"template_form_field_id\":${templateFormFieldIdToFind}}`, `{\"template_form_field_id\":${templateFormFieldIdToFind}, "value": "${propertyValueToInsert}"}`);
        }
        console.log("modifiedPromptData", modifiedContextPromptData)
        let requiredPromptContextData = modifiedContextPromptData && modifiedContextPromptData.replace(/(\w+):(\w+(?:\s+\w+)*)/g, '"$1":"$2"');
        setPromptContextData(requiredPromptContextData)
        // for enclosing the modifiedPromptData value in string ""
        let requiredPromptActionData = modifiedPromptData && modifiedPromptData.replace(/(\w+):(\w+(?:\s+\w+)*)/g, '"$1":"$2"');
        setPromptActionData(requiredPromptActionData)

        // Assign the modified value to promptActionData
        const promptActionData = modifiedPromptData;
        const promptContextData = modifiedContextPromptData
        let temp = { ...templateFormDetails };
        temp.prompt_id = response.data[0].prompt_id
        temp.template_id = response.data[0].template_id
        temp.category_id = response.data[0].category_id
        temp.template_name = response.data[0].template_name
        temp.flag_show_field = response.data[0].flag_show_field
        temp.template_icon_url = response.data[0].template_icon_url
        temp.template_description = response.data[0].template_description
        temp.template_visibility_type_name = response.data[0].template_visibility_type_name
        temp.template_step_form_data[0].template_form_id = response.data[0].template_steps[0].template_step_form_data[0].template_form_id
        // temp.template_prompt_data.prompt_action_data = modifiedPromptData
        temp.template_prompt_data.prompt_action_data = response.data[0].prompt_action_data
        temp.template_prompt_data.prompt_context_data = response.data[0].prompt_context_data
        temp.template_prompt_data.prompt_num_of_output = response.data[0].template_description
        temp.template_step_form_data[0].template_form_name = response.data[0].template_steps[0].template_step_form_data[0].template_form_name
        temp.template_step_form_data[0].template_form_button_name = response.data[0].template_steps[0].template_step_form_data[0].template_form_button_name
        temp.template_step_form_data[0].template_form_field_data = response.data[0].template_steps[0].template_step_form_data[0].template_form_field_data.map((field) => {
            return {
                template_form_field_id: field.template_form_field_id,
                template_form_field_flag_ai_generation: field.template_form_field_flag_ai_generation,
                template_form_field_seq_num: field.template_form_field_seq_num,
                form_field_input_type_id: field.form_field_input_type_id,
                form_field_input_type_name: field.form_field_input_type_name,
                form_field_input_type_abbr: field.form_field_input_type_abbr,
                template_form_field_property: field.template_form_field_property_data,
            }
        })
        console.log("temp data after getting response", temp);
        setTemplateFormFieldList(temp.template_step_form_data[0].template_form_field_data)
        setTemplateFormDetails(temp);
        console.log("templateFormDetails", templateFormDetails)
        setLastUpdatedTemplateFormDetails(temp)
        console.log("promptActionData", promptActionData);
        console.log("promptContextData", promptContextData);

    }
    const handleSaveTemplate = async () => {
        if (templateFormDetails.template_id) {
            //for sending to backend
            // let tempFormattedPromptActionData = templateFormDetails.template_prompt_data.prompt_action_data.replace(/\[\[/gi, '[').replace(/\]\]/gi, ']')
            // promptActionData.replace(/\[\[/, '[').replace(/\]\]/, ']')
            // let regex = /,"value":"([^"]+)","prefix":"@"/g
            // let formattedPromptActionData = tempFormattedPromptActionData.replace(regex, "")
            const payload = { ...templateFormDetails }
            payload.template_prompt_data.prompt_action_data = promptActionData
            payload.template_prompt_data.prompt_context_data = promptContextData.replace(/&lt;/g, '<').replace(/&gt;/g, '>')
            // templateFormDetails.template_prompt_data.prompt_action_data
            payload.template_step_form_data[0].template_form_name = `${templateFormDetails.template_name} form`
            payload.template_step_form_data[0].template_form_field_data = templateFormFieldList
            const response = await agent.updateCustomTemplate.put(payload);
            formatPromptActionData(response)
        } else {
            //for sending to backend
            let tempFormattedPromptActionData = promptActionData.replace(/\[\[/gi, '[').replace(/\]\]/gi, ']')
            let regex = /,"value":"([^"]+)","prefix":"@"/g
            let formattedPromptActionData = tempFormattedPromptActionData.replace(regex, "")
            let tempFormattedPromptActionDataContext = promptContextData.replace(/\[\[/gi, '[').replace(/\]\]/gi, ']')
            let formattedPromptActionDataContext = tempFormattedPromptActionDataContext.replace(regex, "")
            const payload = { ...templateFormDetails }
            payload.template_prompt_data.prompt_action_data = formattedPromptActionData
            payload.template_prompt_data.prompt_context_data = formattedPromptActionDataContext.replace(/&lt;/g, '<').replace(/&gt;/g, '>')
            payload.template_step_form_data[0].template_form_name = `${templateFormDetails.template_name} form`
            payload.template_step_form_data[0].template_form_field_data = templateFormFieldList
            const response = await agent.createCustomTemplate.post(payload);
            // let temp ={}
            formatPromptActionData(response)

        }
    }
    const handleImportFromFormwiz = () => {
        let parsedJSONData = JSON.parse(importedJSONData);
        const extractedData = parsedJSONData.template_form_field_data.map((field) => {
            const fieldType = templateFormFieldsGenericData.find(
                (fieldType) => fieldType.form_field_input_type_name === field.form_field_input_type_name
            )
            let optionsEncountered = false;
            if (fieldType) {
                let optionsArr = []
                return {
                    template_form_field_id: field.template_form_field_id,
                    template_form_field_flag_ai_generation: field.template_form_field_flag_ai_generation,
                    template_form_field_seq_num: field.template_form_field_seq_num,
                    form_field_input_type_id: fieldType.form_field_input_type_id,
                    form_field_input_type_name: fieldType.form_field_input_type_name,
                    form_field_input_type_description: fieldType.form_field_input_type_description,
                    form_field_input_type_abbr: fieldType.form_field_input_type_abbr,
                    form_field_input_type_regex: fieldType.form_field_input_type_regex,
                    template_form_field_property: field.template_form_field_property_data.map((prop, index) => {
                        const arr = fieldType.form_field_input_type_property.filter((el) => el.form_field_input_type_property_name == prop.form_field_input_type_property_name);
                        if (prop.template_form_field_property_value !== null) {
                            delete prop.template_form_field_property_id; // Delete the property here
                            if (optionsArr.find((obj) => obj.form_field_input_type_property_name == "Options") && prop.form_field_input_type_property_name == "Options") {
                                return null
                            }
                            else {
                                optionsArr.push(prop)
                            }
                            return {
                                ...prop,
                                form_field_input_type_property_id: arr.length > 0 ? arr[0].form_field_input_type_property_id : "",
                                form_field_input_type_property_description: arr.length > 0 ? arr[0].form_field_input_type_property_description : "",
                                template_form_field_property_value: prop.form_field_input_type_property_name == "Options" ? field.template_form_field_property[0].Options : prop.template_form_field_property_value,
                                template_form_field_property_flag_array: prop.form_field_input_type_property_name == "Options" ? true : false,
                                form_field_input_type_property_name: prop.form_field_input_type_property_name
                            };
                        } else {
                            return null;
                        }

                    }).filter((tempEle) => tempEle !== null),
                    // .filter((tempEle) => !!tempEle.form_field_input_type_property_id)
                    // .filter((v) => v !== null)



                    // template_form_field_property: field.template_form_field_property_data.map((prop) => {
                    //     console.log(("prop in map----------------", prop))
                    //     const arr = fieldType.form_field_input_type_property.filter((el) => el.form_field_input_type_property_name == prop.form_field_input_type_property_name);
                    //     return {
                    //         ...prop,
                    //         form_field_input_type_property_id: arr.length > 0 ? arr[0].form_field_input_type_property_id : "",
                    //         template_form_field_property_value: prop.template_form_field_property_value,
                    //         template_form_field_property_flag_array: false,
                    //         form_field_input_type_property_name: prop.form_field_input_type_property_name
                    //         delete prop.template_form_field_property_id
                    //     }
                    // }),




                    // template_form_field_property_data: field.template_form_field_property_data,
                    // Extract specific properties from fieldType if needed
                    // title: fieldType.form_field_input_type_property.find(
                    //     (property) => property.form_field_input_type_property_name === "Title"
                    // ),
                    // placeholder: fieldType.form_field_input_type_property.find(
                    //     (property) => property.form_field_input_type_property_name === "Placeholder"
                    // ),
                    // isRequired: fieldType.form_field_input_type_property.find(
                    //     (property) => property.form_field_input_type_property_name === "Is Required?"
                    // ),
                    // maxLength: fieldType.form_field_input_type_property.find(
                    //     (property) => property.form_field_input_type_property_name === "Max Length"
                    // ),
                    // minLength: fieldType.form_field_input_type_property.find(
                    //     (property) => property.form_field_input_type_property_name === "Min Length"
                    // ),
                };
            }

            return null; // Handle the case where no matching fieldType is found
        });
        // filteredData will contain the extracted data
        console.log("extractedData", extractedData);
        var filteredArray = extractedData.filter(function (item) {
            return item !== null;
        });
        filteredArray.map((field, index) => ({ ...field, id: index }))
        console.log("filteredArray", filteredArray)
        let temp = filteredArray.map(function (item) {
            item.template_form_field_property = item.template_form_field_property.filter((prop) => (
                prop !== null && prop.form_field_input_type_property_id !== ''
            ));
            return item;
        });
        console.log("temp-----", temp)
        setTemplateFormFieldList(temp)
        setFlagImportFromFormwiz(false)
    }
    // const handleImportFromFormwiz = () => {
    //     debugger;
    //     let parsedJSONData = JSON.parse(importedJSONData);
    //     console.log("parsedJSONData", parsedJSONData)
    //     const extractedData = parsedJSONData.template_form_field_data.map((field) => {
    //         const fieldType = templateFormFieldsGenericData.find(
    //             (fieldType) => field.form_field_input_type_name == "Dropdown" ?
    //                 field.template_form_field_property_data.filter((fieldProperty) => fieldProperty.form_field_input_type_property_name == "Is Multiple?" && fieldProperty.template_form_field_property_value == "true").length > 0 ?
    //                     fieldType.form_field_input_type_name == "Multiple Chip Text and Dropdown" : fieldType.form_field_input_type_name == "Dropdown"
    //                 : fieldType.form_field_input_type_name === field.form_field_input_type_name
    //         )
    //         let optionsEncountered = false;
    //         if (fieldType) {
    //             console.log("fieldType", fieldType)
    //             let optionsArr = []
    //             return {
    //                 template_form_field_id: field.template_form_field_id,
    //                 template_form_field_flag_ai_generation: field.template_form_field_flag_ai_generation,
    //                 template_form_field_seq_num: field.template_form_field_seq_num,
    //                 form_field_input_type_id: fieldType.form_field_input_type_id,
    //                 form_field_input_type_name: fieldType.form_field_input_type_name,
    //                 form_field_input_type_description: fieldType.form_field_input_type_description,
    //                 form_field_input_type_abbr: fieldType.form_field_input_type_abbr,
    //                 form_field_input_type_regex: fieldType.form_field_input_type_regex,
    //                 template_form_field_property: field.template_form_field_property_data.map((prop, index) => {
    //                     console.log("prop in map----------------", prop);
    //                     const arr = fieldType.form_field_input_type_property.filter((el) => el.form_field_input_type_property_name == prop.form_field_input_type_property_name);
    //                     console.log("matching arr", arr)
    //                     delete prop.template_form_field_property_id; // Delete the property here
    //                     if (optionsArr.find((obj) => obj.form_field_input_type_property_name == "Options")) {
    //                         return null
    //                     }
    //                     else {
    //                         optionsArr.push(prop)
    //                     }
    //                     return {
    //                         ...prop,
    //                         form_field_input_type_property_id: arr.length > 0 ? arr[0].form_field_input_type_property_id : "",
    //                         template_form_field_property_value: prop.form_field_input_type_property_name == "Options" ? field.template_form_field_property[0].Options : prop.template_form_field_property_value,
    //                         template_form_field_property_flag_array: prop.form_field_input_type_property_name == "Options" ? true : false,
    //                         form_field_input_type_property_name: prop.form_field_input_type_property_name
    //                     };
    //                 }).filter((tempEle) => !!tempEle.form_field_input_type_property_id)
    //                 // .filter((v) => v !== null)



    //                 // template_form_field_property: field.template_form_field_property_data.map((prop) => {
    //                 //     console.log(("prop in map----------------", prop))
    //                 //     const arr = fieldType.form_field_input_type_property.filter((el) => el.form_field_input_type_property_name == prop.form_field_input_type_property_name);
    //                 //     return {
    //                 //         ...prop,
    //                 //         form_field_input_type_property_id: arr.length > 0 ? arr[0].form_field_input_type_property_id : "",
    //                 //         template_form_field_property_value: prop.template_form_field_property_value,
    //                 //         template_form_field_property_flag_array: false,
    //                 //         form_field_input_type_property_name: prop.form_field_input_type_property_name
    //                 //         delete prop.template_form_field_property_id
    //                 //     }
    //                 // }),




    //                 // template_form_field_property_data: field.template_form_field_property_data,
    //                 // Extract specific properties from fieldType if needed
    //                 // title: fieldType.form_field_input_type_property.find(
    //                 //     (property) => property.form_field_input_type_property_name === "Title"
    //                 // ),
    //                 // placeholder: fieldType.form_field_input_type_property.find(
    //                 //     (property) => property.form_field_input_type_property_name === "Placeholder"
    //                 // ),
    //                 // isRequired: fieldType.form_field_input_type_property.find(
    //                 //     (property) => property.form_field_input_type_property_name === "Is Required?"
    //                 // ),
    //                 // maxLength: fieldType.form_field_input_type_property.find(
    //                 //     (property) => property.form_field_input_type_property_name === "Max Length"
    //                 // ),
    //                 // minLength: fieldType.form_field_input_type_property.find(
    //                 //     (property) => property.form_field_input_type_property_name === "Min Length"
    //                 // ),
    //             };
    //         }

    //         return null; // Handle the case where no matching fieldType is found
    //     });
    //     // filteredData will contain the extracted data
    //     console.log("extractedData", extractedData);
    //     var filteredArray = extractedData.filter(function (item) {
    //         return item !== null;
    //     });
    //     filteredArray.map((field, index) => ({ ...field, id: index }))
    //     console.log("filteredArray", filteredArray)
    //     setTemplateFormFieldList(filteredArray)
    //     setFlagImportFromFormwiz(false)
    // }
    const handleShowImportFromFormwizPopup = () => {
        setFlagImportFromFormwiz(true)
    }
    const [templateList, setTemplateList] = useState(null)

    useEffect(() => {
        (async () => {
            const response = await agent.GetTemplateList.get();
            setTemplateList(response.data)
        })()
    }, [])
    const handleTryTemplate = async () => {
        const templateFormFieldData = {};
        console.log("formwizFieldsData------", formwizFieldsData)
        if (formwizFieldsData && formwizFieldsData.data && Array.isArray(formwizFieldsData.data.global_form_field)) {
            formwizFieldsData.data.global_form_field.forEach(field => {
                const templateFormFieldId = `template_form_field_id--${field.page_field_id}`;
                const value = Array.isArray(field.value) ? field.value.join(',') : field.value
                templateFormFieldData[templateFormFieldId] = value;
            });
            // (field.value == [] || field.value == "") ? null :
            // field.form_field_input_type_value_flag == null ? //checking value flag is null then i need to send whatever value it is
            //     field.value
            //     : field.form_field_input_type_value_flag ?   //checking value flag is true or not, if true i need to send the value in array otherwise string
            //         (field.value.isArray ? field.value : [field.value])  //need to check if it is already in array format or not if yes no need to convert array
            //         : field.value,
        } else {
            console.error("formwizFieldsData or global_form_field is undefined or not an array");
        }
        // formwizFieldsData.global_form_field.forEach(field => {
        //     const templateFormFieldId = `template_form_field_id--${field.page_field_id}`;
        //     const value = field.value;
        //     templateFormFieldData[templateFormFieldId] = value;
        // });
        const executePromptPayload = {
            document_uuid: "-1",
            prompt_id: templateFormDetails.prompt_id,
            template_form_field_data: templateFormFieldData
        }
        console.log("payload for execute prompt", executePromptPayload)
        const response = await agent.executePrompt.post(executePromptPayload)
        setExecutePromptResponse(response)
        console.log("response", response)
        // const pay
    }
    const getTryTemplateDisabledCondition = () => {
        if (JSON.stringify(templateFormDetails) == JSON.stringify(lastUpdatedTemplateFormDetails)) {
            return false
        } else {
            return true
        }
    }
    const handleUpdateTemplateAccess = async (event) => {
        if (event.target.checked) {
            let payload = {
                template_id: templateFormDetails.template_id,
                template_flag_public: true
            }
            const response = await agent.updateTemplateVisibility.put(payload)
            if (response.status) {
                let temp = { ...templateFormDetails }
                temp.template_visibility_type_name = "Public"
                setTemplateFormDetails(temp)
            }
        } else {
            const payload = {
                template_id: templateFormDetails.template_id,
                template_flag_public: false
            }
            const response = await agent.updateTemplateVisibility.put(payload)
            if (response.status) {
                let temp = { ...templateFormDetails }
                temp.template_visibility_type_name = "Private"
                setTemplateFormDetails(temp)
            }
        }
    }
    return (
        <>

            {/* title header */}
            {flagImportFromFormwiz &&
                <GlobalPopUp
                    open={flagImportFromFormwiz}
                    onClose={() => { setFlagImportFromFormwiz(false) }}
                    title={"Import from formwiz!"}
                    flagShowCloseIcon={true}
                    handleClose={() => { setFlagImportFromFormwiz(false) }}
                >
                    <ImportFromFormwiz getStartedfunc={handleImportFromFormwiz} importedJSONData={importedJSONData} setImportedJSONData={setImportedJSONData} />
                </GlobalPopUp>
            }
            <Grid item className={`${globalStyles.fullwidth} ${styles.customTemplateWrapper}`}>
                <Grid container justifyContent={"space-between"} direction="column">
                    <Grid item>
                        <Grid container justifyContent={"space-between"}>
                            <Grid item>
                                <GlobalButton buttonText="Import from Formwiz" height="30px" className="primaryButtonStyle" onClick={handleShowImportFromFormwizPopup} />
                            </Grid>
                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <Switch disabled={templateFormDetails.template_id ? false : true} checked={templateFormDetails.template_visibility_type_name == "Public" ? true : false} onChange={handleUpdateTemplateAccess} name="antoine" />
                                    }
                                    label="Public"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* save template button*/}
                    <Grid item className={globalStyles.fullwidth}>
                        <Grid container justifyContent={"space-between"}>
                            <Grid item>
                                <Grid container direction="column">
                                    <Grid item className={styles.customTemplateTextStyle}>Custom Templates</Grid>
                                    <Grid item className={styles.customTemplateDescTextStyle}>Lorem ipsum dolor sit amet consectetur. Malesuada diam rhoncus porttitor magna tellus ac arcu</Grid>
                                </Grid>
                            </Grid>
                            <Grid item className={styles.saveTemplateButtonWrapper}>
                                <GlobalButton height="43px" flagFullWidth buttonText={templateFormDetails.template_id ? "Update Template" : "Save Template"} className="primaryButtonStyle"
                                    onClick={handleSaveTemplate}
                                    disabled={!getTryTemplateDisabledCondition()}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={styles.customTemplateContentStyle}>
                        <Grid container>
                            {/* left panel */}
                            <Grid item xs={6} sx={{ borderRight: "0.5px solid #eeebeb" }}>
                                <Grid container direction="column">
                                    {/* template Name */}
                                    <Grid item>
                                        <Grid container direction="column">
                                            <Grid item>
                                                <InputLabel className={styles.templateTitleTextstyle}>Template Name</InputLabel>
                                            </Grid>
                                            <Grid item>
                                                <TextField value={templateFormDetails.template_name} onChange={(e) => { { setTemplateFormDetails({ ...templateFormDetails, template_name: e.target.value }) } }} /></Grid>
                                        </Grid>
                                    </Grid>
                                    {/* template description */}
                                    <Grid item>
                                        <Grid container direction="column">
                                            <Grid item>
                                                <InputLabel className={styles.templateTitleTextstyle}>Template Description</InputLabel>
                                            </Grid>
                                            <Grid item>
                                                <TextareaAutosize value={templateFormDetails.template_description} onChange={(e) => { { setTemplateFormDetails({ ...templateFormDetails, template_description: e.target.value }) } }} /></Grid>
                                        </Grid>
                                    </Grid>
                                    {/* template icon url */}
                                    <Grid item>
                                        <Grid container direction="column">
                                            <Grid item>
                                                <InputLabel className={styles.templateTitleTextstyle}>Template Icon URL</InputLabel>
                                            </Grid>
                                            <Grid item>
                                                <TextareaAutosize value={templateFormDetails.template_icon_url} onChange={(e) => { { setTemplateFormDetails({ ...templateFormDetails, template_icon_url: e.target.value }) } }} /></Grid>
                                        </Grid>
                                    </Grid>
                                    {/* template submit button text */}
                                    <Grid item>
                                        <Grid container direction="column">
                                            <Grid item>
                                                <InputLabel className={styles.templateTitleTextstyle}>Template Submit button Text</InputLabel>
                                            </Grid>
                                            <Grid item>
                                                <TextField value={templateFormDetails.template_form_button_name} onChange={(e) => { setTemplateFormDetails({ ...templateFormDetails, template_form_button_name: e.target.value }) }} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {/* <Grid item>
                                        <Grid container direction="column">
                                            <Grid item>
                                                <InputLabel className={styles.templateTitleTextstyle}>Template Icon Url</InputLabel>
                                            </Grid>
                                            <Grid item>
                                                <TextField value={templateFormDetails.template_icon_url} onChange={(e) => { { setTemplateFormDetails({ ...templateFormDetails, template_icon_url: e.target.value }) } }} /></Grid>
                                        </Grid>
                                    </Grid> */}
                                    <>

                                        {/* maste tab */}
                                        <Grid item>
                                            <DraggableListComponent />
                                            {/* <CreatableSelect value={masterValues} onChange={handleMasterValuesChange} isMulti options={masterValues} /> */}
                                        </Grid>

                                        {/* template prompt */}
                                        <Grid item>
                                            <Grid container direction="column">
                                                <Grid item>
                                                    <InputLabel className={styles.templateTitleTextstyle}>Template Prompt(Action Data)</InputLabel>
                                                </Grid>
                                                <Grid item>
                                                    {/* <TextareaAutosize ref={templatePromptTextAreaRef} className={styles.templateDescriptionTextAreaStyle} /> */}
                                                    {/* {whiteListOptions.length > 0 && */}
                                                    <MixedModeTagify value={promptActionData} setValue={setPromptActionData} whiteListOptions={masterValues} />
                                                </Grid>

                                                {/* prompt context  */}
                                                {/* <Grid item>
                                                    <Grid container direction="column">
                                                        <Grid item>
                                                            <InputLabel className={styles.templateTitleTextstyle}>Prompt Context</InputLabel>
                                                        </Grid>
                                                        <Grid item>
                                                            <TextareaAutosize
                                                                value={templateFormDetails.template_prompt_data.prompt_context_data}
                                                                onChange={(e) => { setTemplateFormDetails({ ...templateFormDetails, template_prompt_data: { ...templateFormDetails.template_prompt_data, prompt_context_data: e.target.value } }) }}
                                                            /></Grid>
                                                    </Grid>
                                                </Grid> */}
                                                <Grid item>
                                                    {/* <TextareaAutosize ref={templatePromptTextAreaRef} className={styles.templateDescriptionTextAreaStyle} /> */}
                                                    {/* {whiteListOptions.length > 0 && */}
                                                    <MixedModeTagifyContext value={promptContextData} setValue={setPromptContextData} whiteListOptions={masterValues} />
                                                </Grid>

                                                {/* prompt response format */}
                                                <Grid item>
                                                    <Grid container direction="column">
                                                        <Grid item>
                                                            <InputLabel className={styles.templateTitleTextstyle}>Prompt Response Format</InputLabel>
                                                        </Grid>
                                                        <Grid item>
                                                            <TextareaAutosize
                                                                value={templateFormDetails.template_prompt_data.prompt_response_format}
                                                                onChange={(e) => { setTemplateFormDetails({ ...templateFormDetails, template_prompt_data: { ...templateFormDetails.template_prompt_data, prompt_response_format: e.target.value } }) }}
                                                            /></Grid>
                                                    </Grid>
                                                </Grid>
                                                {/* flag show field */}
                                                <Grid item>
                                                    <Grid container direction="column">
                                                        <Grid item>
                                                            <InputLabel className={styles.templateTitleTextstyle}>Flag show field</InputLabel>
                                                        </Grid>
                                                        <Grid item>
                                                            <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={templateFormDetails.flag_show_field}
                                                                label="template_id"
                                                                onChange={(e) => { setTemplateFormDetails({ ...templateFormDetails, flag_show_field: e.target.value }) }}
                                                            >
                                                                <MenuItem value={true}>True</MenuItem>
                                                                <MenuItem value={false}>false</MenuItem>
                                                            </Select></Grid>
                                                    </Grid>
                                                </Grid>
                                                {/* prompt num of output */}
                                                <Grid item>
                                                    <Grid container direction="column">
                                                        <Grid item>
                                                            <InputLabel class Name={styles.templateTitleTextstyle}>Prompt Number of Output</InputLabel>
                                                        </Grid>
                                                        <Grid item>
                                                            <TextField type="number"
                                                                value={templateFormDetails.template_prompt_data.prompt_num_of_output}
                                                                onChange={(e) => { setTemplateFormDetails({ ...templateFormDetails, template_prompt_data: { ...templateFormDetails.template_prompt_data, prompt_num_of_output: e.target.value } }) }}

                                                            />
                                                            {/* <Textfield type="number" value={templateFormDetails.template_description} onChange={(e) => { { setTemplateFormDetails({ ...templateFormDetails, template_description: e.target.value }) } }} /> */}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                {/* category */}
                                                <Grid item>
                                                    <Grid container direction="column">
                                                        <Grid item>
                                                            <InputLabel class Name={styles.templateTitleTextstyle}>Category</InputLabel>
                                                        </Grid>
                                                        <Grid item>
                                                            <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={templateFormDetails.category_id}
                                                                label="template_id"
                                                                onChange={(e) => { setTemplateFormDetails({ ...templateFormDetails, category_id: e.target.value }) }}
                                                            >
                                                                {templateList !== null && templateList[0].category_list.map((category) => (
                                                                    <MenuItem value={category.category_id}>{category.category_name}</MenuItem>
                                                                ))
                                                                }
                                                                <MenuItem value={null}>null</MenuItem>
                                                            </Select>
                                                            {/* <Textfield type="number" value={templateFormDetails.template_description} onChange={(e) => { { setTemplateFormDetails({ ...templateFormDetails, template_description: e.target.value }) } }} /> */}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </>
                                </Grid>
                            </Grid>
                            {/* right panel */}
                            {/* {flagShowForm && */}
                            <Grid item xs={6}>
                                <Grid container className={styles.rightPanelStyle} direction={"column"} alignItems={"center"}>
                                    <Grid item>Preview</Grid>
                                    <Grid item>Here is the preview of how your template will look like at the end</Grid>
                                    <TemplateFormPreview formwizFieldsData={formwizFieldsData} setFormwizFieldsData={setFormwizFieldsData} />
                                    <Grid item>
                                        <GlobalButton
                                            disabled={getTryTemplateDisabledCondition()}
                                            onClick={handleTryTemplate} buttonText="Try Template" className="primaryButtonStyle" />
                                    </Grid>
                                    <Grid item>
                                        <Grid container>
                                            <Grid item>Output</Grid>
                                            {executePromptResponse != null && executePromptResponse.data.map((executePromptRespEx) => (
                                                executePromptResponse.flag_show_field ? (
                                                    Object.keys(executePromptRespEx).map((key) => (
                                                        <div key={key}>
                                                            <Typography>{key}</Typography>
                                                            <Typography>{(executePromptRespEx)[key]}</Typography>
                                                        </div>
                                                    ))
                                                ) : (
                                                    <div>
                                                        {Object.values(executePromptRespEx).map((val, key) => (
                                                            <Typography key={key}>{val}</Typography>
                                                        ))}
                                                    </div>
                                                )
                                            ))
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/* } */}
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default CustomTemplate;