import * as React from 'react';
import agent from '../api/api';
import { UserContext } from '../context/userContext';
import { Box, Grid } from '@mui/material';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import "../assets/styles/updatePrompt.module.css";
import UpdatePrompt from './updatePromptForm';
import GlobalButton from '../components/button';
import GlobalDataGrid from '../components/globalDataGrid';
import GlobalSearch from '../utils/globalSearch';
import globalStyles from "../assets/styles/global.module.scss"
import DeleteIcon from '@mui/icons-material/Delete';
import GlobalDeletePopup from '../utils/globalDeletePopup';
import { checkForErrorCode, getErrorMessage } from '../utils/constants';
import { useNavigate } from 'react-router-dom';
import RoundLoader from "../components/roundLoader";
import RefreshIcon from '@mui/icons-material/Refresh';

const PromptList = () => {
    const defaultColumns = [
        { field: 'prompt_id', headerName: 'Prompt ID', width: 300 },
        { field: 'prompt_name', headerName: 'Prompt Name', width: 300 },
    ];
    const navigate = useNavigate();
    const { setFlagLoading, flagLoading, setSuccess, success, setError, error } = React.useContext(UserContext);
    const [rowData, setRowData] = React.useState([]);
    const [columns, setColumns] = React.useState(defaultColumns);
    const [showPrompt, setShowPrompt] = React.useState(false);
    const [flagDeletePrompt, setFlagDeletePrompt] = React.useState(false);
    const [promptToBeDeleted, setPromptToBeDeleted] = React.useState(null);
    const [rowFilteredDetails, setRowFilteredDetails] = React.useState(null);
    const [filterValues, setFilterValues] = React.useState(null);
    const [createPromptFormData, setCreatePromotData] = React.useState(
        {
            max_tokens: "",
            prompt_name: "",
            template_id: null,
            credits_required: "",
            prompt_type_name: "primary",
            prompt_generic_name: ""
        }
    )

    const getPrompts = async () => {
        setFlagLoading(true);
        const response = await agent.GetPrompts.get();
        setFlagLoading(false);
        if (response.status) {
            if (response.data) {
                const tempRowData = response.data.map((prompt, index) => ({
                    id: index,
                    prompt_id: prompt.prompt_id,
                    prompt_name: prompt.prompt_name,
                    prompt_flag_extension: prompt.prompt_flag_extension,
                    seq_num: prompt.seq_num ? prompt.seq_num : "-"
                    // Add other fields here...
                }));
                setRowData(tempRowData);
                setRowFilteredDetails(tempRowData);
                setFilterValues("");
            }
        }
        else {
            let errorCode = checkForErrorCode(response)
            setError({ ...error, flagShowError: true, message: getErrorMessage(errorCode) })
        }
    }

    React.useEffect(() => {
        (async () => {
            getPrompts()
            const columns = [
                { field: 'prompt_id', headerName: 'Prompt ID', width: 200, align: "center", },
                { field: 'prompt_name', headerName: 'Prompt Name', width: 350, },
                {
                    field: 'update',
                    headerName: 'Update',
                    width: 150,
                    align: "start",
                    renderCell: (params) => {
                        return (
                            <strong>
                                <ModeEditIcon className={globalStyles.clickable} onClick={() => { handleRowEditClick(params.row) }} />
                            </strong>
                        )
                    },
                },
                {
                    field: 'delete',
                    headerName: 'Delete',
                    width: 150,
                    align: "start",
                    renderCell: (params) => {
                        return (
                            <strong>
                                <DeleteIcon className={globalStyles.clickable} onClick={() => { handleDeleteClick(params.row) }} />
                            </strong>
                        )
                    },
                },
                {
                    field: 'Details',
                    headerName: 'Details',
                    width: 150,
                    align: "start",
                    renderCell: (params) => {
                        return (
                            <strong>
                                <GlobalButton className="tableButtonStyle" buttonText="View" onClick={() => { window.open(`${window.location.href}/promptDetails?prompt_id=${params.row.prompt_id}`, '_blank') }} />
                            </strong>
                        )
                    },
                },
            ];
            setColumns(columns);
        })();
    }, []);


    const handleRowEditClick = async (row) => {
        const response = await agent.GetPromptDetails.get(row.prompt_id);
        const responseData = response.data[0];
        const updatedData = {
            prompt_id: responseData.prompt_id,
            max_tokens: responseData.max_tokens,
            prompt_name: responseData.prompt_name,
            template_id: responseData.template_id,
            credits_required: responseData.credits_required,
            prompt_type_name: responseData.prompt_type_name,
            prompt_generic_name: responseData.prompt_generic_name
        }
        setCreatePromotData(updatedData)
        setShowPrompt(true)
    };

    const handleDeleteClick = async (row) => {
        setFlagDeletePrompt(true);
        setPromptToBeDeleted(row);
    };

    const handleDeletePrompt = async () => {
        setFlagLoading(true);
        const delete_prompt_id = promptToBeDeleted.prompt_id;
        const response = await agent.DeletePrompt.delete(delete_prompt_id);
        if(response.status){
            setPromptToBeDeleted(null);
            getPrompts();
            setFlagDeletePrompt(false);
            setSuccess({
                ...success,
                flagShowSuccess: response.status,
                message: "Prompt Deleted Successfully.",
            });
        }
        else{
            let errorCode = checkForErrorCode(response)
            setError({ ...error, flagShowError: true, message: getErrorMessage(errorCode) })
            navigate(`/dashboard/prompts/promptDetails?prompt_id=${delete_prompt_id}`)
        }
        setFlagLoading(false);
    }

    const handleCancelPrompt = () => {
        setFlagDeletePrompt(false);
        setPromptToBeDeleted(null);
    }

    const handleSearch = (event) => {
        const query = event.target.value.trim().toLowerCase();
        setFilterValues(query);
        if(Array.isArray(rowData)) {
            const rowFilterData = rowData.filter((data) => {
                return (
                    (data.prompt_name !== null && data.prompt_name.toLowerCase().includes(query)) || 
                    (data.prompt_id !== null && data.prompt_id.toString().includes(query))
                );
            });
            setRowFilteredDetails(rowFilterData);
        }
    }

    const handleClearSearch = () => {
        setFilterValues("");
        setRowFilteredDetails(null);
    }


    return (
        <>
        {
            flagLoading && <RoundLoader />
        }
            {!flagLoading && (
                <>
                    <Grid container className={globalStyles.containerflexWrapStyle} direction={"row"} alignItems={"center"} gap={1} mb={1} justifyContent={"end"}>
                        <Grid item className={globalStyles.searchBarFieldGrid}>
                            <GlobalSearch placeholder="Search Prompt" value={filterValues} onChange={(e) => {handleSearch(e)}} onClose={handleClearSearch}/>
                        </Grid>
                        <Box display={"flex"} justifyContent={"end"} pb={"5px"}>
                            <RefreshIcon onClick={getPrompts} className={globalStyles.clickable} />
                        </Box>
                    </Grid>

                    <Grid container gap={"15px"} justifyContent="space-between">
                        {flagDeletePrompt && 
                            <GlobalDeletePopup deleteDescription={`Are you sure you want to delete ${promptToBeDeleted.prompt_name} prompt`} onClose={handleCancelPrompt} onDelete={handleDeletePrompt}/>
                        }
                        <Grid item xs={showPrompt ? 6 : 12}>
                            <GlobalDataGrid rows={rowData} filterRows={rowFilteredDetails} columns={columns} hideFooter={false} hideFooterPagination={false} hideFooterSelectedRowCount={true} showSelectedRowDetails={true} refresh={getPrompts} onRowClick={(e) => { console.log("e---", e) }} />
                        </Grid>
                        {showPrompt &&
                            <Grid item xs={5.7}>
                                <UpdatePrompt updatePromptFormData={createPromptFormData} setUpdatePromptFormData={setCreatePromotData} setShowPrompt={setShowPrompt}/>
                            </Grid>}
                    </Grid>
                </>

            )}
        </>
    );
};

export default PromptList;
