import * as React from "react";
import { Button, Grid, FormControl, InputLabel, MenuItem, Select, TextField, TextareaAutosize, Switch, FormControlLabel, Typography } from '@mui/material';
import Required from "../../components/required";
import { useNavigate } from "react-router-dom";
import { PromptContext } from "../../context/promptContext";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Checkbox from '@mui/material/Checkbox';
import Avatar from '@mui/material/Avatar';
import globalStyles from "../../assets/styles/global.module.scss";
import GlobalButton from "../../components/button";
import { MixedTags } from "@yaireo/tagify/dist/react.tagify";


const PromptVariantForm = (props) => {
    console.log("props--", props)
    const [settings, setSettings] = React.useState({
        pattern: /@/,
        dropdown: {
            enabled: 0,
            position: "text"
        },
        whitelist: props.whiteListOptions
    });
    React.useEffect(() => {
        setSettings((prevSettings) => ({
            ...prevSettings,
            whitelist: props.whiteListOptions
        }));
    }, [props])
    const [checked, setChecked] = React.useState([1]);

    const handleToggle = (val, value) => {
        // debugger;
        if (props.promptVariantForm.prompt_variant_id) {
            const modelIdExists = props.promptVariantModelData.some(item => item.model_id === value.model_id);

            if (val === true) {
                // If val is true and model_id exists, take the object and push into prompt_variant_model_data
                if (modelIdExists) {
                    const matchingModel = props.promptVariantModelData.find(item => item.model_id === value.model_id);

                    props.setPromptVariantForm({
                        ...props.promptVariantForm,
                        prompt_variant_model_data: [
                            ...props.promptVariantForm.prompt_variant_model_data,
                            matchingModel
                        ]
                    });
                } else {
                    // If model_id doesn't exist, add the value object
                    props.setPromptVariantForm({
                        ...props.promptVariantForm,
                        prompt_variant_model_data: [
                            ...props.promptVariantForm.prompt_variant_model_data,
                            value
                        ]
                    });
                }
            } else {
                // If val is false, remove the object based on model_id
                const updatedModelData = props.promptVariantForm.prompt_variant_model_data.filter(
                    item => item.model_id !== value.model_id
                );

                props.setPromptVariantForm({
                    ...props.promptVariantForm,
                    prompt_variant_model_data: updatedModelData
                });
            }
            // Handle case when prompt_variant_id is present
        } else {
            if (val === true) {
                // If val is true, add the value object to prompt_variant_model_data
                const newExampleData = {
                    prompt_variant_example_name: "",
                    prompt_variant_example_input: "",
                    prompt_variant_example_output: "",
                    prompt_variant_example_seq_num: 1
                };

                props.setPromptVariantForm({
                    ...props.promptVariantForm,
                    prompt_variant_example_data: value.model_name === "gemini-pro"
                        ? [...props.promptVariantForm.prompt_variant_example_data, newExampleData]
                        : props.promptVariantForm.prompt_variant_example_data,
                    prompt_variant_model_data: [
                        ...props.promptVariantForm.prompt_variant_model_data,
                        value
                    ]
                });
            } else {
                // If val is false, remove the value object from prompt_variant_model_data
                const updatedModelData = props.promptVariantForm.prompt_variant_model_data.filter(
                    (item) => item !== value
                );

                props.setPromptVariantForm({
                    ...props.promptVariantForm,
                    prompt_variant_model_data: updatedModelData
                });
            }
        }
    };

    const handleChange = (val, value) => {
        let temp = props.promptVariantForm.prompt_variant_model_data.map((model) => model.model_id == value.model_id ? { ...model, strict_model_flag: val } : { ...model, strict_model_flag: !val })
        props.setPromptVariantForm({ ...props.promptVariantForm, prompt_variant_model_data: temp })
    }
    const { outputFormatGenericData, modelGenericList, setModelGenericList } = React.useContext(PromptContext)
    console.log("modelGenericList", modelGenericList)
    // output_format_name: "",
    //     prompt_variant_name: "",
    //     output_format_example: "",
    //     prompt_context_data: "",
    //     prompt_action_data: "",
    //     prompt_output_key_name: "",
    //     prompt_flag_output_data: false,
    //     prompt_variant_flag_all_models: false,
    //     prompt_variant_model_data: []
    // strict_model_flag
    const handleAddExample = () => {
        props.setPromptVariantForm({
            ...props.promptVariantForm, prompt_variant_example_data: [...props.promptVariantForm.prompt_variant_example_data, {
                prompt_variant_example_name: '',
                prompt_variant_example_input: '',
                prompt_variant_example_output: '',
                prompt_variant_example_seq_num: ''
            }]
        }
        );
    };

    const handleDeleteExample = (index) => {
        // debugger;
        const updatedExamples = [...props.promptVariantForm.prompt_variant_example_data];
        updatedExamples.splice(index, 1);
        props.setPromptVariantForm({ ...props.promptVariantForm, prompt_variant_example_data: updatedExamples });
    };
    const handleExampleChange = (value, index, keyName) => {
        props.setPromptVariantForm({
            ...props.promptVariantForm, prompt_variant_example_data: props.promptVariantForm.prompt_variant_example_data.map((example, exampleIndex) => {
                if (exampleIndex == index) {
                    return { ...example, [keyName]: value }
                }
                return example
            })
        })
    }
    return (
        <>
            <Grid container direction={"column"} className={`${globalStyles.globalContainer}`}>
                <div style={{ marginBottom: "15px" }}><Typography sx={{ color: "#6A097D", fontSize: "15px", fontWeight: "600", }}>{props.promptVariantForm.prompt_variant_id ? "Update" : "Create"} Prompt Variant</Typography></div>
                <div className={globalStyles.variantForm}>
                    {/* prompt variant name */}
                    <Grid item >
                        <InputLabel className={globalStyles.labelStyle} >Prompt Variant Name<Required /></InputLabel>
                        <TextField
                            className={globalStyles.globalTextfieldStyle}
                            fullWidth
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={props.promptVariantForm.prompt_variant_name}
                            onChange={(e) => { props.setPromptVariantForm({ ...props.promptVariantForm, prompt_variant_name: e.target.value }) }}
                        />
                    </Grid>
                    {/* output format name */}
                    <Grid item sx={{ marginBottom: "20px" }}>
                        <InputLabel className={globalStyles.labelStyle} >Output format name<Required /></InputLabel>
                        <FormControl fullWidth>
                            <Select
                                className={globalStyles.globalTextfieldStyle}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={props.promptVariantForm.output_format_name}
                                onChange={(e) => { props.setPromptVariantForm({ ...props.promptVariantForm, output_format_name: e.target.value }) }}
                            >
                                {outputFormatGenericData.map((format) => (
                                    <MenuItem value={format.output_format_name}>{format.output_format_name}</MenuItem>
                                ))
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    {/* model name */}
                    <Grid item >
                        <InputLabel className={globalStyles.labelStyle} >Flag All Models<Required /></InputLabel>
                        <FormControl fullWidth>
                            <Select
                                className={globalStyles.globalTextfieldStyle}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={props.promptVariantForm.prompt_variant_flag_all_models}
                                onChange={(e) => { props.setPromptVariantForm({ ...props.promptVariantForm, prompt_variant_flag_all_models: e.target.value, prompt_variant_model_data: e.target.value == true ? [] : props.promptVariantForm.prompt_variant_model_data }) }}
                            >

                                <MenuItem value={true}>True</MenuItem>
                                <MenuItem value={false}>False</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    {/* model selection */}
                    {
                        !props.promptVariantForm.prompt_variant_flag_all_models &&
                        <List dense sx={{ width: '100%', bgcolor: 'background.paper' }}>
                            <div style={{ color: "#6A097D", fontSize: "15px", fontWeight: "600" }}>Choose Models'</div>
                            {modelGenericList.map((value) => {
                                const labelId = `checkbox-list-secondary-label-${value}`;
                                return (
                                    <ListItem
                                        key={value.model_id}
                                        secondaryAction={
                                            <>
                                                <Checkbox
                                                    edge="end"
                                                    onChange={(e) => handleToggle(e.target.checked, value)}
                                                    // checked={value.checked}
                                                    checked={props.promptVariantForm.prompt_variant_model_data.some((variant) => variant.model_id == value.model_id)}
                                                    // checked={checked.indexOf(value) !== -1}
                                                    disabled={
                                                        (value.model_name === "gemini-pro" &&
                                                            props.promptVariantForm.prompt_variant_model_data.some((variant) => variant.model_name !== "gemini-pro")) ||
                                                        (value.model_name !== "gemini-pro" &&
                                                            props.promptVariantForm.prompt_variant_model_data.some((variant) => variant.model_name === "gemini-pro"))
                                                    }
                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                />
                                                {props.promptVariantForm.prompt_variant_model_data.some((variant) => variant.model_id == value.model_id) &&
                                                    <FormControlLabel
                                                        sx={{ marginLeft: "20px" }}
                                                        control={
                                                            <Switch
                                                                checked={props.promptVariantForm.prompt_variant_model_data.find((variant) => variant.model_id == value.model_id).strict_model_flag}
                                                                onChange={(e) => { handleChange(e.target.checked, value) }}
                                                            />
                                                        }
                                                        label="Strict Mode (priority)"
                                                    />}
                                            </>
                                        }
                                        disablePadding
                                    >
                                        <ListItemButton>
                                            <ListItemText id={labelId} primary={value.model_name} />
                                        </ListItemButton>
                                    </ListItem>
                                );
                            })}
                        </List>
                    }
                    {props.promptVariantForm.prompt_variant_model_data.some((variant) => variant.model_name === "gemini-pro") && (
                        <>
                            <h2>Gemini-Pro Examples</h2>
                            {props.promptVariantForm.prompt_variant_example_data.map((example, index) => (
                                <Grid item>
                                    <Grid container justifyContent={"space-between"}>
                                        <Grid item>
                                            <Grid container direction="column">
                                                <Grid item >
                                                    <InputLabel className={globalStyles.labelStyle} >Prompt Variant Example Name<Required /></InputLabel>
                                                    <TextField
                                                        className={globalStyles.globalTextfieldStyle}
                                                        fullWidth
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={example.prompt_variant_example_name}
                                                        onChange={(e) => handleExampleChange(e.target.value, index, 'prompt_variant_example_name')}
                                                    />
                                                </Grid>
                                                <Grid item >
                                                    <InputLabel className={globalStyles.labelStyle} >Prompt Variant Example Input<Required /></InputLabel>
                                                    <TextField
                                                        className={globalStyles.globalTextfieldStyle}
                                                        fullWidth
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={example.prompt_variant_example_input} onChange={(e) => handleExampleChange(e.target.value, index, 'prompt_variant_example_input')}
                                                    />
                                                </Grid>
                                                <Grid item >
                                                    <InputLabel className={globalStyles.labelStyle} >Prompt Variant Example Output<Required /></InputLabel>
                                                    <TextField
                                                        className={globalStyles.globalTextfieldStyle}
                                                        fullWidth
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={example.prompt_variant_example_output} onChange={(e) => handleExampleChange(e.target.value, index, 'prompt_variant_example_output')}
                                                    />
                                                </Grid>
                                                <Grid item >
                                                    <InputLabel className={globalStyles.labelStyle} >Prompt Variant Example Seq Num.<Required /></InputLabel>
                                                    <TextField
                                                        className={globalStyles.globalTextfieldStyle}
                                                        fullWidth
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        type="number"
                                                        value={example.prompt_variant_example_seq_num} onChange={(e) => handleExampleChange(e.target.value, index, 'prompt_variant_example_seq_num')}
                                                    />
                                                </Grid>

                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <button onClick={() => handleDeleteExample(index)}>-</button>
                                        </Grid>
                                    </Grid>

                                </Grid>

                            ))}
                            {
                                <GlobalButton buttonText="Add Example" onClick={handleAddExample} />
                            }
                        </>
                    )}
                    {/* output format example */}
                    <Grid item sx={{ marginBottom: "20px" }}>
                        <InputLabel className={globalStyles.labelStyle} >output_format_example</InputLabel>
                        <TextareaAutosize
                            className={globalStyles.globalTextfieldStyle}
                            style={{ width: "100%" }}
                            minRows={6}
                            fullWidth
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={props.promptVariantForm.output_format_example}
                            onChange={(e) => { props.setPromptVariantForm({ ...props.promptVariantForm, output_format_example: e.target.value }) }}
                        />
                    </Grid>
                    {/* prompt context data */}
                    <Grid item sx={{ marginBottom: "20px" }}>
                        <InputLabel className={globalStyles.labelStyle} >prompt_context_data</InputLabel>
                        <TextareaAutosize
                            className={globalStyles.globalTextfieldStyle}
                            style={{ width: "100%" }}
                            minRows={6}
                            fullWidth
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={props.promptVariantForm.prompt_context_data}
                            onChange={(e) => { props.setPromptVariantForm({ ...props.promptVariantForm, prompt_context_data: e.target.value }) }}
                        />
                    </Grid>
                    {/* prompt action data */}
                    <Grid item sx={{ marginBottom: "20px" }}>
                        <InputLabel className={globalStyles.labelStyle} >prompt_action_data</InputLabel>
                        <TextareaAutosize
                            className={globalStyles.globalTextfieldStyle}
                            fullWidth
                            style={{ width: "100%" }}
                            minRows={6}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={props.promptVariantForm.prompt_action_data}
                            onChange={(e) => { props.setPromptVariantForm({ ...props.promptVariantForm, prompt_action_data: e.target.value }) }}
                        />
                    </Grid>
                    {/* prompt output key name */}
                    <Grid item sx={{ marginBottom: "20px" }}>
                        <InputLabel className={globalStyles.labelStyle} >prompt_output_key_name</InputLabel>
                        <TextField
                            className={globalStyles.globalTextfieldStyle}
                            fullWidth
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={props.promptVariantForm.prompt_output_key_name}
                            onChange={(e) => { props.setPromptVariantForm({ ...props.promptVariantForm, prompt_output_key_name: e.target.value }) }}
                        />
                    </Grid>
                    {/* prompt flag output data */}
                    <Grid item sx={{ marginBottom: "20px" }}>
                        <InputLabel className={globalStyles.labelStyle} >prompt_flag_output_data</InputLabel>
                        <Select
                            className={globalStyles.globalTextfieldStyle}
                            sx={{ width: "100%" }}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={props.promptVariantForm.prompt_flag_output_data
                            }
                            label="template_id"
                            onChange={(e) => { props.setPromptVariantForm({ ...props.promptVariantForm, prompt_flag_output_data: e.target.value }) }}
                        >
                            <MenuItem value={true}>True</MenuItem>
                            <MenuItem value={false}>False</MenuItem>
                        </Select>
                    </Grid>
                </div>
                {/* create prompt button */}
                <Grid item sx={{ marginBottom: "20px" }}>
                    <GlobalButton buttonText={props.promptVariantForm.prompt_variant_id ? "Update Prompt" : "Create prompt"} className="primaryButtonStyle" onClick={props.handleCreatePromptVariant} />
                </Grid>
            </Grid>
        </>
    )
}

export default PromptVariantForm;