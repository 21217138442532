import { Button, Dialog, Grid } from '@mui/material'
import React from 'react'
import globalStyles from "../assets/styles/global.module.scss"
import deleteConfirm from "../assets/deleteConfirm.svg"

export default function GlobalDeletePopup(props) {
  return (
    <div>
        <Dialog open={true}>
            <Grid container >
                <Grid item className={globalStyles.deleteConfirmHeaderPopup}>
                    <Grid container className={globalStyles.globalDeleteLogoHeadContainer}>
                        <Grid item xs={1} className={globalStyles.deleteConfirmationLogoItem}>
                            <img src={deleteConfirm} className={globalStyles.deleteConfirmImage} alt='delete'/>
                        </Grid>
                        <Grid item xs={9}>
                            <Grid container>
                                <Grid item xs={12} className={globalStyles.deleteConfirmPopupTextStyle}>
                                    Delete Confirmation
                                </Grid>
                                <Grid item xs={12} className={globalStyles.deleteConfirmPopupSubTextStyle}>
                                    {props.deleteDescription}?
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} className={globalStyles.deleteConfirmButtonsPopup}>
                    <Grid container justifyContent="end" gap={2}>
                        <Grid item>
                            <Button buttonText='Cancel' className={globalStyles.cancelButton}
                                onClick={props.onClose}>Cancel</Button>
                        </Grid>
                        <Grid item >
                            <Button className={globalStyles.deleteButton}
                                onClick={props.onDelete}>Delete</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Dialog>
    </div>
  )
}
