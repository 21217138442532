import DraggableList from "react-draggable-list";
import { useContext, useEffect, useRef, useState } from "react";
import { Grid, TextField } from "@mui/material";
import deleteIcon from "../assets/delete.svg";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import styles from "../assets/styles/customTemplateDraggableList.module.scss";
import addFieldIcon from "../assets/addField.svg";
import dragIndicatorIcon from "../assets/dragIndicatorIcon.svg";
import globalStyles from "../assets/styles/global.module.scss";
import { TemplateContext } from "../context/templateContext";
// const data = Array(10)
//     .fill(null)
//     .map((item, index) => ({ id: index }));


const data = [
    {
        id: 0,
        template_form_field_id: "ud_12",
        form_field_input_type_id: 0,
        template_form_field_seq_num: 0,
        template_form_field_property: [
            {
                form_field_input_type_property_id: 0,
                template_form_field_property_value: "What is this blog about",
            }
        ]
    },
    {
        id: 1,
        template_form_field_id: "ud_12",
        form_field_input_type_id: 0,
        template_form_field_seq_num: 0,
        template_form_field_property: [
            {
                form_field_input_type_property_id: 0,
                template_form_field_property_value: "SEO Keywords",
            }
        ]
    },
    {
        id: 2,
        template_form_field_id: "ud_12",
        form_field_input_type_id: 0,
        template_form_field_seq_num: 0,
        template_form_field_property: [
            {
                form_field_input_type_property_id: 0,
                template_form_field_property_value: "Targeted Audience",
            },
            {
                form_field_input_type_property_id: 0,
                template_form_field_property_value: "Targeted Audience",
            },
            {
                form_field_input_type_property_id: 0,
                template_form_field_property_value: "Targeted Audience",
            }
        ]
    },
    {
        id: 3,
        template_form_field_id: "ud_12",
        form_field_input_type_id: 0,
        template_form_field_seq_num: 0,
        template_form_field_property: [
            {
                form_field_input_type_property_id: 0,
                template_form_field_property_value: "Tone of voice",
            }
        ]
    },
    {
        id: 4,
        template_form_field_id: "ud_12",
        form_field_input_type_id: 0,
        template_form_field_seq_num: 0,
        template_form_field_property: [
            {
                form_field_input_type_property_id: 0,
                template_form_field_property_value: "Tone of voice",
            }
        ]
    },
    {
        id: 5,
        template_form_field_id: "ud_12",
        form_field_input_type_id: 0,
        template_form_field_seq_num: 0,
        template_form_field_property: [
            {
                form_field_input_type_property_id: 0,
                template_form_field_property_value: "Tone of voice",
            }
        ]
    }
]
const example = {
    "template_name": "string",
    "template_icon_url": "string",
    "template_description": "string",
    "template_prompt_data": {
        "prompt_data": "string"
    },
    "template_step_form_data": [
        {
            "template_form_name": "string",
            "template_form_field_data": [
                {
                    "template_form_field_id": "ud_12",
                    "form_field_input_type_id": 0,
                    "template_form_field_seq_num": 0,
                    "template_form_field_property": [
                        {
                            "form_field_input_type_property_id": 0,
                            "template_form_field_property_value": "string",
                        }
                    ]
                }
            ]
        }
    ]
}

const Item = ({ item, itemSelected, dragHandleProps, commonProps }) => {
    const { onMouseDown, onTouchStart } = dragHandleProps;
    const handleFormFieldNameChange = (value, id, template_form_field_id) => {
        let temp = { type: "add", value: value, id: id, template_form_field_id: template_form_field_id }
        commonProps(temp)
    }
    const deleteFormField = (id, template_form_field_id) => {
        let temp = { type: "delete", id: id, template_form_field_id: template_form_field_id };
        commonProps(temp)
    }
    return (
        <>
            <Grid container justifyContent={"space-between"} alignItems={"center"} className={styles.fieldItemStyle} >
                <Grid item>
                    <Grid container alignItems={"center"} justifyContent={"space-between"}>
                        <Grid item
                            className="disable-select dragHandle"
                            onTouchStart={(e) => {
                                e.preventDefault();
                                document.body.style.overflow = "hidden";
                                onTouchStart(e);
                            }}
                            onMouseDown={(e) => {
                                document.body.style.overflow = "hidden";
                                onMouseDown(e);
                            }}
                            onTouchEnd={(e) => {
                                document.body.style.overflow = "visible";
                            }}
                            onMouseUp={() => {
                                document.body.style.overflow = "visible";
                            }}
                        ><DragIndicatorIcon
                                className={styles.draggableIconStyle} /></Grid>
                        <Grid item>
                            <TextField value={item.template_form_field_property[0].template_form_field_property_value} onChange={(event) => { handleFormFieldNameChange(event.target.value, item.id, item.template_form_field_id) }} className={styles.fieldTextfieldstyle}
                            // value={item.template_form_field_property[0].template_form_field_property_value} 
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <img src={deleteIcon} onClick={() => (deleteFormField(item.id, item.template_form_field_id))} className={styles.deleteIconStyle} />
                </Grid>
            </Grid>
        </>
    );
};

export default function DraggableListComponent() {
    // const [templateFormFieldList, setTemplateFormFieldList] = useState(data);
    const { templateFormFieldsGenericData, templateFormFieldList, setTemplateFormFieldList } = useContext(TemplateContext);

    console.log("templateFormFieldList", templateFormFieldList)
    const containerRef = useRef();

    const _onListChange = (newList) => {
        newList.forEach((item, index) => {
            item.template_form_field_seq_num = index + 1;
        });
        setTemplateFormFieldList(newList);
    };
    const addFormField = () => {
        const longTextFieldType = templateFormFieldsGenericData.find(
            (fieldType) => fieldType.form_field_input_type_name === "Long Text"
        );


        if (longTextFieldType) {
            // Create a new form field object
            const newFormField = {
                id: templateFormFieldList.length, // Index of the new item
                template_form_field_id: `ud-${Math.floor(Math.random() * 10000)}`, // Generate a random 4-digit number attached with "ud-"
                form_field_input_type_name: longTextFieldType.form_field_input_type_name,
                form_field_input_type_id: longTextFieldType.form_field_input_type_id,
                template_form_field_seq_num: templateFormFieldList.length + 1, // Index of the new item
                template_form_field_property: [
                    {
                        form_field_input_type_property_id: longTextFieldType.form_field_input_type_property.find(
                            (property) => property.form_field_input_type_property_name === "Title"
                        ).form_field_input_type_property_id,
                        template_form_field_property_value: "Input",
                        form_field_input_type_property_name: longTextFieldType.form_field_input_type_property.find(
                            (property) => property.form_field_input_type_property_name === "Title"
                        ).form_field_input_type_property_name
                    },
                    {
                        form_field_input_type_property_id: longTextFieldType.form_field_input_type_property.find(
                            (property) => property.form_field_input_type_property_name === "Placeholder"
                        ).form_field_input_type_property_id,
                        template_form_field_property_value: `Start typing here`,
                        form_field_input_type_property_name: longTextFieldType.form_field_input_type_property.find(
                            (property) => property.form_field_input_type_property_name === "Placeholder"
                        ).form_field_input_type_property_name
                    },
                    {
                        form_field_input_type_property_id: longTextFieldType.form_field_input_type_property.find(
                            (property) => property.form_field_input_type_property_name === "Is Required?"
                        ).form_field_input_type_property_id,
                        template_form_field_property_value: false, // Set to false by default
                        form_field_input_type_property_name: longTextFieldType.form_field_input_type_property.find(
                            (property) => property.form_field_input_type_property_name === "Is Required?"
                        ).form_field_input_type_property_name
                    },
                ],
            };

            // Push the new form field object into the templateFormFieldList
            setTemplateFormFieldList([...templateFormFieldList, newFormField]);
        }
    }
    const listDataUpdate = (data) => {
        if (data.type == "add") {
            let temp = [...templateFormFieldList];
            temp.map((item, id) => {
                if (item.template_form_field_id === data.template_form_field_id) {
                    return item.template_form_field_property[0].template_form_field_property_value = data.value
                }
            })
            setTemplateFormFieldList(temp)
        } else {
            let temp = [...templateFormFieldList];
            let filteredList = temp.filter((item) => (item.template_form_field_id !== data.template_form_field_id))
            setTemplateFormFieldList(filteredList)
        }

    }

    return (
        <Grid container
            direction={"column"}
            ref={containerRef}
            className={styles.dragDropContainer}
            sx={{ touchAction: "pan-y" }}
        >
            <Grid item className={styles.upperBlock}>
                <Grid container justifyContent={"space-between"}>
                    <Grid item className={styles.fieldListTextStyle}>Field list</Grid>
                    {templateFormFieldList.length > 0 &&
                        <Grid item>
                            <Grid container justifyContent={"center"} alignItems={"center"}>
                                <Grid item>
                                    <img src={addFieldIcon} onClick={addFormField} className={globalStyles.clickable} />
                                </Grid>
                                <Grid item className={styles.AddFieldTextStyle}>
                                    Add Input Field
                                </Grid>
                            </Grid>
                        </Grid>}
                </Grid>
            </Grid>
            <Grid item className={styles.draggableListContainer}>
                <Grid container>
                    {templateFormFieldList.length > 0 ?
                        <DraggableList
                            itemKey="id"
                            template={Item}
                            list={templateFormFieldList}
                            commonProps={listDataUpdate}
                            onMoveEnd={(newList) => _onListChange(newList)}
                            container={() => containerRef.current}
                        />
                        :
                        <Grid item className={globalStyles.fullwidth}>
                            <Grid container direction={"column"} justifyContent={"center"} alignItems={"center"}>
                                <Grid item>
                                    <img src={addFieldIcon} className={globalStyles.clickable} onClick={addFormField} />
                                </Grid>
                                <Grid item className={styles.AddFieldTextStyle}>
                                    Add Input Field
                                </Grid>
                            </Grid>
                        </Grid>
                    }

                </Grid>
            </Grid>
            {/* {templateFormFieldList.map((item) => (
          <Item item={item} />
        ))} */}
        </Grid>
    );
}
