import React, { useEffect, useState } from 'react'
import './fileUpload.css'
import axios from 'axios'
import LinearProgress from '@mui/material/LinearProgress';
import { Alert, Box, Typography, Snackbar } from "@mui/material";
import Grid from '@mui/material/Grid';
import DeleteIcon from '@mui/icons-material/Delete';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import PropTypes from 'prop-types';
import { isAxiosError } from 'axios';

var FormData = require('form-data');
function LinearProgressWithLabel(props) {
    console.log("propssssssss", props);
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
};
const FileUpload = (props) => {
    console.log("props", props);
    const [files, setFiles] = useState(props.final_g_id ? props.final_g_id : []);
    const [progress, setProgress] = useState({ filename: "", percent: 0 });
    const [fileError, setFileError] = React.useState({ status: false, message: "" });

    useEffect(() => {
        if (props.fileFlag != null)
            if (props.fileFlag.status == true && props.fileFlag.submission_id == null) {
                setProgress({ filename: "", percent: 0 })
                setFiles([])
                props.saveData([])
            }
    }, [props.fileFlag])

    useEffect(() => {
        console.log("props")
        // props.setFileValue(props.filesFromParent)
    }, [props.filesFromParent])

    // const removeFile = (google_id) => {
    //     var google_s_id = {};
    //     google_s_id['file_id'] = google_id
    //     const fileSet = props.filesFromParent.filter(file => file.google_storage_id !== google_id)
    //     // FileDeleteAPI(google_s_id)
    //     var finalFiles = [...fileSet];
    //     // debugger;
    //     // setFiles(finalFiles)
    //     props.filesFromParent(finalFiles)
    //     // setProgress({ filename: "", percent: 0 })
    //     // props.saveData(finalFiles)
    //     // document.getElementById('image').value = "";
    // }

    const formatFileSize = (bytes, decimalPoint) => {
        if (bytes == 0) return '0 Bytes';
        var k = 1000,
            dm = decimalPoint || 2,
            sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
            i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    const RemoveConfirmation = (data) => {

        setFileError({
            status: false,
            message: ""
        })
        const result = window.confirm("Are you sure you want to remove the the file?");

        if (result) {
            props.removeFile(data)
        } else {
            return
        }

    }

    const validationFile = async (event) => {
        // debugger;

        console.log("filesFromPArent------------", props.filesFromParent);
        console.log(props.fileDetails);
        console.log(event.target);
        console.log(event.target.files);
        console.log(event.target.files);

        if (!event.target.files) return false;
        // debugger;
        if (
            event.target.files.length > props.fileDetails.multiple_file || (event.target.files.length + props.filesFromParent.length > props.fileDetails.multiple_file)) {
            setFileError({
                status: true,
                message: "You can only upload up to " + props.fileDetails.multiple_file + " files"
            })
            return false
        } else {
            setFileError({
                status: false,
                message: ""
            })
        }

        const file = event.target.files;

        const filevalues = Object.values(file);
        var findFilesizeError = filevalues.filter(rr => ((rr.size / 1048576) > props.fileDetails.file_size));
        if (findFilesizeError.length > 0) {
            setFileError({
                status: true,
                message: "Please upload a file smaller than " + props.fileDetails.file_size + " MB"
            })
            return false;
        }
        // checkFileType
        var checkFileTypeFlag = false
        var filename = filevalues.map(rr => {
            if (rr.name) {
                var getFilenameExtension = rr.name.split('.').pop();
                if (props.fileType.indexOf(getFilenameExtension) == -1) {
                    checkFileTypeFlag = true
                }
            }
        })
        if (checkFileTypeFlag) {
            setFileError({
                status: true,
                message: "The file type you selected is invalid. Please select this file type :" + props.fileType
            })
            return false
        }
        return true
    }

    const uploadHandler = async (event) => {
        var checkFilevalidation = await validationFile(event)
        if (!checkFilevalidation) {
            return
        }
        if (!event.target.files.length) return;

        props.fileUploadhandler(event)
        // for (var i = 0; i < event.target.files.length; i++) {
        //     let file = event.target.files[i];
        //     console.log(file);
        //     file.isUploading = true;
        //     setFiles(old => {
        //         let temp = [...old]
        //         temp.push(file)
        //         return temp
        //     })

        //     let formData = new FormData()
        //     formData.append(
        //         "file", file, file.name)

        //     let config = {
        //         onUploadProgress: (progressEvent) => {
        //             const { loaded, total } = progressEvent;
        //             var percent = Math.floor((loaded * 100) / total)
        //             setProgress({ ...progress, percent: percent, filename: file.name })
        //             console.log("progress", [progress, setProgress])
        //         }
        //     }

        //     axios.post('https://api.writewiz-staging.in/api/storage/file', formData, {

        //         headers: {
        //             'Content-Type': 'multipart/form-data'
        //         },
        //         withCredentials: true,
        //         onUploadProgress: config.onUploadProgress,
        //     }
        //     )
        //         .then((res) => {



        //             file.isUploading = false;
        //             file.file_data = res.data.data;
        //             file.google_storage_id = res.data.data.google_storage_id;
        //             setFiles(old => {
        //                 let temp = [...old]
        //                 temp.map(rr => {
        //                     if (rr.lastModified == file.lastModified) {
        //                         rr.isUploading = false;
        //                         rr.file_data = res.data.data;
        //                         rr.google_storage_id = res.data.data.google_storage_id;
        //                     }
        //                 })
        //                 return temp
        //             })
        //             // setFiles([...files, file]);

        //             let images = [];

        //             for (let i = 0; i < event.target.files.length; i++) {
        //                 images.push(URL.createObjectURL(event.target.files[i]))
        //             }
        //             // setfileImg(images)
        //             props.saveData([res.data.data])

        //             //    setfileImg(URL.createObjectURL(event.target.files));
        //         })
        //         .catch((err) => {
        //             // inform the user
        //             console.error(err)
        //             removeFile(file.name)
        //         });

        // }
    }

    console.log("filessss", props.filesFromParent);
    return (
        <>
            <Grid container>
                <Grid item className="file-card" direction="column">
                    {props.fileDetails.is_avatar ?
                        <Grid item>
                            <Grid container style={{ marginTop: "5px" }}>
                                <Grid item xs={0.7} className="avatar_file_upload">
                                    <img src={'https://api.filemaster.io/api/storage/file?file_id=' + (props.fileDetails.value.length ? props.fileDetails.value[0] : "2516") + '&flag_info=false'} style={{ height: "37px" }} />
                                </Grid>
                                <Grid item xs={5}>
                                    <Grid container className="file-inputs-avatar">
                                        <input
                                            id="image"
                                            accept='image/*'
                                            type="file"
                                            multiple={props.multiple_file_alllow}
                                            className="file-inputs-avatar"
                                            onChange={(e) => { uploadHandler(e) }} >
                                        </input>
                                        <button className="file-inputs-avatar" style={{ color: "#4C4A4D", boxShadow: "none !important" }}>
                                            <FileUploadOutlinedIcon />
                                            <span>
                                                {props.fileDetails.placeholder}
                                            </span>
                                        </button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        :
                        <Grid container direction="column">
                            <Grid item className="file-inputs">

                                <input
                                    id="image"
                                    accept={props.fileType}
                                    type="file"
                                    multiple
                                    className="file-inputs"
                                    onChange={uploadHandler} >
                                </input>

                                <button className="file-inputs" style={{ color: "#4C4A4D", display: "flex", flexDirection: "column" }}>
                                    <FileUploadOutlinedIcon />
                                    {props.filePlaceholderText && props.filePlaceholderText.length && props.filePlaceholderText[0].field_option_value != "" ?
                                        <span style={{ fontSize: "14px" }}>
                                            {props.filePlaceholderText[0].field_option_value}
                                        </span> :
                                        <span style={{ fontSize: "14px" }}>Drag & Drop files here, or <span style={{ color: "#2765FF" }}>Browse</span></span>
                                    }
                                </button>
                            </Grid>
                            <>
                                {/* {props.fileFlag == true ? "" : */}
                                <>
                                    {props.filesFromParent && props.filesFromParent.map((fileArray) => {

                                        var roundedNumber = formatFileSize(fileArray.size ? fileArray.size : 0)

                                        return <>
                                            {fileArray.page_field_id == props.fileDetails.page_field_id && <Grid item className={!fileArray.google_storage_id ? "file_item" : "file_item_after_uploaded"}>
                                                <Grid Container className="file_background_design">
                                                    <Grid item direction="column">
                                                        <Grid container className="delete_container">
                                                            <Grid item xs={7} className="file_name">
                                                                {/* <img className="file_type_uploaded"
                                                                    src={fileArray.type == "image/jpeg" ? jpg :
                                                                        fileArray.type == "image/png" ? png :
                                                                            fileArray.type == "application/x-zip-compressed" ? zip :
                                                                                fileArray.type == "application/pdf" ? pdf :
                                                                                    fileArray.type == "application/msword" ? doc :
                                                                                        fileArray.type == "audio/mpeg" ? mp3 :
                                                                                            fileArray.type == "application/rtf" ? rtf :
                                                                                                fileArray.type == "application/vnd.ms-excel" ? xls :
                                                                                                    fileArray.type == "text/plain" ? txt :
                                                                                                        fileNoType
                                                                    } /> */}
                                                                &nbsp;
                                                                <span className="file_name_txt">{fileArray.name}  </span>
                                                                &nbsp;
                                                                &nbsp;
                                                                <span className="file_size"> {roundedNumber}</span>
                                                            </Grid>
                                                            {fileArray.google_storage_id &&
                                                                <>
                                                                    <Grid item xs={4} className="delete_icon">
                                                                        <DeleteIcon className="delete_onclick" onClick={() => {
                                                                            RemoveConfirmation(fileArray.google_storage_id)
                                                                        }} />
                                                                    </Grid>
                                                                </>
                                                            }
                                                        </Grid>
                                                    </Grid>

                                                    {!fileArray.google_storage_id && (props.progressFromParent.filename == fileArray.name) ?
                                                        <Grid item>
                                                            <Box sx={{ width: '100%' }}>
                                                                <LinearProgressWithLabel value={props.progressFromParent.percent} />
                                                            </Box>
                                                        </Grid>
                                                        : ""}
                                                </Grid>
                                            </Grid>}
                                        </>
                                        // }
                                    })}
                                </>
                                {/* } */}
                            </>
                            {fileError.status == true ?

                                <Grid item style={{ color: "red", fontSize: "14px", paddingTop: "3px" }}>
                                    {fileError.message}
                                </Grid>
                                : ""}
                        </Grid>
                    }

                </Grid>
            </Grid >
        </>
    )
}

export default FileUpload;