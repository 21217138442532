import React, { useContext, useEffect, useState } from 'react'
import UserNavbar from './UserNavbar'
import { Grid } from '@mui/material';
import GlobalDataGrid from '../../components/globalDataGrid';
import agent from '../../api/api';
import { UserContext } from '../../context/userContext';
import { UserDetailsContext } from '../../context/userDetailsContext';
import styles from '../../assets/styles/user.module.scss'
import moment from "moment";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import RefreshIcon from '@mui/icons-material/Refresh';
import RoundLoader from '../../components/roundLoader';
import { checkError } from '../../utils/constants';

const User = () => {

    const { userDetail, setFlagLoading, setError, error } = useContext(UserContext);
    const { usersDetails, setUsersDetails } = useContext(UserDetailsContext)

    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const now = new Date();
    const offsetMinutes = now.getTimezoneOffset();
    const offsetHours = Math.floor(Math.abs(offsetMinutes) / 60);
    const offsetMinutesRemainder = Math.abs(offsetMinutes) % 60;
    const offsetSign = offsetMinutes < 0 ? '+' : '-';
    const offsetFormatted = `GMT${offsetSign}${offsetHours}:${offsetMinutesRemainder}`;

    const [showLaoder, setShowLoader] = useState(false)
    const userDetailColumns = [
        {
            field: 'customer_username',
            headerName: 'User Name',
            flex: 1
        },
        {
            field: 'customer_email',
            headerName: 'User Email Address',
            flex: 1
        },
        {
            field: 'customer_registration_timestamp',
            headerName: 'Created Date',
            flex: 1
        },
        {
            field: 'customer_registration_type',
            headerName: 'Register Type',
            flex: 1
        },
        {
            field: 'customer_ip_geo_location',
            headerName: 'Country',
            flex: 1
        },
        {
            field: 'plan_name',
            headerName: 'Current Plan',
            flex: 1
        },
        // {
        //     field: 'customer_ip_geo_location',
        //     headerName: 'Prompt Used',
        //     flex: 1
        // },

    ]

    useEffect(() => {
        if (userDetail) {
            let customer_email = String(userDetail.user_email)
            // console.log("customer_email", customer_email, userDetail);
            const fetchData = async () => {
                try {
                    setFlagLoading(true)
                    const response = await agent.getUserDetails.get(customer_email);
                    // const response = await agent.getUserDetails.get('jndanklml');
                    if (response.status) {
                        if (response.data) {
                            const userData = response.data
                            setUsersDetails(userData)
                            setFlagLoading(false)
                        }
                        else {
                            setUsersDetails([])
                            setFlagLoading(false)
                            setError({ ...error, flagShowError: true, message: "Something went wrong!" })
                        }
                    }
                    else {
                        // const errorMessage = response.error?.message || "Something went wrong!";
                        setError({ ...error, flagShowError: true, message: checkError(response) });
                        setFlagLoading(false)
                    }
                } catch (error) {
                    console.error("Error fetching user details:", error);
                    setError({ ...error, flagShowError: true, message: "Something went wrong!" })
                    setFlagLoading(false)
                }
            };

            fetchData();
        }
    }, []);

    const userDetailRows = usersDetails !== null && usersDetails.map((ele, i) => {
        return {
            id: i,
            customer_username: ele.customer_username,
            customer_email: ele.customer_email,
            customer_registration_timestamp: moment(ele.customer_registration_timestamp).format('DD-MMM-yyyy'),
            customer_registration_type: ele.customer_registration_type,
            customer_role_name: ele.customer_role_name,
            plan_name: ele.customer_plan_details.map((customerPlanDetails) => {
                return customerPlanDetails.plan_name
            }),
            customer_ip_geo_location: ele.customer_ip_geo_location
        }
    })

    const getUserListData = async () => {
        setShowLoader(true)
        let userEmail = String(userDetail.user_email)
        const response = await agent.getUserDetails.get(userEmail);
        if (response.status) {
            if (response.data) {
                const userData = response.data
                setUsersDetails(userData)
                setShowLoader(false)
            }
            else {
                setUsersDetails([])
                setShowLoader(false)
            }
        }
    }



    return (
        <>
            {showLaoder && <RoundLoader />}
            <UserNavbar />
            <Grid container>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={12} sx={{ margin: "20px 0px 10px" }}>
                            <Grid container className={styles.userTimeZoneMainContainer}>
                                <Grid item xs={8} className={styles.userTimeZoneMainItem}>
                                    <Grid container className={styles.userTimeZoneSubItem}>
                                        <Grid item className={styles.userTimeZoneItem}>
                                            {timezone && (
                                                <div style={{ display: "flex", justifyContent: "end", fontSize: "15px", fontWeight: "500" }}>
                                                    {`${timezone} (${offsetFormatted})`}
                                                </div>
                                            )}
                                        </Grid>
                                        <Grid item className={styles.userRefreshItem}>
                                            <IconButton aria-label="delete" size="medium">
                                                <RefreshIcon
                                                    fontSize="inherit"
                                                    style={{ fontWeight: "bold", color: "black" }}
                                                    onClick={getUserListData}
                                                />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sx={{ margin: "10px 0px 10px" }}>
                            <GlobalDataGrid
                                rows={userDetailRows ? userDetailRows : []}
                                columns={userDetailColumns}
                                // className={styles.userDatatable}
                                refresh={getUserListData}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default User