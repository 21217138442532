import React, { useContext } from 'react'
import { Grid } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import styles from "../../assets/styles/UserNavbar.module.scss";
import Divider from '@mui/material/Divider';
import { UserContext } from '../../context/userContext';

const UserNavbar = (props) => {

    const { userDetail, } = useContext(UserContext);



    function handleClick(event) {
        event.preventDefault();
        console.info('You clicked a breadcrumb.');
    }


    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Stack>
                                <Breadcrumbs separator={<ArrowForwardIcon sx={{ color: "#939393" }} />} aria-label="breadcrumb" className={styles.brcMain}>
                                    <Link underline="hover" key="1" color="inherit" href="/" onClick={handleClick} className={`${styles.brcUserText}`}>
                                        User
                                    </Link>,
                                    <Link
                                        underline="hover"
                                        key="2"
                                        // href="/material-ui/getting-started/installation/"
                                        onClick={handleClick}
                                        className={`${styles.brcUserProfileText}`}
                                    >
                                        {userDetail.user_first_name}
                                    </Link>,
                                </Breadcrumbs>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} sx={{ marginTop: "14px" }}>
                            <Divider sx={{ color: "black", border: "1px solid #DFDFDF" }}></Divider>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default UserNavbar 