import * as React from "react";
import { Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import Required from "../../components/required";
import globalStyles from "../../assets/styles/global.module.scss";
import GlobalButton from "../../components/button";
import { GenericDataContext } from "../../context/GenericData";


const CreateLoaderDetail = (props) => {
    const { loaderUserTypeDataList, moduleDataList } = React.useContext(GenericDataContext);
    const changeImageGroupAssignmentData = (keyToChange, value) => {
        props.setCreateImageGroupAssignmentData(prevState => ({
            ...prevState,
            image_group_assignment_data: prevState.image_group_assignment_data.map((data, index) => {
                if (index === 0) {
                    return { ...data, [keyToChange]: value };
                }
                return data;
            })
        }));
    };
    return (
        <>
            <Grid container direction={"column"} className={`${globalStyles.globalContainer}`}>
                <div style={{ marginBottom: "15px" }}><Typography sx={{ color: "#6A097D", fontSize: "15px", fontWeight: "600", }}>{props.createImageGroupAssignmentData.image_group_assignment_data[0].image_group_assignment_id ? "Update" : "Create"} Loader Details</Typography></div>
                <div className={globalStyles.variantForm}>
                    {/* image Group Id */}
                    <Grid item >
                        <InputLabel className={globalStyles.labelStyle} >Image Group Id<Required /></InputLabel>
                        <TextField
                            className={globalStyles.globalTextfieldStyle}
                            fullWidth
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={(props.createImageGroupAssignmentData.image_group_id || "")}
                            onChange={(e) => { props.setCreateImageGroupAssignmentData({ ...props.createImageGroupAssignmentData, image_group_id: Number(e.target.value) }) }}
                        />
                    </Grid>
                    {/* BG Img google storage id */}
                    <Grid item sx={{ marginBottom: "20px" }}>
                        <InputLabel className={globalStyles.labelStyle} >Bg Img Google Storage Id<Required /></InputLabel>
                        <TextField
                            className={globalStyles.globalTextfieldStyle}
                            fullWidth
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={(props.createImageGroupAssignmentData.image_group_assignment_data[0].background_image_google_storage_id || "")}
                            onChange={(e) => changeImageGroupAssignmentData('background_image_google_storage_id', Number(e.target.value))}
                        />
                    </Grid>
                    {/* Icon image google storage id */}
                    <Grid item >
                        <InputLabel className={globalStyles.labelStyle} >Icon Image Google Storage Id<Required /></InputLabel>
                        <TextField
                            className={globalStyles.globalTextfieldStyle}
                            fullWidth
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={(props.createImageGroupAssignmentData.image_group_assignment_data[0].icon_image_google_storage_id || "")}
                            onChange={(e) => changeImageGroupAssignmentData('icon_image_google_storage_id', Number(e.target.value))}
                        />
                    </Grid>
                    {/* Image Heading*/}
                    <Grid item >
                        <InputLabel className={globalStyles.labelStyle} >Image Heading<Required /></InputLabel>
                        <TextField
                            className={globalStyles.globalTextfieldStyle}
                            fullWidth
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={props.createImageGroupAssignmentData.image_group_assignment_data[0].image_heading || ""}
                            onChange={(e) => changeImageGroupAssignmentData('image_heading', e.target.value)}
                        />
                    </Grid>
                    {/* Image Description*/}
                    <Grid item >
                        <InputLabel className={globalStyles.labelStyle} >Image Description<Required /></InputLabel>
                        <TextField
                            className={globalStyles.globalTextfieldStyle}
                            fullWidth
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={props.createImageGroupAssignmentData.image_group_assignment_data[0].image_description || ""}
                            onChange={(e) => changeImageGroupAssignmentData('image_description', e.target.value)}
                        />
                    </Grid>
                    {/* CTA btn Text */}
                    <Grid item >
                        <InputLabel className={globalStyles.labelStyle} >CTA Button Text<Required /></InputLabel>
                        <TextField
                            className={globalStyles.globalTextfieldStyle}
                            fullWidth
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={props.createImageGroupAssignmentData.image_group_assignment_data[0].cta_button_text || ""}
                            onChange={(e) => changeImageGroupAssignmentData('cta_button_text', e.target.value)}
                        />
                    </Grid>
                    {/* CTA btn google storage id*/}
                    <Grid item >
                        <InputLabel className={globalStyles.labelStyle} >CTA Button Icon Google Storage Id<Required /></InputLabel>
                        <TextField
                            className={globalStyles.globalTextfieldStyle}
                            fullWidth
                            type="number"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={props.createImageGroupAssignmentData.image_group_assignment_data[0].cta_button_icon_google_storage_id || ""}
                            onChange={(e) => changeImageGroupAssignmentData('cta_button_icon_google_storage_id', e.target.value)}
                        />
                    </Grid>
                    {/* CTA button link*/}
                    <Grid item >
                        <InputLabel className={globalStyles.labelStyle} >CTA Button link<Required /></InputLabel>
                        <TextField
                            className={globalStyles.globalTextfieldStyle}
                            fullWidth
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={props.createImageGroupAssignmentData.image_group_assignment_data[0].cta_button_link || ""}
                            onChange={(e) => changeImageGroupAssignmentData('cta_button_link', e.target.value)}
                        />
                    </Grid>
                    {/* CTA button related field*/}
                    <Grid item >
                        <InputLabel className={globalStyles.labelStyle} >CTA Button Related Field<Required /></InputLabel>
                        <TextField
                            className={globalStyles.globalTextfieldStyle}
                            fullWidth
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={props.createImageGroupAssignmentData.image_group_assignment_data[0].cta_button_related_field || ""}
                            onChange={(e) => changeImageGroupAssignmentData('cta_button_related_field', e.target.value)}
                        />
                    </Grid>
                    {/* Module type id*/}
                    <Grid item >
                        <InputLabel className={globalStyles.labelStyle} >Module Type id<Required /></InputLabel>
                        <Select
                            className={globalStyles.globalTextfieldStyle}
                            sx={{ width: "100%" }}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="template_id"
                            value={props.createImageGroupAssignmentData.image_group_assignment_data[0].module_type_id || ""}
                            onChange={(e) => changeImageGroupAssignmentData('module_type_id', e.target.value)}
                        >
                            {moduleDataList.map((module) => {
                                return <MenuItem value={module.module_type_id}>{module.module_type_name}</MenuItem>
                            })}
                        </Select>
                    </Grid>
                    {/* Loader user type id*/}
                    <Grid item >
                        <InputLabel className={globalStyles.labelStyle} >Loader User type Id<Required /></InputLabel>
                        <Select
                            className={globalStyles.globalTextfieldStyle}
                            sx={{ width: "100%" }}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="template_id"
                            value={props.createImageGroupAssignmentData.image_group_assignment_data[0].loader_user_type_id || ""}
                            onChange={(e) => changeImageGroupAssignmentData('loader_user_type_id', e.target.value)}
                            multiple={true}
                        >
                            {loaderUserTypeDataList.map((loaderUserDetail) => {
                                return <MenuItem value={loaderUserDetail.loader_user_type_id}>{loaderUserDetail.loader_user_type_name}</MenuItem>
                            })}
                        </Select>
                    </Grid>
                </div>
                {/* create prompt button */}
                <Grid item sx={{ marginBottom: "20px" }}>
                    <GlobalButton
                        buttonText={props.createImageGroupAssignmentData.image_group_assignment_data[0].image_group_assignment_id ? "Update Loader Details" : "Create Loader Details"}
                        className="primaryButtonStyle"
                        onClick={props.createImageGroupAssignmentData.image_group_assignment_data[0].image_group_assignment_id ? props.updateLoaderDetail : props.createLoaderDetail}
                    />
                </Grid>
            </Grid>

        </>
    )
}

export default CreateLoaderDetail;